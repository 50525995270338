import React from 'react';
import {Grid, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import cama0 from '../../../assets/png/cama0.png'
import cama1 from '../../../assets/png/cama1.png'
import cama2 from '../../../assets/png/cama2.png'
const Item=({num})=>{
    const [open,setOpen]=React.useState(num===0?true:false)
    return  <Grid item xs={12} md={3}> 
              <Typography component={"h3"} align='left'>
                  Producto
              </Typography>
              <Grid>
                <Grid className='switch' container spacing={2}>
                  <Grid item xs={6}>
                    Product 
                  </Grid>
                  <Grid item xs={6} align="right" className='cursor-pointer' onClick={()=>setOpen(open?false:true)}>
                    {!open?<KeyboardArrowDownIcon/>:<KeyboardArrowUpIcon/>}
                  </Grid>            
                  {
                    open&&(
                      <>
                        <Grid item xs={3} md={3}>
                          <img src={cama0} className='img-fluid' alt="programandoWeb"/>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <img src={cama1} className='img-fluid' alt="programandoWeb"/>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <img src={cama2} className='img-fluid' alt="programandoWeb"/>
                        </Grid>
                      </>
                    )
                  }      
                  
                </Grid>
              </Grid>
            </Grid>
  }
  
export default Item