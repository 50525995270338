import { useContext, useEffect, useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import SubMenuInterno from './SubMenuInterno';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import StateContext from '../../../utils/ContextState';
import useAxios from '../../../hooks/useAxios';
import { useParams } from 'react-router-dom';
import Filter from '../../../components/Form/EditProductosModal';
import Loading from '../../../components/Loading/LinearWithLabel';
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import {formatearMontoEnCOP} from '../../../utils/functions';

const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  
]


const Item=({row,nro})=>{
  return  <Grid item xs={12} md={3}> 
            <Paper sx={{p:2}}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Grid><b>{row.name}</b></Grid>
                  <Grid>Unid. compradas: <b>{row.quantity}</b></Grid>
                  <Grid>Unid. transportadas: <b>{row.quantity}</b></Grid>
                  <Grid>Valor Unit: <b>{formatearMontoEnCOP(row.value)}</b></Grid>
                </Grid>
                <Grid item xs={2} >
                  <Grid >
                    <Button component={NavLink} to="#">
                      <DeleteIcon className='text-red'/>
                    </Button>  
                  </Grid>                  
                </Grid>
              </Grid>
            </Paper>
          </Grid>
}


let init            =   false;
let inputs          =   {}
const timeLoading   =   2000;
let data    =   []

const Home=()=>{
  const context               =   useContext(StateContext);
  const [negociaciones,setNegociaciones]  = useState([])
  const {getInit}             =   useAxios();
  let { id }                  =   useParams();
  const axios                 =   useAxios();
  const [loading,setLoading]  =   useState(true);
  const userStorage           =   useAsyncStorage("formProduct");

  const handleSave=(data)=>{

  }

  init=()=>{
    axios.getInit("negotiation",{},"include=products&filter=id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {      
        inputs=response.data[0];         
      }     
      setTimeout(() => {
        setLoading(false)
      }, timeLoading);  
      
    })        
  }

  useEffect(()=>{
    axios.getInit("negotiation",{},"include=products.subcategory.category,products,state,provider",1000).then((response)=>{
      if(response&&response.data){
        let items = []
        response.data.map((row,key)=>{
          return items.push({
            id:row.id,
            name:"("+row.negotiation_value+")"+" - " +row.state.name+ " " + row.provider.name,
            products:row.products,
            dispatch_to:row.dispatch_to,
            negotiation_date:row.negotiation_date,
            provider_id:row.provider_id,
          })
        })
        setNegociaciones(items)
      }      
    })
    if (id==="new") {
      data=[]
      setLoading(false)
      userStorage.removeData();  
      return;
    }  
    
    init();
    
  },[])

  const handleOpenModal=()=>{
    return context.setOpenDialog({
      component:<Filter init={init} axios={axios} context={context} id={id} negociaciones={negociaciones} />,
      maxWidth:"sm"
    })
  }

  return  <Container {...useFormData()} save={"/"}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                   Transporte #PENDIENTE POR DEFINIR
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Transporte #PENDIENTE POR DEFINIR
                </Typography>                
              </Grid>              
            </Grid>  
          
            <SubMenuInterno id={id} option={"productos"}/>

            {
              loading&&(
                <Grid>
                  <Loading time={timeLoading} increment={timeLoading}/>
                </Grid>
              )
            }

            {
              !loading&&(
                <Grid container spacing={2}>
                  {inputs.products&&inputs.products.length>0&&(
                    inputs.products.map((row,key)=>{
                      return <Item key={key} row={row} nro={key} />
                    })
                  )}
                  {inputs.products&&inputs.products.length===0&&(
                    <Grid align="center">No hay Productos aún</Grid>                
                  )}  
                </Grid>
              )
            }
            
            <Grid className='btnAddNew'>
                <Button
                  style={{
                    padding: "16px",
                    fontSize: "18px",
                    color:"white",
                  }}
                  variant="btnAddNew"
                  onClick={handleOpenModal}
                >
                  <AddIcon sx={{fontSize:{md:30,xs:26}}}/>
                </Button>
            </Grid>
          </Container>
}
export default Home
