//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';
import UploadIcon from '@mui/icons-material/Upload';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';

const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} save={"/"}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='' >
                  <Typography component={"h3"}>
                    
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"},mt:{xs:2}}}>
                <Typography component={"h3"} align='center'>
                  
                </Typography>                
              </Grid>              
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={5} sx={{mb:2}} >
                <Input  required 
                        
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Número de Pago"/>
              </Grid>
              <Grid item xs={12} md={5} sx={{mb:2}}>
                <Input  required 
                        
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Valor de Pago"/>
              </Grid>  
              <Grid item xs={12} md={5} sx={{mb:2}}>
                <Input  required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Fecha de Pago"/>
              </Grid>  
              <Grid item xs={12} md={5} sx={{mb:2}}>
                <Input  required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Método de Pago"/>
              </Grid>  
              <Grid item xs={12} md={5} sx={{mb:2}}>
                <Input  required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Estado de Pago"/>
              </Grid>  
              <Grid item xs={12} md={5} sx={{mb:2,mt:1}}>
                <Grid sx={{display:{xs:"none",md:"block"}}}>
                  <span style={{paddingTop:"20px"}}>Documentos Adjuntos</span> 
                  <span><TextSnippetIcon sx={{fontSize:50,float:"right",color:"#2fca5a"}}/></span>
                </Grid>
                <Grid sx={{display:{md:"none",xs:"block"}}}>
                  <Button variant='btnUpload'>
                    <UploadIcon sx={{fontSize:20, }}/> Subir Documentos
                  </Button>
                </Grid>                
              </Grid>
            </Grid>
          </Container>
}
export default Home
