import { useContext } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/List';
import { Button, Grid, IconButton, Paper } from '@mui/material';
import image    from '../../../assets/png/image-item.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import { NavLink } from 'react-router-dom';
import Select from '../../../components/Select'
import StateContext from '../../../utils/ContextState';

const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },  
]

const Item=({row})=>{
  return  <Grid item xs={12} md={3}> 
            <Paper sx={{p:2}}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid className={(row.status==='Vencido')?' item-status colorRed ':(row.status==='A tiempo')?' item-status colorGreen ':' item-status colorBlack '} 
                        sx={{fontSize:11,fontWeight:"bold"}}>
                    {row.status}
                  </Grid>
                  <Grid className='item-name'>
                    {row.label}
                  </Grid>
                  <Grid className='item-date'>
                    Fecha de negociación
                  </Grid>
                  <Grid className='item-date2'>
                    {row.fecha}
                  </Grid>
                  <Grid className='item-price'>
                    ${row.precio}
                  </Grid>                  
                </Grid>
                <Grid item xs={6} align="right">
                  <Grid sx={{mb:1}}>
                    <IconButton color="green" size="small" variant="text"><WhatsAppIcon sx={{mr:1}}/> </IconButton>
                    <IconButton color="blue" size="small" variant="text"><PhoneForwardedIcon/></IconButton>
                  </Grid>
                  <img className='item-img' src={image} alt="Programandoweb"/>
                </Grid>
              </Grid>
              <Grid container justifyContent={"right"}>
                <Grid item xs={"auto"}>
                  <Button variant='primary' sx={{mr:0.5}} color="primary">Ver Productos</Button>
                  <Button variant='red'>No Pago</Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
}


const Filter=({context})=>{
  return  <Grid sx={{p:4}}>
            <Grid container spacing={3} sx={{mb:2}}>
              <Grid item xs={6}>
                <Select label="Estado Pago"/>
              </Grid>
              <Grid item xs={6}>
                <Select label="Proveedor"/>
              </Grid>
              <Grid item xs={6}>
                <Select label="Estado Fecha"/>
              </Grid>
              <Grid item xs={6}>
                <Select label="Vencimiento Pago"/>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button variant='contained' fullWidth>
                  Aceptar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant='outlined' fullWidth onClick={()=>context.setOpenDialog(false)}>
                  Borrar Filtro
                </Button> 
              </Grid>
            </Grid>
          </Grid>
}


const Home=(props)=>{

  const context               =   useContext(StateContext);
  const handleFilters=()=>{
    return context.setOpenDialog({
              component:<Filter context={context}/>,
              maxWidth:"sm"
            })
  }
  
  
  return  <Container {...useFormData()} filter={handleFilters}>
            <Grid container spacing={2} sx={{mb:4}}>
              <Grid item xs={12} sm={10} md={6} xl={4}>
                <Grid className='contentSubMenu'>
                  <Grid container>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTag' component={NavLink} to="/dashboard/negociacionInternacional">
                        Negociación
                      </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTag' component={NavLink} to="/dashboard/negociacionInternacional/negociacionparcial">
                        Transporte Parcial
                      </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTagSelect' component={NavLink} to="/dashboard/negociacionInternacional/negociaciontotal">
                        Transporte Total
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {
                items.map((row,key)=>{
                  return <Item key={key} row={row} />
                })
              }     
            </Grid>       
          </Container>
}
export default Home
