//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Input        from '../../../components/Input/GroupInput';
import UploadIcon from '@mui/icons-material/Upload';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';




const Home=(props)=>{
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} >
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                    Compra Nacional #43473444
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Compra Nacional #43473
                </Typography>                
              </Grid>                           
            </Grid>
            <Grid container spacing={2} sx={{mb:4}}>
              <Grid item xs={12} sm={10} md={8}>
                <Grid className='contentSubMenu'>
                  <Grid container>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTag' component={NavLink} to="/dashboard/ventas/0">
                        Negociación
                      </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTagSelect' component={NavLink} to="/dashboard/ventas/0/formapago">
                        Forma de Pago
                      </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTag' component={NavLink} to="/dashboard/ventas/0/productos">
                        Productos
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5} >
                  <Input  required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="Fecha "/>
                </Grid>
                <Grid item xs={0} md={2} sx={{display:{xs:"none",md:"block"}}}>
                    
                </Grid>  
                <Grid item xs={12} md={5} sx={{mb:2,mt:1}}>
                  <Grid container>
                    <Grid item xs={6} align="left" sx={{pt:1}}><b>Estado Pago</b></Grid>
                    <Grid item xs={6} align="right"><Button variant="contained" color='red'>No Pago</Button></Grid>
                  </Grid>
                </Grid> 
                <Grid item xs={8} md={5} >
                  <Input  sx={{mb:2}} 
                          required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="Cliente"/>
                </Grid>  
                <Grid item xs={4} md={2} >
                  <IconButton color="green" size="small" variant="text"><WhatsAppIcon sx={{fontSize:22}} /></IconButton>
                  <IconButton color="blue" size="small" variant="text"><PhoneForwardedIcon sx={{fontSize:22}}  /></IconButton>                    
                </Grid>  
                <Grid item xs={12} md={5} sx={{mb:2,mt:1}}>
                  <Grid container>
                    <Grid item xs={6} align="left" sx={{pt:1}}><b>Estado Negociación</b></Grid>
                    <Grid item xs={6} align="right"><Button variant="contained" color='red'>No Pago</Button></Grid>
                  </Grid>
                </Grid> 
                <Grid item xs={12} md={5} sx={{mb:2}}>
                  <Input  sx={{mb:2}} 
                          required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="Tipo financiación"/>
                </Grid>  
                <Grid item xs={0} md={2} sx={{display:{xs:"none",md:"block"}}}>
                    
                </Grid>  
                <Grid item xs={12} md={5} >
                  <Input  required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="Valor Venta"/>
                </Grid>  
                <Grid item xs={12} md={5} sx={{mb:2}}>
                  <Input  sx={{mb:2}} 
                          required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="Facturado"/>
                </Grid>  
                <Grid item xs={0} md={2} sx={{display:{xs:"none",md:"block"}}}>
                    
                </Grid>  
                <Grid item xs={12} md={5} >
                  <Input  required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="IVA"/>
                </Grid>  
                <Grid item xs={12} md={5} sx={{mb:2}}>
                  <Input  sx={{mb:2}} 
                          required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="Concepto"/>
                </Grid>  
                <Grid item xs={0} md={2} sx={{display:{xs:"none",md:"block"}}}>
                    
                </Grid>  
                <Grid item xs={12} md={5} >
                  <Input  required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="Retención"/>
                </Grid>

                <Grid item xs={12} md={5} sx={{mb:2}}>
                  <Input  sx={{mb:2}} 
                          required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="Método de envío"/>
                </Grid>  
                <Grid item xs={0} md={2} sx={{display:{xs:"none",md:"block"}}}>
                    
                </Grid>  
                <Grid item xs={12} md={5} >
                  <Input  required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="Retención"/>
                </Grid>


                <Grid item xs={12} md={5} sx={{mb:2,mt:1}}>
                  <Grid sx={{display:{xs:"none",md:"block"}}}>
                    <span style={{paddingTop:"20px"}}>Documentos Adjuntos</span> 
                    <span><TextSnippetIcon sx={{fontSize:50,float:"right",color:"#2fca5a"}}/></span>
                  </Grid>
                  <Grid sx={{display:{md:"none",xs:"block"}}}>
                    <Button variant='btnUpload'>
                      <UploadIcon sx={{fontSize:20, }}/> Subir Documentos
                    </Button>
                  </Grid>                
                </Grid>
                <Grid item xs={0} md={2} sx={{display:{xs:"none",md:"block"}}}>
                    
                </Grid>  
                <Grid item xs={12} md={5} >
                  <Input  required 
                          onChange={onChange} 
                          size="small" 
                          name="email" 
                          label="Valor total"/>
                </Grid>
            </Grid>
          </Container>
}
export default Home
