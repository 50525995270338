import {Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
const SubMenu=({option})=>{
    console.log(option)
    return <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={8}>
                <Grid className='contentSubMenu'>
                    <Grid container>
                    <Grid item xs={4} align="center">
                        <Button variant={option===''?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/centroAcopio/remanufactura/0">
                            Lista 
                        </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='productos'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/centroAcopio/remanufactura/0/productos">
                            Productos
                        </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='mapa'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/centroAcopio/remanufactura/0/mapa">
                            Mapa de Cargue
                        </Button>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
}
export default SubMenu;