import { useContext, useEffect, useState  } from 'react';
import useFormData from '../../../hooks/useFormData';
import useAxios from '../../../hooks/useAxios';
import Container    from '../../../theme/Form';
import { Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';
import Select from '../../../components/Select/GroupSelect';
import StateContext from '../../../utils/ContextState';
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import UploadIcon from '@mui/icons-material/Upload';
import {getImagePreviewURL} from '../../../utils/functions'
import Loading from '../../../components/Loading/LinearWithLabel';
import Avatar from '../../../components/Avatar/AvatarWithUpload';
import FileContent from '../../../components/FileContent';

let inputs                            =   {photo:null};
const timeLoading                     =   2000;

const Edit=()=>{
  const { getInit }                   =   useAxios();
  let { id }                          =   useParams();  
  const navigate                      =   useNavigate();
  const context                       =   useContext(StateContext);
  const [subcategory,setSubCategory]  =   useState([]);
  const userStorage                   =   useAsyncStorage("formProduct");
  const axios                         =   useAxios();
  const [loading,setLoading]          =   useState(true);
  const [mainImage, setMainImage]     =   useState(null); // Estado para la imagen principal
  const [image, setImage]             =   useState(null); // Estado para la imagen principal

  const onChange = (e)=>{    
    inputs[e.target.name] = e.target.value;
  }

  const onChange2 = (name,value)=>{
    //console.log(name,value)

    if (!value||!value.id) {
      return false;
    }

    inputs[name]  =  value.id;

    let return_=[]
    context.config.subcategory.map((row)=>{
      if (row.category_id===value.id) {
        return_.push(row)  
      }      
    }) 
    
    if (name==='category') {
      setSubCategory(return_)  
    }            
    

  }

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita el comportamiento predeterminado del formulario
    // Realiza cualquier otra lógica que necesites aquí
    
    if(inputs.photo===null){
      delete inputs.photo;
    }
    if(inputs.vehicle===null){
      delete inputs.vehicle;
    }
    if(inputs.attachments===null){
      delete inputs.attachments;
    }else if (mainImage) {
      inputs["attachments[]"]=mainImage;
    }

    if (id==="new") {
      axios.postUpload({
            ...inputs,            
          },"api/client/create").then((response)=>{
        navigate('/dashboard/clientes/'+response.data.id+'/vehiculo')
      })  
    }  else{
      axios.postUpload({
        ...inputs
      },"api/client/update/"+id).then((response)=>{
        if (response&&response.data) {
          userStorage.setData(response.data)
          navigate('/dashboard/clientes/'+response.data.id+'/vehiculo')
        }
      })
    }
      
  };

  useEffect(()=>{
    if(userStorage.data&&userStorage.data.name){
      inputs=userStorage.data
    }
  },[userStorage.data])

  useEffect(()=>{
    if (id==="new") {
      inputs={}
      setLoading(false)
      userStorage.removeData();  
      return;
    }  
    getInit("client",{},"include=city&filter=id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {
        inputs=response.data[0];
        if (inputs.attachments) {
          setImage(inputs.attachments)  
          delete inputs.attachments;
        }        
        setTimeout(()=>{
          setLoading(false);
        },timeLoading)
      }      
    })
  },[])
  
  return  <form onSubmit={handleSubmit}>
            <Container {...useFormData()} onClickSubmit={true}>
              <Grid container spacing={2} sx={{mt:{xs:5,md:1}}}>
                <Avatar name="photo" inputs={inputs}/>
              </Grid>
              <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
                <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                  <Grid  className='borderBottom' >
                    <Typography component={"h3"}>
                      Registro de cliente {inputs.name}  
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                  <Typography component={"h3"} align='center'>
                    Registro de cliente {inputs.name}
                  </Typography>                
                </Grid>              
              </Grid>
              <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={6} xl={4}>
                  <Grid className='contentSubMenu'>
                    <Grid container>
                      <Grid item xs={6} align="center">
                        <Button variant='btnTagSelect' component={NavLink} to={"/dashboard/clientes/"+id}>
                          Datos Cliente
                        </Button>
                      </Grid>
                      <Grid item xs={6} align="center">
                        <Button variant='btnTag' component={NavLink} to={"/dashboard/clientes/"+id+"/vehiculo"} >
                          Vehículo
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {
                loading&&(
                  <Grid>
                    <Loading time={timeLoading} increment={timeLoading}/>
                  </Grid>
                )
              }
              {
                !loading&&(
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} >
                        <Select defaultValue={inputs.type} label="Tipo de cliente" name="type" data={[
                          {
                            id:"Distribuidor",
                            name:"Distribuidor",
                          },
                          {
                            id:"Cliente Final",
                            name:"Cliente Final",
                          },
                        ]} onChange={onChange2}/>                
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                defaultValue={inputs.phone} 
                                onChange={onChange} 
                                size="small" 
                                name="phone" 
                                label="Celular"/>
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                defaultValue={inputs.social_reason} 
                                onChange={onChange} 
                                size="small" 
                                name="social_reason" 
                                label="Razón Social"/>
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Select defaultValue={inputs.city&&inputs.city.country_id?inputs.city.country_id:""} label="País" name="city_id" data={context.config.countries} onChange={onChange2}/>                
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                defaultValue={inputs.nit} 
                                onChange={onChange} 
                                size="small" 
                                name="nit" 
                                label="Nit"/>
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Select defaultValue={inputs.city_id} label="Ciudad" name="city_id" data={context.config.cities} onChange={onChange2}/>                
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                defaultValue={inputs.email} 
                                onChange={onChange} 
                                size="small" 
                                name="email" 
                                label="Correo"/>
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                defaultValue={inputs.address} 
                                onChange={onChange} 
                                size="small" 
                                name="address" 
                                label="Dirección"/>
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                onChange={onChange} 
                                size="small" 
                                defaultValue={inputs.person_in_charge} 
                                name="person_in_charge" 
                                label="Persona Encargada"/>
                      </Grid>  
                      <Grid item xs={12} md={3} >
                        {
                          image&&(
                             <FileContent files={image}/>                     
                          )
                        }
                        <Button variant='btnUpload' className='content-image-upload' sx={{my:2}}>
                          <input type="file" name="photo" className='input' onChange={(e) => setMainImage(e.target.files[0])} />
                          <UploadIcon sx={{fontSize:20}}/> Subir Documentos
                        </Button>
                        
                        {
                          mainImage&&(<img className='img-fluid'  src={getImagePreviewURL(mainImage)} alt="Programandoweb" />)
                        } 
                                    
                      </Grid>              
                    </Grid>
                )
              }
                
            </Container>
            
          </form>  
}
export default Edit
