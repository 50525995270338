//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';


const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },  
]


const Item=({row,nro})=>{
  return  <Grid item xs={12} md={3}> 
            <Paper sx={{p:2}}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                    Pago {nro+1}
                  </Grid>
                  <Grid className='item-name'>
                    {row.label}
                  </Grid>
                  <Grid className='item-date'>
                    Fecha de negociación
                  </Grid>
                  <Grid className='item-date2'>
                    {row.fecha}
                  </Grid>
                  <Grid className='item-price'>
                    ${row.precio}
                  </Grid>                  
                </Grid>
                <Grid item xs={2} >
                  <Grid >
                    <Button component={NavLink} to="#">
                      <DeleteIcon className='text-red'/>
                    </Button>  
                  </Grid>                  
                </Grid>
              </Grid>
            </Paper>
          </Grid>
}


const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} save={"/"} add={{component:NavLink,to:"0"}}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                    Negociación Internacional #43473
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Negociación Internacional #43473
                </Typography>                
              </Grid>              
            </Grid>
            <Grid container spacing={2} sx={{mb:4}}>
              <Grid item xs={12} sm={10} md={8}>
                <Grid className='contentSubMenu'>
                  <Grid container>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTag' component={NavLink} to="/dashboard/negociacionInternacional/0">
                        Negociación
                      </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTag' component={NavLink} to="/dashboard/negociacionInternacional/0/formapago">
                        Forma de Pago
                      </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTagSelect' component={NavLink} to="/dashboard/negociacionInternacional/0/productos">
                        Productos
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {
                items.map((row,key)=>{
                  return <Item key={key} row={row} nro={key} />
                })
              }  
            </Grid>
          </Container>
}
export default Home
