import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './utils/theme'; // Importa el tema creado
import './App.css';
import Snackbars from './components/Snackbars';
import StateContext from './utils/ContextState';
import { routes_modules } from './routes';
import NotFound from './components/NotFound';
import NotInternet from './theme/NotInternet';
import LoadingFullWidth from './theme/LoadingFullWidth';
import useAsyncStorage from './hooks/useAsyncStorage';
import useInternetConnection from './hooks/useInternetConnection';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dialog from './components/Dialog';
import axios from 'axios';

const LIMIT_TIMEOUT = process.env.REACT_APP_LIMIT_TIMEOUT || 5000; // Establece un límite de tiempo para la carga

const BACKEND       = window.location.hostname === 'localhost' ? process.env.REACT_APP_BACKEND : process.env.REACT_APP_BACKENDREMOTE;

const App       =   () => {
  const isOnline                        =   useInternetConnection(); // Verifica si hay conexión a Internet
  const userStorage                     =   useAsyncStorage("user"); // Obtiene los datos de usuario almacenados en el almacenamiento asíncrono
  const [user, setUser]                 =   useState(false); // Estado para almacenar la información del usuario
  const [loading, setLoading]           =   useState(null); // Estado para controlar si se está cargando algún contenido
  const [loadingTable, setLoadingTable] =   useState(false); // Estado para controlar si se está cargando algún contenido
  const [loadingAll, setLoadingAll]     =   useState(true); // Estado para controlar si se está cargando todo el contenido
  const [openMessage, setOpenMessage]   =   useState(false); // Estado para controlar si se muestra un mensaje
  const [openDialog, setOpenDialog]     =   useState(false); // Estado para controlar si se muestra un mensaje
  const [storageState, setStorageState] =   useState({});
  const configStorage                   =   useAsyncStorage("config");
  const [config, setConfig]             =   useState({});

  React.useEffect(()=>{

    const init=async()=>{

      let insert      =   {}

      const category      =    BACKEND + "api/category/?order_by=name"             
      const subcategory   =    BACKEND + "api/subcategory/?order_by=name"             
      const countries     =    BACKEND + "api/country/?order_by=name"             
      const cities        =    BACKEND + "api/city/?order_by=name" 
      const states        =    BACKEND + "api/state/?order_by=name" 
      const providers        =    BACKEND + "api/provider/?order_by=name" 
      
      await axios.get(category,{
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }).then((response)=>{
        if (response&&response.data&&response.data.data) {
          insert.category=response.data.data
        }        
      })


      await axios.get(subcategory,{
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }).then((response)=>{
        if (response&&response.data&&response.data.data) {
          insert.subcategory=response.data.data
        }        
      })


      await axios.get(countries,{
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }).then((response)=>{
        if (response&&response.data&&response.data.data) {
          insert.countries=response.data.data
        }        
      })

      await axios.get(cities,{
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }).then((response)=>{
        if (response&&response.data&&response.data.data) {
          insert.cities=response.data.data
        }        
      })

      await axios.get(states,{
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }).then((response)=>{
        if (response&&response.data&&response.data.data) {
          insert.states=response.data.data
        }        
      })

      await axios.get(providers,{
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }).then((response)=>{
        if (response&&response.data&&response.data.data) {
          insert.providers=response.data.data
        }        
      })

      configStorage.setData(insert)       
      setConfig(insert)

    }       

    if (user.access_token) {
      init()  
    }

  },[user.access_token])

  const Map=({formData,component: Component})=>{
    if (formData && Array.isArray(formData) && formData.length > 0) {
      return (
        <>
          {
            formData.map((row, key) => (
              <Component key={key} row={row} />
            )            
          )}
        </>
      );
    } else {
      return null; // Devuelve null si no hay datos para renderizar
    }
  }


  // Función para proporcionar valores y funciones al contexto
  const provider = () => {
    return {
      user,
      setUser,
      loading,
      setLoading,
      openMessage,
      setOpenMessage,
      loadingTable,
      setLoadingTable,
      openDialog,
      setOpenDialog,
      Map,
      config
    };
  };

  React.useEffect(() => {
    /* Verifico el estado del almacenamiento si el usuario está logueado */
    if (!user && userStorage.data) {
      setUser(userStorage.data);
    }
    /* Establecer un temporizador para cambiar el estado de loadingAll a false después de cierto tiempo */
    setTimeout(() => setLoadingAll(false), LIMIT_TIMEOUT);
  }, [user, userStorage]);

  return (
    <ThemeProvider theme={theme}>
      <StateContext.Provider value={provider()}>
        {openDialog&&(<Dialog open={openDialog} setOpen={setOpenDialog}/>)}
        {loadingAll ? (
          /* Mostrar componente de carga mientras se carga todo el contenido */
          <LoadingFullWidth />
        ) : !isOnline ? (
          /* Mostrar componente para cuando no hay conexión a Internet */
          <NotInternet />
        ) : (
          <>
            <Router>
              <Routes>
                {/* Mapear y renderizar rutas */}
                {routes_modules.map((module, index) => {
                  if (module.private && !user) {
                    // Ruta privada sin inicio de sesión, redirigir al inicio de sesión
                    return (
                      <Route
                        key={index}
                        path={module.path}
                        element={<Navigate to="/auth/login" />}
                      />
                    );
                  } else if (!module.private && !module.menu) {
                    // Ruta pública y no se muestra en el menú
                    return <Route key={index} path={module.path} element={<module.component />} />;
                  } else if (!module.private && module.menu) {
                    // Ruta pública y se muestra en el menú
                    return (
                      <Route
                        key={index}
                        path={module.path}
                        element={<module.component />}
                      />
                    );
                  } else if (module.private && module.menu) {
                    // Ruta privada y se muestra en el menú
                    return (
                      <Route
                        key={index}
                        path={module.path}
                        element={<module.component />}
                      />
                    );
                  }
                  return null;
                })}
                {/* Ruta para manejar URLs no encontradas */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Router>
          </>
        )}
        {openMessage && <Snackbars open={openMessage} setOpen={setOpenMessage} />} {/* Mostrar Snackbar si openMessage es verdadero */}
      </StateContext.Provider>
    </ThemeProvider>
  );
};

export default App;
