import { useContext, useEffect, useState  } from 'react';
import useFormData from '../../../hooks/useFormData';
import useAxios from '../../../hooks/useAxios';
import Container    from '../../../theme/Form';
import { Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';
import Select from '../../../components/Select/GroupSelect';
import StateContext from '../../../utils/ContextState';
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading/LinearWithLabel';
import Avatar from '../../../components/Avatar/AvatarWithUpload';

let inputs  = {photo:null};
let country = 0
const timeLoading                     =   2000;

const Edit=()=>{
  const axios                         =   useAxios()
  const { getInit }                   =   useAxios();
  let { id }                          =   useParams();  
  const navigate                      =   useNavigate();
  const context                       =   useContext(StateContext);
  const [subcategory,setSubCategory]  =   useState([]);
  const configStorage                 =   useAsyncStorage("config");
  const userStorage                   =   useAsyncStorage("formProduct");
  const [loading,setLoading]          =   useState(true);
  
  const onChange = (e)=>{    
    inputs[e.target.name] = e.target.value;
  }

  const onChange2 = (name,value)=>{
    if (!value||!value.id) {
      return false;
    }
   inputs[name]  =  value.id;
    let return_=[]
    context.config.subcategory.map((row)=>{
      if (row.category_id===value.id) {
        return_.push(row)  
      }      
    }) 
    if (name==='category') {
      setSubCategory(return_)  
    }            
  }

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita el comportamiento predeterminado del formulario
    // Realiza cualquier otra lógica que necesites aquí
    if (id==='new') {
      axios.postUpload({
              ...inputs,
            },"api/provider/create").then((response)=>{
        if (response&&response.data&&response.data.id) {
          actualiza_store(response.data)
          navigate('../'+response.data.id+'/cuentas')  
        }
      })
    }else{
      axios.postUpload({
              ...inputs,
            },"api/provider/update/"+id).then((response)=>{
        if (response&&response.data) {
          actualiza_store(response.data)
          navigate('./cuentas')        
        }
      })
    }    
  };

  const actualiza_store=(data)=>{
    let _data = {...configStorage.data}
    if(_data.providers){
      const result  =   _data.providers.findIndex(search=>search.id===data.id)
      if(result){
        _data.providers[result] = data;        
      }
      configStorage.setData(_data)
    }    
  }

  useEffect(()=>{
    if(userStorage.data&&userStorage.data.name){
      inputs=userStorage.data
    }
  },[userStorage.data])

  useEffect(()=>{
    if (id==="new") {
      inputs={}
      setLoading(false)
      userStorage.removeData();  
      return;
    }    
    getInit("provider",{},"include=city.country,bank_account&filter=id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {      
        inputs=response.data[0]; 
        country    =   inputs.city.country_id; 
        setTimeout(() => {
          setLoading(false)
        }, timeLoading);              
      }      
    })    
  },[])
  
  return  <form onSubmit={handleSubmit}>
            <Container {...useFormData()} onClickSubmit={true}>
              <Grid container spacing={2} sx={{mt:{xs:5,md:1}}}>
                  <Avatar name="photo" inputs={inputs}/>
              </Grid>
              <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
                <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                  <Grid  className='borderBottom' >
                    <Typography component={"h3"}>
                      {inputs.social_reason||"Nuevo registro"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                  <Typography component={"h3"} align='center'>
                    {inputs.social_reason||"Nuevo registro"}
                  </Typography>                
                </Grid>              
              </Grid>
              <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={8}>
                  <Grid className='contentSubMenu'>
                    <Grid container>
                      <Grid item xs={6} align="center">
                        <Button variant='btnTagSelect' component={NavLink} to={"/dashboard/proveedores/"+id}>
                          Datos proveedor
                        </Button>
                      </Grid>
                      <Grid item xs={6} align="center">
                        <Button variant='btnTag' component={NavLink} to={"/dashboard/proveedores/"+id+"/cuentas"}>
                          Cuenta bancaria
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {
                loading&&(
                  <Grid>
                    <Loading time={timeLoading} increment={timeLoading}/>
                  </Grid>
                )
              }

              {
                !loading&&(
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} >
                      <Select defaultValue={inputs.type} label="Tipo de proveedor" name="type" data={[
                        {
                          id:"Nacional",
                          name:"Nacional",
                        },
                        {
                          id:"Internacional",
                          name:"Internacional",
                        },
                      ]} onChange={onChange2}/>                
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              required 
                              defaultValue={inputs.phone} 
                              onChange={onChange} 
                              size="small" 
                              type="number"
                              name="phone" 
                              label="Celular"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              required 
                              defaultValue={inputs.name}
                              onChange={onChange} 
                              size="small" 
                              name="name" 
                              label="Nombre"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              required 
                              defaultValue={inputs.social_reason}
                              onChange={onChange} 
                              size="small" 
                              name="social_reason" 
                              label="Razón Social"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Select defaultValue={country} label="País" name="city_id" data={context.config.countries} onChange={onChange2}/>                
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              required 
                              defaultValue={inputs.nit}
                              onChange={onChange} 
                              size="small" 
                              name="nit" 
                              label="Nit"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Select defaultValue={inputs.city_id} label="Ciudad" name="city_id" data={context.config.cities} onChange={onChange2}/>                
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              required 
                              defaultValue={inputs.email}
                              onChange={onChange} 
                              size="small" 
                              type="email" 
                              name="email" 
                              label="Correo"/>
                              
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              required 
                              defaultValue={inputs.address}
                              onChange={onChange} 
                              size="small" 
                              name="address" 
                              label="Dirección"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              required 
                              defaultValue={inputs.person_in_charge}
                              onChange={onChange} 
                              size="small" 
                              name="person_in_charge" 
                              label="Persona Encargada"/>
                    </Grid>    
                  </Grid>
                )
              }

              
            </Container>            
          </form>  
}
export default Edit
