//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Button, Grid, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import galeria,{main} from '../../../assets/jpg/galeria'



const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} component={NavLink} to="/dashboard/productos/0/preview">
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                    Suspensión 300
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Suspensión 300
                </Typography>                
              </Grid>              
            </Grid>
            <Grid container spacing={2} sx={{mb:4}}>
              <Grid item xs={12} md={4}>
                <Grid className='contentSubMenu'>
                  <Grid container>
                    <Grid item xs={6} align="center">
                      <Button variant='btnTag'  component={NavLink} to="/dashboard/productos/0">
                        Formulario
                      </Button>
                    </Grid>
                    <Grid item xs={6} align="center">
                      <Button variant='btnTagSelect' component={NavLink} to="/dashboard/productos/0/fotografia">
                        Fotografía
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Typography component={"b"}>
                  Foto Principal
                </Typography>   
              </Grid>  
            </Grid>
            <Grid container spacing={2} sx={{mb:2}}>
              <Grid item xs={12} md={4} >
                <img className='img-fluid' src={main} alt="Programandoweb" />
              </Grid>  
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Typography>Foto Complementarias</Typography>   
              </Grid>  
            </Grid>
            <Grid container spacing={2}>
              {
                galeria.map((row,key)=>{
                  return  <Grid item xs={12} md={2} key={key}>
                            <img className='img-fluid' src={row} alt="Programandoweb" />
                          </Grid>  
                })
              }              
            </Grid>                        
          </Container>
}
export default Home
