import { useContext } from 'react';
import Input        from '../../../components/Input';
import Button       from '@mui/material/Button';
//import { NavLink }  from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import useFormData  from '../../../hooks/useFormData';
import useAxios     from '../../../hooks/useAxios';
import StateContext from '../../../utils/ContextState';
import useAsyncStorage     from '../../../hooks/useAsyncStorage';
import { useNavigate, useLocation } from "react-router-dom";

import right2 from '../../../assets/png/elementBottonRight2.png'
import left2 from '../../../assets/png/elementTopLeft2.png'

const WebLogin=()=>{
    const userStorage             =   useAsyncStorage("user");
    const context                 =   useContext(StateContext);
    const { formData, onChange }  =   useFormData();
    const axios                   =   useAxios();
    const navigate                =   useNavigate();
    const location                =   useLocation();
    

    const onSubmit=(e)=>{
        e.preventDefault()
        console.log(500)
        axios.postData(formData).then((response)=>{
          if (  response  &&
                response.data &&
                response.code &&
                response.code==="SUCCESS"
              ) {
    
            let permissions = {}
            if (response.data&&response.data.permissions) {
              response.data.permissions.map((row,key)=>{
                const split = row.name.split("_")
                if (!permissions[split[0]]) {
                  permissions[split[0]] = []
                }
                return permissions[split[0]].push(split[1])
              })
            }
    
    
            const setStorageAndState  =  {
                                            access_token:response.data.access_token,
                                            ...response.data.user,
                                            roles:response.data.roles,
                                            permissions:permissions,
                                          };
            /*vamos a setear el estado en App.js para que esté disponible global*/
            context.setUser(setStorageAndState)
            /*Guardo en el storage también*/
            userStorage.setData(setStorageAndState).then(resp=>{
              context.setOpenMessage("Inicio de sesión, será redirigido a tu cuenta...")
              if (location.search==='?redirect=true' && window.history.length===0) {
                return navigate("/");
              }else {
                return navigate("/dashboard");
              }
            })
          }
        })
    }

    return  <>  

              <Grid className="left">
                <img alt="" src={left2}/>
              </Grid>
              <Grid className="right">
                <img alt="" src={right2}/>
              </Grid>
              <Grid container className='vh100'>
                <Grid item xs={12} md={6} className='flex'>
                  <Grid className='flex-center' align="center">
                    <Grid>
                      <form  onSubmit={onSubmit}>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={10} md={7}>  
                              <Typography sx={{fontSize:28,fontWeight:"bold"}}>
                                  Recuperación de contraseña
                              </Typography>
                            </Grid>
                            <Grid item xs={10} md={7} sx={{mb:5,mt:2}}>  
                              <Typography sx={{fontSize:16}}>
                                Ingresa tu correo electrónico y te enviaremos un enlace para recuperar tu contraseña
                              </Typography>
                            </Grid>
                            <Grid item xs={10}>
                              <Input sx={{mb:2}} required onChange={onChange} size="small" fullWidth name="email" type="email" label="Correo electrónico"/>
                            </Grid>
                            <Grid item xs={11} sx={{maxWidth:"80%"}}>  
                              <Button style={{ display: 'block', width: '100%' }} 
                                size="large" 
                                variant='contained' 
                                type="submit"
                              >
                                Recuperar contraseña
                              </Button>
                            </Grid>
                        </Grid>
                        
                      </form>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
}
export default WebLogin;