import { Button, Grid, IconButton, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';

const Item=({row})=>{
    return  <Grid item xs={12} md={3}> 
              <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={8} component={NavLink} to={"/dashboard/transporte/"+row.id}>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      #{row.guide_number}
                    </Grid>
                    <Grid className='item-name'>
                      <b>{row.conveyor}</b>
                    </Grid>
                    <Grid className='item-date'>
                      Fecha de salida: {row.departure_date}
                    </Grid>
                    <Grid className='item-date'>
                      Fecha de llegada: {row.arrival_date}
                    </Grid>
                    <Grid className='item-price'>
                      Proveedor: {
                        row&&row.provider&&row.provider.name&&(
                          row.provider.name
                        )
                      }
                    </Grid>                  
                  </Grid>
                  <Grid item xs={4} >
                    <Grid className='flexBottom'>
                      <Button variant={(row.status==='Vencido')?'red':'green'} component={NavLink} to="/dashboard/transporte/0">
                        {row.status==='Vencido'?"No Pago":"Pago"}
                      </Button>  
                    </Grid>                  
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }
export default Item  