import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({ 
                                      label,
                                      className, 
                                      data, 
                                      name, 
                                      onChange}) {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    const result  =   data.find((search)=>search.id===event.target.value||parseInt(search.id)===parseInt(event.target.value))
    if (result) {
      setAge(result.id);  
    }
    if (name&&onChange) {
      onChange(name,result)
    }    
  };  

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
            {label}
        </InputLabel>
        <Select
          className={className}
          labelId="demo-simple-select-label"
          value={age}
          label={label}
          onChange={handleChange}
        > 
          {
            data&&data.length>0&&(
              data.map((row,key)=>{
                return  <MenuItem key={key} value={row.id}>
                          {row.name}
                        </MenuItem>
              })
            )
          }
        </Select>
      </FormControl>
    </Box>
  );
}