import { PictureAsPdf as PdfIcon, Image as ImageIcon } from '@mui/icons-material';

export const getImagePreviewURL = (file) => {
  if (file) {
    if (file.extension === 'pdf') {
      return {
        url: URL.createObjectURL(file),
        icon: <PdfIcon />,
      };
    } else if (file.extension === 'jpg' || file.extension === 'jpeg' || file.extension === 'png') {
      return {
        url: URL.createObjectURL(file),
        icon: <ImageIcon />,
      };
    } else {
      return null; // Handle other file types here
    }
  }
  return null;
};

export const getImagePreview = (image) => {
    if (image) {
        return URL.createObjectURL(image);
    }
    return null;
};


export const obtenerUltimoSegmento=(cadena)=>{
    const segmentos         =   cadena.split('/');
    const ultimoSegmento    =   segmentos[segmentos.length - 1];
    return ultimoSegmento;
}

export function groupItemsByInitialLetter(response,keys) {
  const groupedItems = {};

  response.data.forEach((item) => {

    let initialLetter ;

    if(item.name){
      initialLetter = item.name.charAt(0).toUpperCase();
    }

    if(item[keys]){
      initialLetter = item[keys].charAt(0).toUpperCase();
    }
    
    if (!groupedItems[initialLetter]) {
      groupedItems[initialLetter] = [];
    }
    groupedItems[initialLetter].push(item);
  });

  // Obtener las letras iniciales como un array y ordenarlas
  const initialLetters = Object.keys(groupedItems).sort();

  // Crear un nuevo objeto para almacenar los elementos agrupados en orden alfabético
  const groupedItemsAlphabetical = {};

  // Recorrer el array ordenado y copiar los elementos
  initialLetters.forEach((letter) => {
    groupedItemsAlphabetical[letter] = groupedItems[letter];
  });


  return groupedItemsAlphabetical;
}

export const formatearMontoEnCOP=(monto)=>{
    if(monto){
      // Configura las opciones de formato
      const opcionesDeFormato = {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 2, // Mínimo de 2 decimales
        maximumFractionDigits: 2, // Máximo de 2 decimales
      };
    
      // Formatea el monto utilizando las opciones de formato
      return monto.toLocaleString("es-CO", opcionesDeFormato);
    }else{
      return monto;
    }
    
  }
  
  
export const limitarCadena=(cadena, maxLength)=> {
  if (cadena.length > maxLength) {
    return cadena.slice(0, maxLength); // Recorta la cadena a la longitud máxima
  }
  return cadena; // Devuelve la cadena original si no supera la longitud máxima
} 