import { Grid } from "@mui/material"
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useState } from "react";

const Counter=({defaultValue, event  , keys})=>{
    const [count,setCount]  =   useState(defaultValue)
    const handleSum=(num)=>{
        setCount(count+num)
        if (event) {
            event(keys,count+num) 
        }
    }
    return  <Grid container justifyContent={"right"}>
                <Grid item xs="auto">
                    <Grid variant="counter" sx={{mr:1}}>
                        <Grid sx={{mr:1}}>
                            <RemoveCircleOutlineIcon className="cursor-pointer" onClick={()=>handleSum(-1)}/>
                        </Grid> 
                        <Grid sx={{mr:1}}>
                            {count}
                        </Grid> 
                        <Grid>
                            <ControlPointIcon className="cursor-pointer" onClick={()=>handleSum(1)}/>
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
}
export default Counter