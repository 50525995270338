import {Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
const SubMenu=({option})=>{
    return <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={6} xl={4}>
                    <Grid className='contentSubMenu'>
                        <Grid container>
                        <Grid item xs={4} align="center">
                            <Button variant={option==='negociacionInternacional'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/negociacionInternacional">
                                Negociación
                            </Button>
                        </Grid>
                        <Grid item xs={4} align="center">
                            <Button variant={option==='partial_transport'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/negociacionInternacional/partial_transport">
                                Transporte Parcial
                            </Button>
                        </Grid>
                        <Grid item xs={4} align="center">
                            <Button variant={option==='total_transport'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/negociacionInternacional/total_transport">
                                Transporte Total
                            </Button>
                        </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
}
export default SubMenu;