import {Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
const SubMenu=({option})=>{
    console.log(option)
    return <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={8}>
                <Grid className='contentSubMenu'>
                    <Grid container>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='transporte'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/transporte">
                            Pendiente 
                        </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='transport_sent'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/transporte/transport_sent">
                            Enviado
                        </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='transport_delivered'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/transporte/transport_delivered">
                            Entregado
                        </Button>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
}
export default SubMenu;