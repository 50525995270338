import { useContext, useEffect } from 'react';
import Input        from '../../../components/Input';
import Button       from '@mui/material/Button';
//import { NavLink }  from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import useFormData  from '../../../hooks/useFormData';
import useAxios     from '../../../hooks/useAxios';
import StateContext from '../../../utils/ContextState';
import useAsyncStorage     from '../../../hooks/useAsyncStorage';
import { useNavigate, useLocation } from "react-router-dom";

import right2 from '../../../assets/png/elementBottonRight2.png'
import left2 from '../../../assets/png/elementTopLeft2.png'
import { useParams } from 'react-router-dom';

const WebLogin=()=>{
    const { email }               =   useParams();
    const userStorage             =   useAsyncStorage("user");
    const context                 =   useContext(StateContext);
    const { formData, onChange }  =   useFormData();
    const axios                   =   useAxios();
    const navigate                =   useNavigate();
    const location                =   useLocation();
    

    const onSubmit=(e)=>{
        e.preventDefault()
        
    }

    useEffect(()=>{

      const getInit=()=>{
        axios.getData({email:email}).then((response)=>{
          console.log(response)
        })
      }

      getInit()

    },[])

    return  <>  

              <Grid className="left">
                <img alt="" src={left2}/>
              </Grid>
              <Grid className="right">
                <img alt="" src={right2}/>
              </Grid>
              <Grid container className='vh100'>
                <Grid item xs={12} md={6} className='flex'>
                  <Grid className='flex-center' align="center">
                    <Grid>
                      <form  onSubmit={onSubmit}>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={10} md={7}>  
                              <Typography sx={{fontSize:28,fontWeight:"bold"}}>
                                  {email}
                              </Typography>
                            </Grid>
                            <Grid item xs={10} md={7} sx={{mb:5,mt:2}}>  
                              <Typography sx={{fontSize:16}}>
                                Ingresa tu nueva contraseña
                              </Typography>
                            </Grid>
                            <Grid item xs={10}>
                              <Input sx={{mb:2}} required onChange={onChange} size="small" fullWidth name="email" type="email" label="Correo electrónico"/>
                            </Grid>
                            <Grid item xs={11} sx={{maxWidth:"80%"}}>  
                              <Button style={{ display: 'block', width: '100%' }} 
                                size="large" 
                                variant='contained' 
                                type="submit"
                              >
                                Recuperar contraseña
                              </Button>
                            </Grid>
                        </Grid>
                        
                      </form>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
}
export default WebLogin;