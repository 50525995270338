import { useContext, useEffect, useState  } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';
import Select from '../../../components/Select/GroupSelect';
import UploadIcon from '@mui/icons-material/Upload';
import StateContext from '../../../utils/ContextState';
import {getImagePreview} from '../../../utils/functions'
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import useAxios from '../../../hooks/useAxios';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Loading from '../../../components/Loading/LinearWithLabel';
import FileContent from '../../../components/FileContent';

let inputs            =   {};
const timeLoading     =   2000;

const Home=()=>{
  const { getInit }                   =   useAxios();
  let { id }                          =   useParams();  
  const navigate                      =   useNavigate();
  const axios                         =   useAxios()
  const { formData, onChange }        =   useFormData();
  const context                       =   useContext(StateContext);
  const userStorage                   =   useAsyncStorage("formProduct");
  const [loading,setLoading]          =   useState(true);
  const [image, setImage]             =   useState([]); // Estado para la imagen principal

  const onChange2 = (name,value)=>{

    if (!value||!value.id) {
      return false;
    }

    inputs[name]  =  value.id;

    let return_   = []
    context.config.subcategory.map((row)=>{
      if (row.category_id===value.id) {
        return_.push(row)  
      }      
    }) 

  }

  const setMainImages=(e)=>{
    let image_  = [...image]
    image_.push(e.target.files[0])
    setImage(image_)
  }


  const handleSubmit = (event) => {
    
    event.preventDefault(); // Evita el comportamiento predeterminado del formulario

    axios.postUpload({
                        ...formData,
                        ...inputs,                        
                        name:inputs.social_reason,
                        'bank.attachments[]':image[0]
                      },"api/provider/update/"+id).then((response)=>{
      if (response&&response.data) {
        userStorage.setData(response.data)
        navigate('/dashboard/proveedores')
      }
    })
    
  };

  useEffect(()=>{
    if (id==="new") {
      setLoading(false)
      //userStorage.removeData();  
      return;
    }    
    getInit("provider",{},"include=city.country,bank_account&filter=id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {      
        inputs=response.data[0]; 
        if (inputs.attachments) {
          setImage(inputs.attachments)  
          delete inputs.attachments;
        }  
        setTimeout(() => {
          setLoading(false)
        }, timeLoading);              
      }      
    })    
  },[])


  
  useEffect(()=>{
    if(userStorage.data && id==="new"){
      inputs=userStorage.data
    }
  },[userStorage.data])
  
  

  return  <form onSubmit={handleSubmit}>
            <Container {...useFormData()} onClickSubmit={true}>
              <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
                <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                  <Grid  className='borderBottom' >
                    <Typography component={"h3"}>
                      {inputs.social_reason||"Nuevo registro"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                  <Typography component={"h3"} align='center'>
                    {inputs.social_reason||"Nuevo registro"}
                  </Typography>                
                </Grid>              
              </Grid>
              <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={8}>
                  <Grid className='contentSubMenu'>
                    <Grid container>
                      <Grid item xs={6} align="center">
                        <Button variant='btnTag' component={NavLink} to={"/dashboard/proveedores/"+id}>
                          Datos proveedor
                        </Button>
                      </Grid>
                      <Grid item xs={6} align="center">
                        <Button variant='btnTagSelect' component={NavLink} to={"/dashboard/proveedores/"+id+"/cuentas"}>
                          Cuenta bancaria
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {
                loading&&(
                  <Grid>
                    <Loading time={timeLoading} increment={timeLoading}/>
                  </Grid>
                )
              }

              {
                !loading&&(
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} >
                      <Select defaultValue={inputs.bank_account&&inputs.bank_account.country_id?inputs.bank_account.country_id:0} label="País" name="bank.country_id" data={context.config.countries} onChange={onChange2}/>                
                    </Grid>  
                    <Grid item xs={12} md={6} sx={{display:{xs:"none",md:"block"}}}>
                      
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                               
                              defaultValue={inputs.bank_account&&inputs.bank_account.country_id?inputs.bank_account.bank:""}
                              onChange={onChange} 
                              size="small" 
                              name="bank.bank" 
                              label="Banco"/>
                    </Grid>  
                    
                    <Grid item xs={12} md={6} >
                      <Select defaultValue={inputs.bank_account&&inputs.bank_account.type?inputs.bank_account.type:""} 
                              label="Tipo de cuenta" 
                              name="bank.type" 
                              data={[
                                    {
                                      id:"Ahorros",
                                      name:"Ahorros",
                                    },
                                    {
                                      id:"Corriente",
                                      name:"Corriente",
                                    },
                                    {
                                      id:"Internacional",
                                      name:"Internacional",
                                    },
                              ]} onChange={onChange2}/>                
                    </Grid>  
                  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}}                                
                              defaultValue={inputs.bank_account&&inputs.bank_account.account_number?inputs.bank_account.account_number:""} 
                              onChange={onChange} 
                              size="small" 
                              name="bank.account_number" 
                              label="Número de cuenta"/>
                    </Grid>  
                    <Grid item xs={12} md={2} >                      
                      <Button variant='btnUpload' className='content-image-upload'>
                        <input type="file" name="bank.attachments" className='input' onChange={setMainImages} />
                        <UploadIcon sx={{fontSize:20}}/> Subir Documentos
                      </Button>                      
                    </Grid>   
                    <Grid item xs={12} md={2} >                      
                      {
                        image.length>0&&(
                          <FileContent files={image}/>                     
                        )
                      }           
                    </Grid>                    
                  </Grid>
                )
              }              
            </Container>
          </form>
}
export default Home
