import { useContext, useState, useEffect } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';
import UploadIcon from '@mui/icons-material/Upload';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import Select from '../../../components/Select/GroupSelect';
import StateContext from '../../../utils/ContextState';
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import useAxios from '../../../hooks/useAxios';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import {getImagePreviewURL} from '../../../utils/functions';
import Loading from '../../../components/Loading/LinearWithLabel';


let inputs  = {}
const timeLoading                     =   2000;

const dispatch_to=[
  {
    id:"Puerto",
    name:"Puerto",
  },
  {
    id:"Centro de acopio",
    name:"Centro de acopio",
  },
]

const Home=()=>{
  const { getInit }               =   useAxios();
  let { id }                      =   useParams();
  const context                   =   useContext(StateContext);
  const { formData, onChange }    =   useFormData();
  const [mainImage, setMainImage] =   useState(null); // Estado para la imagen principal
  const userStorage               =   useAsyncStorage("formProduct");
  const navigate                  =   useNavigate();
  const axios                     =   useAxios()
  const [loading,setLoading]      =   useState(true);

  const onChange2 = (name,value)=>{
    if (!value||!value.id) {
      return false;
    }

    inputs[name]  =  value.id;

    let return_=[]
    context.config.subcategory.map((row)=>{
      if (row.category_id===value.id) {
        return_.push(row)  
      }      
    })     
          
  }

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita el comportamiento predeterminado del formulario
    // Realiza cualquier otra lógica que necesites aquí
    userStorage.setData(inputs)
    //navigate('./vehiculo')

    axios.postUpload({
            ...formData,
            ...inputs,            
          },"api/negotiation/create").then((response)=>{
      if (response&&response.data) {
      userStorage.setData(response.data)
      navigate('/dashboard/negociacionInternacional/'+response.data.id+'/formapago')
      }
    })
    
  };

  useEffect(()=>{
    if (id==="new") {
      inputs={}
      setLoading(false)
      userStorage.removeData();  
      return;
    }    
    getInit("negotiation",{},"include=city.country,bank_account&filter=id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {      
        inputs=response.data[0]; 
        setTimeout(() => {
          setLoading(false)
        }, timeLoading);              
      }      
    })    
  },[])

  return  <form onSubmit={handleSubmit}>
            <Container {...useFormData()} onClickSubmit={true}>
              <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
                <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                  <Grid  className='borderBottom' >
                    <Typography component={"h3"}>
                      Negociación Internacional #{inputs.id}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"},mt:{xs:2}}}>
                  <Typography component={"h3"} align='center'>
                    Negociación Internacional #{inputs.id}
                  </Typography>                
                </Grid>              
              </Grid>
              <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={8}>
                  <Grid className='contentSubMenu'>
                    <Grid container>
                      <Grid item xs={4} align="center">
                        <Button variant='btnTagSelect' component={NavLink} to={"/dashboard/negociacionInternacional/"+id}>
                          Negociación
                        </Button>
                      </Grid>
                      <Grid item xs={4} align="center">
                        <Button variant='btnTag' component={NavLink} to={"/dashboard/negociacionInternacional/"+id+"/formapago"}>
                          Forma de Pago
                        </Button>
                      </Grid>
                      <Grid item xs={4} align="center">
                        <Button variant='btnTag' component={NavLink} to={"/dashboard/negociacionInternacional/"+id+"/productos"}>
                          Productos
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {
                loading&&(
                  <Grid>
                    <Loading time={timeLoading} increment={timeLoading}/>
                  </Grid>
                )
              }
              {
                !loading&&(
                  <Grid container >
                    <Grid item xs={12} md={5} >
                      <Input  onChange={onChange} 
                              defaultValue={inputs.negotiation_date} 
                              size="small" 
                              name="negotiation_date"
                              type="date" 
                              label="Fecha "/>
                    </Grid>
                    <Grid item xs={0} md={2} sx={{display:{xs:"none",md:"block"}}}>
                        
                    </Grid>  
                    <Grid item xs={12} md={5} sx={{mb:2,mt:1}}>
                      <Grid container>
                        <Grid item xs={6} align="left" sx={{pt:1}}><b>Estado Pago</b></Grid>
                        <Grid item xs={6} align="right"><Button variant="contained" color='red'>No Pago</Button></Grid>
                      </Grid>
                    </Grid> 
                    <Grid item xs={8} md={5} >
                      <Select defaultValue={inputs.provider_id} label="Proveedor" name="provider_id" data={context.config.providers} onChange={onChange2}/>                
                    </Grid>  
                    <Grid item xs={4} md={2} >
                      <IconButton color="green" size="small" variant="text"><WhatsAppIcon sx={{fontSize:22}} /></IconButton>
                      <IconButton color="blue" size="small" variant="text"><PhoneForwardedIcon sx={{fontSize:22}}  /></IconButton>                    
                    </Grid>  
                    <Grid item xs={12} md={5} sx={{mb:2,mt:1}}>
                      <Grid container>
                        <Grid item xs={6} align="left" sx={{pt:1}}><b>Estado Negociación</b></Grid>
                        <Grid item xs={6} align="right"><Button variant="contained" color='red'>No Pago</Button></Grid>
                      </Grid>
                    </Grid> 
                    <Grid item xs={12} md={5} sx={{mb:2}}>
                      <Select defaultValue={inputs.dispatch_to} label="Despacho a" name="dispatch_to" data={dispatch_to} onChange={onChange2}/>                                
                    </Grid>  
                    <Grid item xs={0} md={2} sx={{display:{xs:"none",md:"block"}}}>
                        
                    </Grid>  
                    <Grid item xs={12} md={5} >
                      <Input  onChange={onChange} 
                              defaultValue={inputs.negotiation_value} 
                              size="small" 
                              type={"number"}
                              name="negotiation_value" 
                              label="Valor Negociacón"/>
                    </Grid>  
                    <Grid item xs={12} md={5} sx={{mb:2,mt:1}}>
                      <Grid sx={{display:{xs:"none",md:"block"}}}>
                        <Grid container>
                          <Grid item xs={6}>
                            <span style={{paddingTop:"20px"}}>Documentos Adjuntos</span> 
                          </Grid>
                          <Grid item xs={3} className='content-image-upload'>
                            <input type="file" name="bank.attachments[]" className='input' onChange={(e) => setMainImage(e.target.files[0])} />
                            <TextSnippetIcon sx={{fontSize:50,float:"right",color:"#2fca5a"}}/>  
                          </Grid>
                          <Grid item xs={3} className='content-image-upload'>
                            {
                              mainImage&&(<img className='img-fluid'  src={getImagePreviewURL(mainImage)} alt="Programandoweb" />)
                            }                                     
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid sx={{display:{md:"none",xs:"block"}}}>
                        <Button variant='btnUpload' className='content-image-upload'>
                          <input type="file" name="bank.attachments[]" className='input' onChange={(e) => setMainImage(e.target.files[0])} />
                          <UploadIcon sx={{fontSize:20, }}/> Subir Documentos
                        </Button>
                      </Grid>                
                    </Grid>
                  </Grid>
                )
              }
              
            </Container>
          </form>
}
export default Home
