/*set components*/
import Home  from './Home';
import HomeInternacional  from './HomeInternacional';
import Edit  from './Edit';
import Cuentas  from './Cuentas';
/*set icons*/
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

const Testing=()=>{
  return <div>PRUEBA</div>
}

export const routes_modules = [
  {
    name: "Home",
    path: "",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Home",
    path: "/internacionales",
    component: HomeInternacional,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Edit",
    path: "/:id",
    component: Edit,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Edit",
    path: "/internacionales/:id",
    component: Edit,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Cuentas Bancarias",
    path: "/:id/cuentas",
    component: Cuentas,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Cuentas Bancarias",
    path: "/internacionales/:id/cuentas",
    component: Cuentas,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
];
