import { useEffect,  useContext , useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import UploadIcon from '@mui/icons-material/Upload';
import SubMenuInterno from './SubMenuInterno';
import {getImagePreviewURL} from '../../../utils/functions';
import Select from '../../../components/Select/GroupSelect';
import StateContext from '../../../utils/ContextState';
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import useAxios from '../../../hooks/useAxios';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading/LinearWithLabel';



const dispatch_to=[
  {
    id:"Puerto",
    name:"Puerto",
  },
  {
    id:"Centro de acopio",
    name:"Centro de acopio",
  },
]

const state_id=[
  {
    id:"transport_pending",
    name:"Pendiente",
  },
  {
    id:"transport_sent",
    name:"Enviado",
  },
  {
    id:"transport_delivered",
    name:"Entregado",
  },
]

let inputs  = {}
const timeLoading                     =   2000;

const Home=(props)=>{
  let { id }                      =   useParams();
  const context                   =   useContext(StateContext);
  const { formData, onChange }    =   useFormData();
  const userStorage               =   useAsyncStorage("formProduct");
  const navigate                  =   useNavigate();
  const axios                     =   useAxios();
  const { getInit }               =   useAxios();  
  const [mainImage, setMainImage] =   useState(null); // Estado para la imagen principal
  const [loading,setLoading]      =   useState(true);
  const [image, setImage]         =   useState(null); // Estado para la imagen principal

  const onChange2 = (name,value)=>{
    if (!value||!value.id) {
      return false;
    }

    inputs[name]  =  value.id;

    let return_=[]
    context.config.subcategory.map((row)=>{
      if (row.category_id===value.id) {
        return_.push(row)  
      }      
    })     
          
  }

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita el comportamiento predeterminado del formulario
    // Realiza cualquier otra lógica que necesites aquí
    userStorage.setData(inputs)
    //navigate('./vehiculo')

    if (id==='new') {
      axios.postUpload({
        ...formData,
        ...inputs,            
      },"api/transporter/create").then((response)=>{
  
        if (response&&response.data&&response.data.id&&response.code===200) {
          navigate('/dashboard/transporte/'+(response.data.id)+'/productos')
        }
      })  
    }else{
      axios.postUpload({
        ...formData,
        ...inputs,            
      },"api/transporter/update/"+id).then((response)=>{
        if (response&&response.data&&response.data.id&&response.code===200) {
          navigate('/dashboard/transporte/'+id+'/productos')
        }
      })
    }

    
    
  };

  useEffect(()=>{
    if (id==="new") {
      inputs={}
      setLoading(false)
      userStorage.removeData();  
      return;
    }  
    getInit("transporter",{},"include=state,provider,products&filter=id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {
        inputs=response.data[0];
        if (inputs.attachments&&inputs.attachments[0]&&inputs.attachments[0].url) {
          setImage(inputs.attachments[0].url)  
        }        
        setTimeout(()=>{
          setLoading(false);
        },timeLoading)
      }      
    })
  },[])


  return  <form onSubmit={handleSubmit}>
            <Container {...useFormData()} save={"/"} onClickSubmit={true}>
              <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
                <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                  <Grid  className='borderBottom' >
                    <Typography component={"h3"}>
                      Transporte #{inputs.id}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                  <Typography component={"h3"} align='center'>
                    Transporte #{inputs.id}
                  </Typography>                
                </Grid>              
              </Grid>

              <SubMenuInterno option={""} id={id}/>
  
              {
                loading&&(
                  <Grid>
                    <Loading time={timeLoading} increment={timeLoading}/>
                  </Grid>
                )
              }

              {
                !loading&&(
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} >
                      <Select defaultValue={inputs.provider_id} label="Proveedor" name="provider_id" data={context.config.providers} onChange={onChange2}/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Select defaultValue={inputs.dispatch_to} label="Despacho a" name="dispatch_to" data={dispatch_to} onChange={onChange2}/>                                
                    </Grid> 
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              defaultValue={inputs.departure_date} 
                              onChange={onChange} 
                              size="small" 
                              type="date" 
                              name="departure_date" 
                              label="Fecha de Salida"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              defaultValue={inputs.arrival_date} 
                              onChange={onChange} 
                              size="small" 
                              type="date" 
                              name="arrival_date" 
                              label="Fecha de Llegada"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              defaultValue={inputs.conveyor}
                              onChange={onChange} 
                              size="small" 
                              name="conveyor" 
                              label="Transportadora"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              defaultValue={inputs.guide_number}
                              onChange={onChange} 
                              size="small" 
                              name="guide_number" 
                              label="Número de Guía"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              defaultValue={inputs.transport_value}
                              onChange={onChange} 
                              size="small" 
                              name="transport_value" 
                              type="number"
                              label="Valor Tranporte"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Select defaultValue={inputs.state_id} label="Estado" name="state_id" data={state_id} onChange={onChange2}/>                 
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      
                    </Grid>
                    <Grid item xs={3}>
                      <Button variant='btnUpload' className='content-image-upload'>
                        <input type="file" name="bank.attachments[]" className='input' onChange={(e) => setMainImage(e.target.files[0])} />
                        <UploadIcon sx={{fontSize:20}}/> Subir Documentos
                      </Button>
                    </Grid>
                    <Grid item xs={3} className='content-image-upload'>
                      {
                        mainImage&&(<img className='img-fluid'  src={getImagePreviewURL(mainImage)} alt="Programandoweb" />)
                      }     
                      {
                        image&&(<img className='img-fluid'  src={image} alt="Programandoweb" />)
                      }                                
                    </Grid>
                  </Grid>
                )
              }

              
              
            </Container>
          </form>
}
export default Home
