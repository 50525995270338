//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Grid, Typography } from '@mui/material';
import Item from './Items3';
import Input        from '../../../components/Input/GroupInput';
import StateContext from '../../../utils/ContextState'
import React from 'react';
import { NavLink } from 'react-router-dom';



const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },  
]


const Modal = ()=>{
  const context   =   React.useContext(StateContext)
  const onChange=()=>{

  }
  return  <Grid sx={{p:4}}>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs={12}>
                  <b>Pagos</b>
              </Grid>
              <Grid item xs={12} md={6} >
                <Input 
                      sx={{mb:0}}
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Fecha Pago"/>              
              </Grid>              
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Categoría"/>              
              </Grid>   
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Subcategoría"/>              
              </Grid>              
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Tipo de transacción"/>              
              </Grid>              
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Método de pago"/>              
              </Grid> 
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Estado de pago"/>              
              </Grid> 
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Valor"/>              
              </Grid>             
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Comentario"/>              
              </Grid>  
            </Grid>
          </Grid>  
}


const Home=(props)=>{
  /*
  const context                   =   React.useContext(StateContext)
  const handleOpenModal=()=>{
    context.setOpenDialog({
      open:true,
      component:<Modal/>
    })
  }
  */
  //const [items,setItems]        =   useState([])
  //const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                    Gastos de Importación
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"}>
                  Gastos de Importación
                </Typography>
              </Grid>              
              <Grid container justifyContent={"center"} sx={{mt:1}}>
                <Grid item xs={11} md={6}  sx={{mb:1,mt:1}}>
                  <Input  
                          required 
                          size="small" 
                          name="email" 
                          label="Fecha Pago"/>              
                </Grid>
                <Grid item xs={11} md={6} sx={{mb:1,mt:1}}>
                  <Input  sx={{mb:1,mt:1}}
                          required 
                          size="small" 
                          name="email" 
                          label="Categoría"/>              
                </Grid>
                <Grid item xs={11} md={6} sx={{mb:1,mt:1}}>
                  <Input  required 
                          size="small" 
                          name="email" 
                          label="Subcategoría"/>              
                </Grid>
                <Grid item xs={11} md={6} sx={{mb:1,mt:1}}>
                  <Input  required 
                          size="small" 
                          name="email" 
                          label="Tipo de transaccion"/>              
                </Grid>
                <Grid item xs={11} md={6} sx={{mb:1,mt:1}}>
                  <Input  required 
                          size="small" 
                          name="email" 
                          label="Método de pago"/>              
                </Grid>
                <Grid item xs={11} md={6} sx={{mb:1,mt:1}}>
                  <Input  required 
                          size="small" 
                          name="email" 
                          label="Estado de pago"/>              
                </Grid>
                <Grid item xs={11} md={6} sx={{mb:1,mt:1}}>
                  <Input  required 
                          size="small" 
                          name="email" 
                          label="Valor"/>              
                </Grid>
                <Grid item xs={11} md={6} sx={{mb:1,mt:1}}>
                  <Input  required 
                          size="small" 
                          name="email" 
                          label="Comentario"/>              
                </Grid>
                <Grid item xs={11} md={6} sx={{mb:1,mt:1}}>
                  <Input  required 
                          size="small" 
                          name="email" 
                          label="Asociado a"/>              
                </Grid>
                <Grid item xs={11} md={6} sx={{mb:1,mt:1}}>
                  <Input  required 
                          size="small" 
                          name="email" 
                          label="Código"/>              
                </Grid>
              </Grid>
            </Grid>
          </Container>
}
export default Home
