import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Typography, Grid, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Input        from '../../../components/Input/GroupInput';
import SubMenuInterno from './SubMenuInterno';
import StateContext from '../../../utils/ContextState'
import React from 'react';
import { useParams } from 'react-router-dom';


const items   =   [
  {
    label:"Roberto Manriquess",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  
]

const Item=({row,nro})=>{
  return  <Grid item xs={12} md={3}> 
            <Paper sx={{p:2}}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                    Pago {nro+1}
                  </Grid>
                  <Grid className='item-name'>
                    {row.label}
                  </Grid>
                  <Grid className='item-date'>
                    Fecha de negociación
                  </Grid>
                  <Grid className='item-date2'>
                    {row.fecha}
                  </Grid>
                  <Grid className='item-price'>
                    ${row.precio}
                  </Grid>                  
                </Grid>
                <Grid item xs={4} >
                  <Grid className='flexBottom'>
                    <Button variant={(row.status==='Vencido')?'red':'green'} component={NavLink} to="/dashboard/transporte/0/pagoForm">
                      {row.status==='Vencido'?"No Pago":"Pago"}
                    </Button>  
                  </Grid>                  
                </Grid>
              </Grid>
            </Paper>
          </Grid>
}

const Modal = ()=>{
  const context   =   React.useContext(StateContext)
  const onChange=()=>{

  }
  return  <Grid sx={{p:4}}>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs={12}>
                  <b>Pagos</b>
              </Grid>
              <Grid item xs={12} md={6} >
                <Input 
                      sx={{mb:0}}
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Fecha Pago"/>              
              </Grid>              
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Categoría"/>              
              </Grid>   
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Subcategoría"/>              
              </Grid>              
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Tipo de transacción"/>              
              </Grid>              
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Método de pago"/>              
              </Grid> 
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Estado de pago"/>              
              </Grid> 
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Valor"/>              
              </Grid>             
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Comentario"/>              
              </Grid>  
            </Grid>
          </Grid>  
}

const Home=(props)=>{
  const context                   =   React.useContext(StateContext)
  let { id }                  =   useParams();

  const handleOpenModal=()=>{
    context.setOpenDialog({
      open:true,
      component:<Modal/>
    })
  }
  return  <Container {...useFormData()} save={"/"} add={{event:handleOpenModal,to:"0"}}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                   Transporte #{id}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Transporte #{id}
                </Typography>                
              </Grid>              
            </Grid>
            <SubMenuInterno id={id} option={"pago"}/>
            
            <Grid container spacing={2}>
              {
                items.map((row,key)=>{
                  return <Item key={key} row={row} nro={key} />
                })
              }  
            </Grid>
          </Container>
}
export default Home
