import { useState  } from 'react';
import Avatar from '../Avatar'
import { IconButton, Grid } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {getImagePreview} from '../../utils/functions';
const AvatarWithUpload=({inputs,name})=>{
    const [image, setImage]     =   useState(null);
    const setMainImage=(e)=>{
        setImage(e.target.files[0])
        inputs[name]=e.target.files[0];
    }
    return (
        <Grid sx={{position:"relative"}} className='content-image-upload'>
            <Avatar src={image?getImagePreview(image):inputs.photo&&inputs.photo!==null?inputs.photo:""} sx={{width:100,height:100}}/>
            <IconButton sx={{position:"absolute", bottom:0, right:0}}>
                <input type="file" name={name} className='input' onChange={setMainImage} />
                <CameraAltIcon/>
            </IconButton>
        </Grid>
    )
}
export default AvatarWithUpload