import React from 'react';
import { Grid, IconButton, Tooltip } from "@mui/material";
import { NavLink } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const FileContent = ({ files }) => {
  let icon = null;

  return (
    <Grid container spacing={2}>
      {files.map((file, index) => (
        <Grid item key={index}>
          <Tooltip title="Abrir descargable">
            <IconButton>
              {
                file&&file.url&&(
                  <NavLink to={file.url} target="_blank" rel="noopener noreferrer">
                    {
                      file.url&&file.url.includes('pdf')&&(
                        <PictureAsPdfIcon />
                      )
                    }
                    {
                      file.url&&file.url.includes('docx')&&(
                        <PictureAsPdfIcon />
                      )
                    }
                    {
                      ['jpg', 'jpeg', 'png', 'gif'].some(extension => file.url.includes(extension))&&(
                        <ImageIcon />
                      )
                    }
                    {
                      ['xlsx', 'xls',].some(extension => file.url.includes(extension))&&(
                        <InsertDriveFileIcon />
                      )
                    }
                  </NavLink>
                )
              }
              {
                file instanceof File&&(
                  <>
                    {
                      ['jpg', 'jpeg', 'png', 'gif'].some(extension => file.type.includes(extension))&&(
                        <ImageIcon />
                      )
                    }
                    {
                      ['xlsx', 'xls','pdf',].some(extension => file.type.includes(extension))&&(
                        <InsertDriveFileIcon />
                      )
                    }                    
                  </>                  
                )                
              }              
            </IconButton>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
};

export default FileContent;
