import { useContext , useEffect} from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/List';
import { Button, Grid, Paper, Typography } from '@mui/material';
import StateContext from '../../../utils/ContextState';
import Select from '../../../components/Select'
import { useLocation, useNavigate } from 'react-router-dom';
import Submenu from './SubMenu';
import useAxios from '../../../hooks/useAxios';
import Pagination from '../../../components/Pagination';
import Item from './Items';


function groupItemsByInitialLetter(response) {
  const groupedItems = {};

  response.data.forEach((item) => {
    const initialLetter = item.name.charAt(0).toUpperCase();
    if (!groupedItems[initialLetter]) {
      groupedItems[initialLetter] = [];
    }
    groupedItems[initialLetter].push(item);
  });

  return groupedItems;
}


const Filter=({context})=>{
  return  <Grid sx={{p:4}}>
            <Grid container spacing={3} sx={{mb:2}}>
              <Grid item xs={6}>
                <Select label="País"/>
              </Grid>
              <Grid item xs={6}>
                <Select label="Ciudad"/>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button variant='contained' fullWidth>
                  Aceptar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant='outlined' fullWidth onClick={()=>context.setOpenDialog(false)}>
                  Borrar Filtro
                </Button> 
              </Grid>
            </Grid>
          </Grid>
}



let filterUrl    =   {};

const Home=(props)=>{
  const navigate                =   useNavigate();
  const location                =   useLocation();
  const context                 =   useContext(StateContext);
  const {Map}                   =   context;
  const {getInit}               =   useAxios();
  const {formData,setFormData}  =   useFormData();

  useEffect(()=>{    
    let subcategory   =   ""
    if (filterUrl.filter) {
      subcategory   =   "include=subcategory&order_by=name&filter="+filterUrl.filter;
    }else{
      subcategory   =   "include=city,bank_account&include=subcategory&order_by=name&filter=type:Internacional";
    }    
    if(location.search===''){
      subcategory   =   "include=city,bank_account&include=subcategory&order_by=name&filter=type:Internacional";
    }
    
    getInit("provider", {}, subcategory).then((response) => {
      if (response) {
        const groupedItems = groupItemsByInitialLetter(response);
        setFormData({ groupedItems }); // Añade los elementos agrupados a formData
      }
    });

  },[location.search])

  const setUrl=()=>{
    let new_url = location.pathname+"?include=subcategory&filter=subcategory.id:"+filterUrl.subcategory
    return navigate(new_url);       
  }

  const clearUrl=()=>{
    filterUrl={}
    let new_url = location.pathname
    return navigate(new_url);      
  }
 
 

  const handleFilters=()=>{
    return context.setOpenDialog({
              component:<Filter context={context}/>,
              maxWidth:"sm"
            })
  }

  const onKeyDown=(e)=>{
    filterUrl={
      filter:'name/'+e.target.value
    }
    let new_url = location.pathname+"?include=subcategory&filter="+filterUrl.filter
    return navigate(new_url);       
  }

  return  <Container {...useFormData()} filter={handleFilters} download={"api/provider/export?key="+process.env.REACT_APP_DOWNLOAD} onKeyDown={onKeyDown}>
            <Submenu option={"internacionales"}/>
            
            {
              formData&&formData.groupedItems&&(
                Object.entries(formData.groupedItems).map((row,key)=>{
                  return  <Grid sx={{mb:3}} key={key}>
                            <Grid container spacing={0} >
                              <Grid item xs={12} className='separador-letras'>
                                <Typography sx={{pl:1,pt:0.5,pb:0.5,m:0,fontWeight:"bold"}}>
                                  {
                                    row[0]
                                  }
                                </Typography>
                              </Grid>
                            </Grid>  
                            <Grid container spacing={1} sx={{mb:3}}>
                              {
                                row[1].map((row2,key2)=>{
                                  return <Item key={key2} row={row2} />
                                })
                              }     
                            </Grid>                   
                          </Grid>
                })
              )
            }           
            
            
          </Container>
}
export default Home
