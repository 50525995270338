import {  useEffect, useState  } from 'react';
import useFormData from '../../../hooks/useFormData';
import useAxios from '../../../hooks/useAxios';
import Container    from '../../../theme/Form';
import { Button, Grid, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import galeria from '../../../assets/jpg/galeria'
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import image from '../../../assets/png/image-not-found-scaled2.png';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Loading from '../../../components/Loading/LinearWithLabel';
import {getImagePreview} from '../../../utils/functions';

let inputs  = {}
const timeLoading = 2000;

const Home=()=>{
  let { id }                      =   useParams(); 
  const navigate                  =   useNavigate();
  const axios                     =   useAxios();
  const {getInit}               =   useAxios();
  const userStorage               =   useAsyncStorage("formProduct");
  const [mainImage, setMainImage] =   useState(null); // Estado para la imagen principal
  const [complementaryImages, setComplementaryImages] = useState([]); // Estado para las imágenes complementarias
  const [gallery, setGallery]     =   useState(galeria); // Estado para la imagen principal
  const [loading,setLoading]      =   useState(true);

  const handleImageChange = (e, index) => {
    const newImages   =   [...complementaryImages];
    newImages[index]  =   e.target.files[0];
    setComplementaryImages(newImages);
  };

  
  useEffect(()=>{
    getInit("product",{},"include=subcategory.category,&filter=id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {
        const data_ = {...response.data[0]}
        inputs=data_;            
        if (inputs&&inputs.complementary_photos) {
          let _return=[]
          galeria.map((row,key)=>{
            if (inputs.complementary_photos&&inputs.complementary_photos[key]&&inputs.complementary_photos[key].url) {
              return _return.push(inputs.complementary_photos[key].url);
            }else{
              return _return.push(false);
            }
          })
          setGallery(_return);
        }      
      }      
      setTimeout(() => {
        setLoading(false)
      }, timeLoading); 
    })    
  },[])

  const handleSubmit = (event) => {

    event.preventDefault(); // Evita el comportamiento predeterminado del formulario
    if (inputs&&inputs.subcategory_id&&inputs.subcategory_id.id) {
      inputs.subcategory_id = inputs.subcategory_id.id;  
    }
    
    delete(inputs.subcategory)

    //console.log(complementaryImages)


    axios.postUpload({...inputs,photo:mainImage,complementary_photos:complementaryImages},parseInt(id)>0?"api/product/update/"+id:"api/product/create?").then((response)=>{
      if (response&&response.data) {
        //console.log(response.data)
        userStorage.setData(response.data)
        navigate('../'+response.data.id+"/preview")
      }
    })
    
  };

  const getImagePreviewURL = (image) => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return null;
  };

  const handleDelete=(url)=>{
    const result  = inputs.complementary_photos.find(search=>search.url===url)
    if (!result) {
      return;
    }
    
    axios.deleteData("api/product/delete/resource/"+result.id).then((response)=>{      
      if (response) {
        return  axios.getInit("product",{},"include=subcategory.category,&filter=id:"+id).then((response)=>{
                  if (response&&response.data&&response.data[0]) {
                    userStorage.setData(response.data[0]);                    
                  }      
                })
      }
    })
  }

  return  <form onSubmit={handleSubmit}>
            <Container {...useFormData()} onClickSubmit={true}>
              <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
                <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                  <Grid  className='borderBottom' >
                    <Typography component={"h3"}>
                      {inputs.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                  <Typography component={"h3"} align='center'>
                    {inputs.name}
                  </Typography>                
                </Grid>              
              </Grid>
              {
                loading&&(
                  <Grid>
                    <Loading time={timeLoading} increment={timeLoading}/>
                  </Grid>
                )
              }
              {
                !loading&&(
                  <Grid>
                    <Grid container spacing={2} sx={{mb:4}}>
                      <Grid item xs={12} md={8}>
                        <Grid className='contentSubMenu'>
                          <Grid container>
                            <Grid item xs={6} align="center">
                              <Button variant='btnTag'  component={NavLink} to={"/dashboard/productos/"+id}>
                                Formulario
                              </Button>
                            </Grid>
                            <Grid item xs={6} align="center">
                              <Button variant='btnTagSelect' component={NavLink} to={"/dashboard/productos/"+id+"/fotografia"}>
                                Fotografía
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} >
                        <Typography component={"b"}>
                          Foto Principal
                        </Typography>   
                      </Grid>  
                    </Grid>
                    <Grid container spacing={2} sx={{mb:2}}>
                      <Grid item xs={12} md={4}>
                        <img className='img-fluid' src={inputs.photo || getImagePreviewURL(mainImage) || image} alt="Programandoweb" />
                      </Grid>  
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} >
                        <Typography>Foto Complementarias</Typography>   
                      </Grid>  
                    </Grid>
                    <Grid container spacing={2}>
                      {
                        gallery.map((row,key)=>{
                          return  <Grid item xs={12} md={2} key={key} className='content-image-upload'>
                                    {
                                      (!row||row.includes("/static/media/"))&&(
                                        <input type="file" name={`complementary_photos[${key}]`} className='input' onChange={(e) => handleImageChange(e, key)} />
                                      )
                                    }
                                    {
                                      row&&!row.includes("/static/media/")&&(
                                        <Grid sx={{
                                                    position:"absolute",
                                                    width:"100%",
                                                  }} align="center">
                                          <Button onClick={()=>handleDelete(row)} variant='contained'>
                                            <HighlightOffIcon/>
                                          </Button>                                              
                                        </Grid>
                                      )
                                    }
                                    {
                                      row&&!row.includes("/static/media/")&&(
                                        <img className='img-fluid' src={row} alt="Programandoweb" />
                                      )
                                    }
                                    {
                                      !row&&!complementaryImages[key]&&(
                                        <img className='img-fluid' src={row||image} alt="Programandoweb" />
                                      )
                                    }
                                    {
                                      complementaryImages[key]&&(
                                        <img className='img-fluid' src={getImagePreview(complementaryImages[key])} alt="Programandoweb" />
                                      )
                                    }
                                    
                                  </Grid>  
                        })
                      }              
                    </Grid>                        
                  </Grid>
                )
              }              
            </Container>
          </form>
}
export default Home
