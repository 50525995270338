//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Button, Grid, Link, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';
import cama0 from '../../../assets/png/cama0.png'
import cama1 from '../../../assets/png/cama1.png'
import cama2 from '../../../assets/png/cama2.png'
import React from 'react';
import StateContext from '../../../utils/ContextState'

const Modal = ()=>{
  const context   =   React.useContext(StateContext)
  return  <Grid sx={{p:2}}>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs={12} sx={{mb:2}} align="center">
                <Typography component={"h3"}><b>Seleccionar</b></Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <img src={cama0} className='img-fluid' alt="programandoWeb"/>
              </Grid>
              <Grid item xs={4} md={4}>
                <img src={cama1} className='img-fluid' alt="programandoWeb"/>
              </Grid>
              <Grid item xs={4} md={4}>
                <img src={cama2} className='img-fluid' alt="programandoWeb"/>
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs={4} md={4}>
                <img src={cama0} className='img-fluid' alt="programandoWeb"/>
              </Grid>
              <Grid item xs={4} md={4}>
                <img src={cama1} className='img-fluid' alt="programandoWeb"/>
              </Grid>
              <Grid item xs={4} md={4}>
                <img src={cama2} className='img-fluid' alt="programandoWeb"/>
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs={4} md={4}>
                <img src={cama0} className='img-fluid' alt="programandoWeb"/>
              </Grid>
              <Grid item xs={4} md={4}>
                <img src={cama1} className='img-fluid' alt="programandoWeb"/>
              </Grid>
              <Grid item xs={4} md={4}>
                <img src={cama2} className='img-fluid' alt="programandoWeb"/>
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"} spacing={1}>
              <Grid item xs={7} align="center">
                <Button fullWidth variant='contained'>Guardar</Button>
              </Grid>
              <Grid item xs={7} align="center">
                <Button fullWidth variant='contained' onClick={()=>{context.setOpenDialog(false)}}>Volver</Button>
              </Grid>
            </Grid>
          </Grid>  
}

const Home=(props)=>{
  const context   =   React.useContext(StateContext)
  const { formData, onChange }    =   useFormData();
  const handleOpenModal=()=>{
    context.setOpenDialog({
      maxWidth:"xs",
      open:true,
      component:<Modal/>
    })
  }
  return  <Container {...useFormData()} skipSave={true}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"} align='center'>
                    <b>Cama</b>
                  </Typography>
                  <Typography component={"h4"} align='center'>
                    Peso Total: 20Kg
                  </Typography>                
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  <b>Cama</b>
                </Typography>
                <Typography component={"h4"} align='center'>
                  Peso Total: 20Kg
                </Typography>                 
              </Grid>              
            </Grid>
            <Grid container spacing={1} sx={{mb:3}}>
              <Grid xs={12}> 
                Gráfica
              </Grid>
              <Grid xs={12} sx={{mt:2}}> 
                <Button variant='contained' onClick={handleOpenModal}>Seleccionar Gráfica</Button>
              </Grid>
              
            </Grid>  
            <Grid container spacing={1} sx={{mb:3}}>
              <Grid item xs={12} md={6} >
                <Grid sx={{mb:1}}>Producto</Grid>
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Product"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Grid sx={{mb:1}}>Peso Total</Grid>
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Peso Total"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Grid sx={{mb:1}}>Comentarios</Grid>
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Comentarios"/>
              </Grid>  
            </Grid>  
            
            <Grid align="center">
              <Button component={NavLink} to="0" variant='contained'>Agregar Producto</Button>
            </Grid> 
          </Container>
}
export default Home
