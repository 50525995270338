import {Button, Grid, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import mula from '../../../assets/png/mula.png'
const Item=({row,nro})=>{
    return  <Grid item xs={12} md={4}> 
              <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      <b>Categoría:</b> Gasto
                    </Grid>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      <b>Subcategoría:</b> Importación
                    </Grid>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      <b>Tipo de transacción:</b> Remanufactura
                    </Grid>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      <b>Valor pago:</b> $50.000 USD
                    </Grid>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      <b>Fecha de pago:</b> 10/10/2023
                    </Grid>
                  </Grid>
                  <Grid item xs={4} >
                    <Grid sx={{mt:7}}>
                      <Button variant={'green'} component={NavLink} to="/dashboard/importacion/0">
                        Pago
                      </Button>  
                    </Grid>                  
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }
  
export default Item