import {Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
const SubMenu=({option})=>{
    console.log(option)
    return <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={8}>
                <Grid className='contentSubMenu'>
                    <Grid container>
                    <Grid item xs={4} align="center">
                        <Button variant={option===''?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/comprasProvNacionales">
                            Todos 
                        </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='pagos'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/comprasProvNacionales/pagos">
                            Pagos
                        </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='nopagos'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/comprasProvNacionales/nopagos">
                            No Pagos
                        </Button>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
}
export default SubMenu;