import {Button, Grid, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import mula from '../../../assets/png/mula.png'

const Item=({row,nro})=>{
    return  <Grid item xs={12} md={4}> 
              <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      TGD
                    </Grid>
                    <Grid className='item-name'>
                      Unidades Transportadas: 3 
                    </Grid>
                    <Grid className='item-date'>
                      Peso unitario $50
                    </Grid>
                    <Grid className='item-date2'>
                      Valor unitario $50
                    </Grid>
                    <Grid className='item-price'>
                      Valor transporte $50
                    </Grid>                  
                    <Grid align="left">
                      <Button variant={(row.status!=='Llegó')?'red':'green'} component={NavLink} to="/dashboard/importacion/0">
                        Estado {row.status==='Llegó'?"Llegó":"No Llegó"}
                      </Button>  
                    </Grid>                  
                  </Grid>
                  <Grid item xs={4} align="right">
                    <img className='img-fluid' src={mula} alt="Programandoweb"/>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }
  
export default Item