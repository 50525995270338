import { useState, useContext , useEffect} from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/List';
import { Button, Grid, Typography } from '@mui/material';
import StateContext from '../../../utils/ContextState';
import Select from '../../../components/Select'
import { useLocation, useNavigate } from 'react-router-dom';
import Submenu from './SubMenu';
import useAxios from '../../../hooks/useAxios';
import Pagination from '../../../components/Pagination';
import Item from './HomeItems';
import { obtenerUltimoSegmento } from '../../../utils/functions';




let filterUrl       =   {};
const endpoint      =   "negotiation";

const Home=()=>{
  const navigate                =   useNavigate();
  const location                =   useLocation();
  const context                 =   useContext(StateContext);
  const {Map}                   =   context;
  const {getInit}               =   useAxios();
  const {formData,setFormData}  =   useFormData();
  

  useEffect(()=>{    
    
    let subcategory   =   ""

    const ext =   (obtenerUltimoSegmento(location.pathname)==='negociacionInternacional')?"&filter=state_id:negotiation":"&filter=state_id:"+obtenerUltimoSegmento(location.pathname);

    if (filterUrl.filter) {
        subcategory   =   "include=state,provider,products&filter="+filterUrl.filter;
    }else{
        subcategory   =   "include=state,provider,products"+ext;
    }    
    if(location.search===''){
        subcategory   =   "include=state,provider,products"+ext;
    }
    
    getInit(endpoint, {}, subcategory).then((response) => {
      if (response) {
        setFormData(response); // Añade los elementos agrupados a formData
      }
    });

  },[location])

  const handleFilters=()=>{
    return context.setOpenDialog({
              component:<Filter context={context}/>,
              maxWidth:"sm"
            })
  }

  const Filter=()=>{    
    
    const [subcategory,setSubCategory]          =   useState([]);

    const onChange=(name,value)=>{

      let return_=[]
      context.config.cities.map((row)=>{
        if (row.country_id===value.id) {
          return_.push(row)  
        }      
      }) 
      
      if (name==='category') {
        setSubCategory(return_)  
      }    
      filterUrl[name] = value.id
      
    }

    const setUrl=()=>{
      let new_url = location.pathname+"?include=subcategory&filter=city_id:"+filterUrl.city_id
      return navigate(new_url);       
    }

    const clearUrl=()=>{
      filterUrl={}
      let new_url = location.pathname
      return navigate(new_url);      
    }
   
    


    return  <Grid sx={{p:4}}>
              <Grid container spacing={3} sx={{mb:2}}>
                <Grid item xs={6}>
                  <Select label="País" name="category" data={context.config.countries} onChange={onChange}/>
                </Grid>
                <Grid item xs={6}>
                  <Select label="Ciudad" name="city_id" data={subcategory} onChange={onChange}/>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button variant='contained' fullWidth onClick={()=>{setUrl();context.setOpenDialog(false);}}>
                    Aceptar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant='outlined' fullWidth onClick={()=>{clearUrl();context.setOpenDialog(false);}}>
                    Borrar Filtro
                  </Button> 
                </Grid>
              </Grid>
            </Grid>
  }

  const onKeyDown=(e)=>{
    filterUrl={
      filter:'name/'+e.target.value
    }
    let new_url = location.pathname+"?include=subcategory&filter="+filterUrl.filter
    return navigate(new_url);       
  }

    const handleChange  =   (event, value) => {
        const new_url     =   location.pathname+"?page="+value+"&top="+parseInt(process.env.REACT_APP_PAGINATION);
        navigate(new_url)
    };

  return  <Container {...useFormData()} filter={handleFilters} download={"api/negociacionInternacional/export?key="+process.env.REACT_APP_DOWNLOAD} onKeyDown={onKeyDown}>
            <Submenu option={obtenerUltimoSegmento(location.pathname)}/>     
              
            {
                formData&&formData.data&&(<>
                        <Grid container spacing={1}>
                            <Map formData={formData.data} component={Item}/>
                        </Grid>
                        <Grid>
                            <Pagination handleChange={handleChange} formData={formData} navigate={navigate} location={location}/>                  
                        </Grid>
                    </>
                )
            }
          </Container>
}
export default Home
