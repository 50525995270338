import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Grid, Typography } from '@mui/material';
import SubMenuInterno from './SubMenuInterno';
import Item from './Items2';

const items   =   [
  {
    label:"Pendiente",
    fecha:"15mayo2023",
    active:true
  },
  {
    label:"Puerto de salida",
    fecha:"25mayo2023",
    active:true
  },
  {
    label:"Altamar",
    fecha:"05junio2023",
    active:false
  },
  {
    label:"Puerto de llegada",
    fecha:"15junio2023",
    active:false
  },  
  {
    label:"Arribado",
    fecha:"20junio2023",
    active:false
  },  
  {
    label:"Finalizado",
    fecha:"20julio2023",
    active:false
  },  
]

const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} save={"/"}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                   Transporte #43473
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Transporte #43473
                </Typography>                
              </Grid>              
            </Grid>

            
            <Grid container spacing={2} sx={{mt:{xs:5,md:1},mb:{xs:2,md:0}}}>
              <Grid item xs={12} md={6}>
                <SubMenuInterno md={12} xl={12} option={"estados"}/> 
              </Grid>
            </Grid> 
            
            {
              items.map((row,key)=>{
                return  <Grid container key={key}>
                          <Grid  xs={1} align="right" sx={{mr:2}}>
                            <Grid className={ row.active? ' bolita bolita-active ':' bolita  bolita-inactive ' }></Grid>
                            {items.length!==key+1&&(<Grid className='palito'></Grid>)}                            
                          </Grid>
                          <Grid  xs={2}>
                            {row.label}
                          </Grid>
                          <Grid  xs={"auto"}>
                            <b>{row.fecha}</b>
                          </Grid>
                        </Grid>
              })
            }     
            
            
            
          </Container>
}
export default Home
