import { useContext } from 'react';
import Input        from '../../../components/Input';
import Button       from '@mui/material/Button';
import { NavLink }  from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import useFormData  from '../../../hooks/useFormData';
import useAxios     from '../../../hooks/useAxios';
import StateContext from '../../../utils/ContextState';
import useAsyncStorage     from '../../../hooks/useAsyncStorage';
import { useNavigate, useLocation } from "react-router-dom";

import logo   from '../../../assets/png/logo.png';
import vehiculo   from '../../../assets/png/vehiculo.png';
import left   from '../../../assets/png/elementTopLeft.png';
import elementCenter   from '../../../assets/png/elementCenter.png';

const CenteredImage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Grid className='img-center' align="center">
        <img
          src={elementCenter}
          alt="Imagen responsiva"
          style={{ maxWidth: '50%', height: 'auto' }}
        />
      </Grid>      
    </div>
  );
};

const WebLogin=()=>{
    const userStorage             =   useAsyncStorage("user");
    const context                 =   useContext(StateContext);
    const { formData, onChange }  =   useFormData();
    const axios                   =   useAxios();
    const navigate                =   useNavigate();
    const location                =   useLocation();
    

    const onSubmit=(e)=>{
        e.preventDefault()
        axios.postData(formData,"api/auth/email").then((response)=>{
          if (  response  &&
                response.token_type &&
                response.access_token
              ) {
    
            const setStorageAndState  =  {  
                                            access_token:response.access_token,                                            
                                          };
            /*vamos a setear el estado en App.js para que esté disponible global*/
            context.setUser(setStorageAndState)
            /*Guardo en el storage también*/
            userStorage.setData(setStorageAndState).then(resp=>{
              context.setOpenMessage("Inicio de sesión, será redirigido a tu cuenta...")
              if (location.search==='?redirect=true' && window.history.length===0) {
                return navigate("/");
              }else {
                return navigate("/dashboard");
              }
            })
          }
        })
    }

    return  <>  

              <Grid sx={{position:'absolute'}}>
                <img src={left} alt=""/>
              </Grid>
              <Grid container className='vh100'>
                <Grid item xs={12} md={6} className='flex'>
                  <Grid className='flex-center' align="center">
                    <Grid sx={{mb:2}}><img src={logo} alt=""/></Grid>
                    <Grid>

                      <form  onSubmit={onSubmit}>
                        <Grid align="left" sx={{mb:2,mt:2}}>
                            <Typography sx={{fontSize:16}}>Bienvenido</Typography>
                            <Typography sx={{fontSize:30,fontWeight:"bold"}}>Ingrese a su cuenta</Typography>
                        </Grid>
                        <Input sx={{mb:2}} required onChange={onChange} size="small" fullWidth name="email" type="email" label="Correo electrónico"/>
                        <Input sx={{mb:2}} required onChange={onChange} size="small" fullWidth name="password" type="password" label="Password"/>
                        <Grid container>
                            <Grid item xs={6}>  
                                <Typography sx={{fontSize:11}} align='left'>Recordarme</Typography>
                            </Grid>
                            <Grid item xs={6} align="right" sx={{mb:2}}>
                            <NavLink to="/auth/recover">
                                <Typography sx={{fontSize:11}}>
                                    Recuperar Contraseña
                                </Typography>
                            </NavLink>
                            </Grid>
                        </Grid>
                        <Button style={{ display: 'block', width: '100%' }} 
                                size="large" 
                                variant='contained' 
                                type="submit"
                        >
                            Iniciar Sesión
                        </Button>
                      </form>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item 
                      xs={12} 
                      md={6} 
                      sx={  
                            {
                              display:{
                                        xs:"none",
                                        md:"block"
                                      },
                              background: "url('" + vehiculo + "') bottom / cover no-repeat",
                              width: "100%",
                            }
                          }
                >
                  <Grid sx={{position:"absolute",top:0,left:0,width:"100%",zIndex:1,pointerEvents: "none",}}>
                    <CenteredImage />
                  </Grid>    
                </Grid>
              </Grid>
            </>
}
export default WebLogin;