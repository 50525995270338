import  {   Grid, Paper } from '@mui/material';
import  { NavLink } from 'react-router-dom';
import image    from '../../../assets/jpg/proveedores.jpg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';


const Item=({row})=>{
     return  <Grid item xs={12} md={3}> 
                <Paper sx={{p:2}}>
                    <Grid container>
                        <Grid item xs={9}>
                        <Grid component={NavLink} to={"/dashboard/proveedores/"+row.id}>
                            {row.name}
                        </Grid>
                        <Grid sx={{mt:1}}>
                            <Grid container>
                            <Grid item xs={2}>
                                <IconButton color="green" size="small" variant="text" component={NavLink} to={row.phone} target="_blank">
                                    <WhatsAppIcon sx={{fontSize:22}} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton color="blue" size="small" variant="text" component={NavLink} to={row.phone} target="_blank">
                                    <PhoneForwardedIcon sx={{fontSize:22}}  />
                                </IconButton>                    
                            </Grid>
                            <Grid item xs={8} align="center">
                                <Button component={NavLink} to={"/dashboard/proveedores/"+row.id+"/cuentas"} size="small" variant="contained2">
                                    Cuenta Bancaria
                                </Button>                    
                            </Grid>
                            </Grid>                    
                        </Grid>
                        </Grid>
                        <Grid item xs={3} component={NavLink} to={"/dashboard/proveedores/"+row.id}>
                            <img className='img-fluid' src={image} alt="Programandoweb"/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
  
  }
export default Item  