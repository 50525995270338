import {Button, Grid, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
const Item=({row,nro})=>{
    return  <Grid item xs={12} md={3}> 
              <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      #46546545 {nro+1}
                    </Grid>
                    <Grid className='item-name'>
                      Fedex
                    </Grid>
                    <Grid className='item-date'>
                      Fecha de salida: 10/10/2023
                    </Grid>
                    <Grid className='item-date2'>
                      Fecha de llegada: 12/10/2023
                    </Grid>
                    <Grid className='item-price'>
                      Proveedor: Julio
                    </Grid>                  
                  </Grid>
                  <Grid item xs={4} >
                    <Grid className='flexBottom'>
                      <Button variant={(row.status==='Vencido')?'red':'green'} component={NavLink} to="/dashboard/transporte/0">
                        {row.status==='Vencido'?"No Pago":"Pago"}
                      </Button>  
                    </Grid>                  
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }
  
export default Item