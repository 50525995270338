import  image    from '../../../assets/png/image-not-found-scaled.png';
import  {   Grid, Paper } from '@mui/material';
import  { NavLink } from 'react-router-dom';

const Item=({row})=>{
     return  <Grid item xs={12} md={3}> 
              <Paper sx={{p:1}}>
                <NavLink to={row.id+"/preview"}>
                  <Grid container>
                    <Grid item xs={9}>
                      <Grid>
                        {row.name}
                      </Grid>
                      <Grid>
                        {row.brand}
                      </Grid>
                      {
                        row && row.subcategory&&row.subcategory.name&&(
                          <Grid>
                            {row.subcategory.name}
                          </Grid>
                        )
                      }                    
                      <Grid>
                        {row.weight}
                      </Grid>                  
                    </Grid>
                    <Grid item xs={3}>
                      <img className='img-fluid' src={row.photo||image} alt="Programandoweb"/>
                    </Grid>
                  </Grid>
                </NavLink>
              </Paper>
            </Grid>
  
  }
export default Item  