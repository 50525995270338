//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';
import PostAddIcon from '@mui/icons-material/PostAdd';


const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} save={"/"}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                    Suspensión 300
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Suspensión 300
                </Typography>                
              </Grid>              
            </Grid>
            <Grid container spacing={2} sx={{mb:4}}>
              <Grid item xs={12} sm={10} md={6} xl={4}>
                <Grid className='contentSubMenu'>
                  <Grid container>
                    <Grid item xs={6} align="center">
                      <Button variant='btnTagSelect' component={NavLink} to="/dashboard/usuarios/0">
                        Datos Usuarios
                      </Button>
                    </Grid>
                    <Grid item xs={6} align="center">
                      <Button variant='btnTag' component={NavLink} to="/dashboard/usuarios/0/permisos">
                        Permisos
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Nombre"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Cargo"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Email"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Celular"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="País"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Ciudad"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Dirección"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Estado"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Contraseña"/>
              </Grid>  
            </Grid>  
          </Container>
}
export default Home
