import { useContext, useEffect, useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import Loading from '../../../components/Loading/LinearWithLabel';
import useAsyncStorage from '../../../hooks/useAsyncStorage';
//import { useNavigate } from "react-router-dom";
import {formatearMontoEnCOP} from '../../../utils/functions';
import StateContext from '../../../utils/ContextState';
import Filter from '../../../components/Form/EditProductosList';
import AddIcon from '@mui/icons-material/Add';


const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },  
]


let data    =   []
const timeLoading =   2000;
let inputs  =   {}
let init    =   false;

const Home=()=>{
  const context                   =   useContext(StateContext);
  const axios                     =   useAxios();
  let { id }                      =   useParams();
  const userStorage               =   useAsyncStorage("formProduct");
  const [loading,setLoading]                  =   useState(true);
  const [negociaciones,setNegociaciones]      =   useState([])
  const [negociacion,setNegociacion]          =   useState({})


  init=()=>{
    axios.getInit("negotiation",{},"include=products&filter=id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {      
        inputs=response.data[0]; 
        setNegociacion(inputs)        
      }     
      setTimeout(() => {
        setLoading(false)
      }, timeLoading);  
      
    })        
  }

  useEffect(()=>{
    

    axios.getInit("product",{},"include=products,subcategory.category&order_by=name",1000).then((response)=>{
      if(response&&response.data){
        let items = []
        setNegociaciones(response.data)        
      }      
      
    })
    if (id==="new") {
      data=[]
      setLoading(false)
      userStorage.removeData();  
      return;
    }  
    
    init();
    
  },[])

  const handleOpenModal=()=>{
    return context.setOpenDialog({
      component:<Filter negociacion={negociacion} init={init} axios={axios} context={context} id={id} negociaciones={negociaciones} />,
      maxWidth:"sm"
    })
  }

  const handleDelete=(row)=>{
    let _row  = []
    if (negociacion&&negociacion.products) {
      setLoading(true)
      negociacion.products.map((_row_)=>{
        if(row.id!==_row_.id){
          _row.push(_row_)
        }        
      }) 
      inputs.products  =   _row
      setNegociacion({...negociacion,products:_row});
      setTimeout(() => {
        setLoading(false)
      }, timeLoading);  
    }
    
    let send    = {};
    _row.map((row,key)=>{
      send = {...send,
        ["products."+key+".id"]:row.id,
        ["products."+key+".quantity"]:row.quantity,
        ["products."+key+".value"]:row.value
      }
    })

    send.dispatch_to        =   inputs.dispatch_to;
    send.negotiation_date   =   inputs.negotiation_date;
    send.provider_id        =   inputs.provider_id;

    axios.postData({...send,},"api/negotiation/update/"+id);

  }


  const Item=({row})=>{
    return  <Grid item xs={12} md={3}> 
              <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Grid><b>{row.name}</b></Grid>
                    <Grid>Unid. compradas: <b>{row.quantity}</b></Grid>
                    <Grid>Unid. transportadas: <b>{row.quantity}</b></Grid>
                    <Grid>Valor Unit: <b>{formatearMontoEnCOP(row.value)}</b></Grid>
                  </Grid>
                  <Grid item xs={2} >
                    <Grid >
                      <Button onClick={()=>handleDelete(row)}>
                        <DeleteIcon className='text-red'/>
                      </Button>  
                    </Grid>                  
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }



  return  <Container {...useFormData()} save={"/"}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                    Negociación Internacional #{inputs.id}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Negociación Internacional #{inputs.id}
                </Typography>                
              </Grid>              
            </Grid>
            <Grid container spacing={2} sx={{mb:4}}>
              <Grid item xs={12} sm={10} md={8}>
                <Grid className='contentSubMenu'>
                  <Grid container>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTag' component={NavLink} to={"/dashboard/negociacionInternacional/"+id}>
                        Negociación
                      </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTag' component={NavLink} to={"/dashboard/negociacionInternacional/"+id+"/formapago"}>
                        Forma de Pago
                      </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTagSelect' component={NavLink} to={"/dashboard/negociacionInternacional/"+id+"/productos"}>
                        Productos
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              loading&&(
                <Grid>
                  <Loading time={timeLoading} increment={timeLoading}/>
                </Grid>
              )
            }
            {
              !loading&&(
                <Grid container spacing={2}>
                  {inputs.products&&inputs.products.length>0&&(
                    inputs.products.map((row,key)=>{
                      return <Item key={key} row={row} nro={key} />
                    })
                  )}
                  {inputs.products&&inputs.products.length===0&&(
                    <Grid align="center">No hay Productos aún</Grid>                
                  )}  
                </Grid>
              )
            }
            <Grid className='btnAddNew'>
                <Button
                  style={{
                    padding: "16px",
                    fontSize: "18px",
                    color:"white",
                  }}
                  variant="btnAddNew"
                  onClick={handleOpenModal}
                >
                  <AddIcon sx={{fontSize:{md:30,xs:26}}}/>
                </Button>
            </Grid>
          </Container>
}
export default Home
