import { useContext } from 'react';
import StateContext from '../../../utils/ContextState';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/List';
import { Grid, Button } from '@mui/material';
import Item from './Items';
import Submenu from './SubMenu';
import Select from '../../../components/Select';


const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },  
]


const Filter=({context})=>{



  
  return  <Grid sx={{p:4}}>
            <Grid container spacing={3} sx={{mb:2}}>
              <Grid item xs={12} md={6}>
                <Select label="Estado Pago"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select label="Proveedor"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select label="Forma Pago"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select label="Vencimiento"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select label="Fecha inicio"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select label="Fecha inicio"/>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button variant='contained' fullWidth>
                  Aceptar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant='outlined' fullWidth onClick={()=>context.setOpenDialog(false)}>
                  Borrar Filtro
                </Button> 
              </Grid>
            </Grid>
          </Grid>
}



const Home=(props)=>{

  const context               =   useContext(StateContext);

  const handleFilters=()=>{
    return context.setOpenDialog({
              component:<Filter context={context}/>,
              maxWidth:"sm"
            })
  }

  return  <Container {...useFormData()} filter={handleFilters} save={"/"} skipAdd={true}>
            <Submenu option={""}/>
            <Grid container spacing={2}>
              {
                items.map((row,key)=>{
                  return <Item key={key} row={row} nro={key} />
                })
              }  
            </Grid>
          </Container>
}
export default Home
