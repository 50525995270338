import {Grid, Paper } from '@mui/material';

const Item=()=>{
    return  <Grid item xs={12} md={3}> 
              <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      TGD
                    </Grid>
                    <Grid className='item-date'>
                      Unidades 3
                    </Grid>
                    <Grid className='item-date2'>
                      <b>Peso Unitario 50 Lb</b> 
                    </Grid>
                    <Grid className='item-price'>
                      <b>Peso Total 150 Lb</b> 
                    </Grid>                  
                    <Grid className='item-price'>
                      <b>Valor unitario total USD $50</b> 
                    </Grid>                  
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }
  
export default Item