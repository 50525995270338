import { useState, useContext , useEffect} from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/List';
import { Button, Grid, Typography } from '@mui/material';
import StateContext from '../../../utils/ContextState';
import Select from '../../../components/Select'
import { useLocation, useNavigate } from 'react-router-dom';
import Submenu from './SubMenu';
import useAxios from '../../../hooks/useAxios';
//import Pagination from '../../../components/Pagination';
import Item from './Items';
import {groupItemsByInitialLetter} from '../../../utils/functions';


/*
function groupItemsByInitialLetter(response,keys) {
  const groupedItems = {};

  response.data.forEach((item) => {

    let initialLetter ;

    if(item.name){
      initialLetter = item.name.charAt(0).toUpperCase();
    }

    if(item[keys]){
      initialLetter = item[keys].charAt(0).toUpperCase();
    }
    
    if (!groupedItems[initialLetter]) {
      groupedItems[initialLetter] = [];
    }
    groupedItems[initialLetter].push(item);
  });

  // Obtener las letras iniciales como un array y ordenarlas
  const initialLetters = Object.keys(groupedItems).sort();

  // Crear un nuevo objeto para almacenar los elementos agrupados en orden alfabético
  const groupedItemsAlphabetical = {};

  // Recorrer el array ordenado y copiar los elementos
  initialLetters.forEach((letter) => {
    groupedItemsAlphabetical[letter] = groupedItems[letter];
  });


  return groupedItemsAlphabetical;
}*/



let filterUrl    =   {};

const Home=(props)=>{
  const navigate                =   useNavigate();
  const location                =   useLocation();
  const context                 =   useContext(StateContext);
  const {Map}                   =   context;
  const {getInit}               =   useAxios();
  const {formData,setFormData}  =   useFormData();

  useEffect(()=>{    
    let subcategory   =   ""
    if (filterUrl.filter) {
      subcategory   =   "include=subcategory&person_in_charge=name&filter="+filterUrl.filter;
    }else{
      subcategory   =   "include=city,vehicle&person_in_charge=name";
    }    
    if(location.search===''){
      subcategory   =   "include=city,vehicle&include=subcategory&order_by=person_in_charge&filter=type:Distribuidor";
    }
    
    getInit("client", {}, subcategory,100000).then((response) => {
      if (response) {
        const groupedItems = groupItemsByInitialLetter(response,"person_in_charge");
        setFormData({ groupedItems }); // Añade los elementos agrupados a formData
      }
    });

  },[location.search])

  /*
  const setUrl=()=>{
    let new_url = location.pathname+"?include=subcategory&filter=subcategory.id:"+filterUrl.subcategory
    return navigate(new_url);       
  }

  const clearUrl=()=>{
    filterUrl={}
    let new_url = location.pathname
    return navigate(new_url);      
  }
  */
 
 

  const handleFilters=()=>{
    return context.setOpenDialog({
              component:<Filter context={context}/>,
              maxWidth:"sm"
            })
  }

  const Filter=()=>{    
    
    const [subcategory,setSubCategory]          =   useState([]);

    const onChange=(name,value)=>{

      let return_=[]
      context.config.cities.map((row)=>{
        if (row.country_id===value.id) {
          return_.push(row)  
        }      
      }) 
      
      if (name==='category') {
        setSubCategory(return_)  
      }    
      filterUrl[name] = value.id
      
    }

    const setUrl=()=>{
      let new_url = location.pathname+"?include=subcategory&filter=city_id:"+filterUrl.city_id
      return navigate(new_url);       
    }

    const clearUrl=()=>{
      filterUrl={}
      let new_url = location.pathname
      return navigate(new_url);      
    }
   


    return  <Grid sx={{p:4}}>
              <Grid container spacing={3} sx={{mb:2}}>
                <Grid item xs={6}>
                  <Select label="País" name="category" data={context.config.countries} onChange={onChange}/>
                </Grid>
                <Grid item xs={6}>
                  <Select label="Ciudad" name="city_id" data={subcategory} onChange={onChange}/>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button variant='contained' fullWidth onClick={()=>{setUrl();context.setOpenDialog(false);}}>
                    Aceptar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant='outlined' fullWidth onClick={()=>{clearUrl();context.setOpenDialog(false);}}>
                    Borrar Filtro
                  </Button> 
                </Grid>
              </Grid>
            </Grid>
  }

  const onKeyDown=(e)=>{
    filterUrl={
      filter:'name/'+e.target.value
    }
    let new_url = location.pathname+"?include=subcategory&filter="+filterUrl.filter
    return navigate(new_url);       
  }

  return  <Container {...useFormData()} filter={handleFilters} download={"api/client/export?key="+process.env.REACT_APP_DOWNLOAD} onKeyDown={onKeyDown}>
            <Submenu option={""}/>
            {
              formData&&formData.groupedItems&&(
                Object.entries(formData.groupedItems).map((row,key)=>{
                  return  <Grid sx={{mb:3}} key={key}>
                            <Grid container spacing={0} >
                              <Grid item xs={12} className='separador-letras'>
                                <Typography sx={{pl:1,pt:0.5,pb:0.5,m:0,fontWeight:"bold"}}>
                                  {
                                    row[0]
                                  }
                                </Typography>
                              </Grid>
                            </Grid>  
                            <Grid container spacing={1} sx={{mb:3}}>
                              {
                                row[1].map((row2,key2)=>{
                                  return <Item key={key2} row={row2} />
                                })
                              }     
                            </Grid>                   
                          </Grid>
                })
              )
            }           
            
            
          </Container>
}
export default Home
