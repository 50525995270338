import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography } from '@mui/material';
import Search        from '../../../components/Input/Search';
import Item from './Items';
import SubMenuInterno from './SubMenuInterno';

const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Llegó"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Llegó"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Llegó"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Llegó"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Llegó"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },  
]

const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} save={"/"}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                   Transporte #43473
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Transporte #43473
                </Typography>                
              </Grid>              
            </Grid>
            <Grid container spacing={2} sx={{mt:{xs:5,md:1},mb:{xs:2,md:0}}}>
              <Grid item xs={12} md={6}>
                <SubMenuInterno md={12} xl={12} option={"productos"}/>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button fullWidth variant='contained'>Mapa de Cargue</Button>
              </Grid>
              <Grid item xs={12} md={"auto"} >
                <Button fullWidth variant='contained'>Productos que llegaron</Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent={"center"}>
               <Grid item xs={12} md={3}>
                  <Search placeholder="Search"/>
               </Grid>
            </Grid> 
            <Grid container spacing={1} sx={{mb:3,mt:2}}>
              {
                items.map((row,key)=>{
                  return <Item key={key} row={row} />
                })
              }     
            </Grid>
          </Container>
}
export default Home
