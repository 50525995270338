import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SmartToyIcon from '@mui/icons-material/SmartToy';


const App=()=>{
    return  <Grid  sx={{height:"89vh"}}>
              <Box sx={{
                            position: 'absolute',
                            top: {md:'40%',xs:'20%'},
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 500,
                            textAlign:"center",

                        }}>
                <SmartToyIcon sx={{fontSize:{xs:40,md:220}}}  color="primary"/>
                <Typography variant="h2">
                  Backend 403
                </Typography>
                <Typography>
                  <Grid>
                    <h6>
                      <b>Tus privilegios son limitados</b>
                      <br/>
                        No puedes estar acá, se reportó al departamento de seguridad.
                      <br/>
                    </h6>
                  </Grid>
                  <h4>Si continúas podrías ser inhabilitado</h4>
                </Typography>
              </Box>
            </Grid>
}
export default App
