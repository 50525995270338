import { useContext } from 'react';
import Input        from '../../../components/Input';
import Button       from '@mui/material/Button';
//import { NavLink }  from 'react-router-dom';
import { Grid, Typography,Checkbox } from '@mui/material';
import useFormData  from '../../../hooks/useFormData';
import useAxios     from '../../../hooks/useAxios';
import StateContext from '../../../utils/ContextState';
import useAsyncStorage     from '../../../hooks/useAsyncStorage';
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink }  from 'react-router-dom';
import logo   from '../../../assets/png/logo3.png';
import right2 from '../../../assets/png/elementBottonRight2.png'
import left2 from '../../../assets/png/elementTopLeft2.png'


const WebLogin=()=>{
    const userStorage             =   useAsyncStorage("user");
    const context                 =   useContext(StateContext);
    const { formData, onChange }  =   useFormData();
    const axios                   =   useAxios();
    const navigate                =   useNavigate();
    const location                =   useLocation();
    

    const onSubmit=(e)=>{
      e.preventDefault()
      axios.postData(formData,"api/auth/email").then((response)=>{
        if (  response  &&
              response.token_type &&
              response.access_token
            ) {
  
          const setStorageAndState  =  {  
                                          access_token:response.access_token,                                            
                                        };
          /*vamos a setear el estado en App.js para que esté disponible global*/
          context.setUser(setStorageAndState)
          /*Guardo en el storage también*/
          userStorage.setData(setStorageAndState).then(resp=>{
            context.setOpenMessage("Inicio de sesión, será redirigido a tu cuenta...")
            if (location.search==='?redirect=true' && window.history.length===0) {
              return navigate("/");
            }else {
              return navigate("/dashboard");
            }
          })
        }
      })
    }

    return  <>  

              <Grid className="left">
                <img alt="" src={left2}/>
              </Grid>
              <Grid className="right">
                <img alt="" src={right2}/>
              </Grid>
              <Grid container className='vh100'>
                <Grid item xs={12} md={6} className='flex'>
                  <Grid className='flex-center' align="center">
                    <Grid sx={{mb:10}}><img src={logo} alt=""/></Grid>
                    <Grid>

                      <form  onSubmit={onSubmit}>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={10}>
                              <Input sx={{mb:2}} required onChange={onChange} size="small" fullWidth name="email" type="email" label="Correo electrónico"/>
                              <Input sx={{mb:2}} required onChange={onChange} size="small" fullWidth name="password" type="password" label="Password"/>
                            </Grid>
                            <Grid item xs={11} sx={{maxWidth:"80%",mb:2}}>  
                              <Button style={{ display: 'block', width: '100%', marginTop:"10px" }} 
                                      component={NavLink}
                                      to="/auth/recover/movil"
                              >
                                  ¿Olvidaste la contraseña?
                              </Button>
                            </Grid>
                            <Grid item xs={11} sx={{maxWidth:"80%"}}>  
                              <Button style={{ display: 'block', width: '100%' }} 
                                size="large" 
                                variant='contained' 
                                type="submit"
                              >
                                Iniciar Sesión
                              </Button>
                            </Grid>
                        </Grid>
                        
                      </form>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
}
export default WebLogin;