import { useState } from 'react';
import {Button, Grid, Paper } from '@mui/material';
import Input        from '../Input';
import Counter from '../Counter';


let inputs=[]
let inputs_values=[]

const Filter=({axios,context,negociaciones,id,init,negociacion})=>{

    const [searchText, setSearchText]               =   useState("");

    const onChangeSearch = (e) => {
      setSearchText(e.target.value);
    };
    

    const handleIncrement=(key,quanty)=>{

      const item    =   negociaciones[key];
      const result  =   inputs.find(search=>search.id===item.id);
      
      if (!result) {
        inputs.push({
          key:key,
          id:item.id,
          quantity:quanty,
          value:inputs_values[key]||0          
        })
      }else{
        result.quantity   =   quanty;
      }

    }

    const onChange = (key,e)=>{  
      const item      =   negociaciones[key];
      const result    =   inputs.find(search=>search.key===key);
      if (!result) {
        inputs.push({
          key:key,
          id:item.id,
          quantity:0,
          value:parseInt(e.target.value)          
        })
      }else{
        result.value  =   parseInt(e.target.value);
      }            

    }

    const handleSubmit=()=>{
      let send    = {};
      let counter = 0;
      
      if (inputs&&inputs.length>0) {
        inputs.map((row,key)=>{
          send = {...send,
            ["products."+counter+".id"]:row.id,
            ["products."+counter+".quantity"]:row.quantity,
            ["products."+counter+".value"]:row.value
          }
          counter++
        })
      }

      if (negociacion&&negociacion.products&&negociacion.products.length>0) {
        negociacion.products.map((row,key)=>{
          send = {...send,
            ["products."+counter+".id"]:row.id,
            ["products."+counter+".quantity"]:row.quantity,
            ["products."+counter+".value"]:row.value
          }
          counter++
        })
      }

      send.dispatch_to        =   negociacion.dispatch_to;
      send.negotiation_date   =   negociacion.negotiation_date;
      send.provider_id        =   negociacion.provider_id;
      
      
      
      axios.postData({
              ...send,                
            },"api/negotiation/update/"+id).then((response)=>{
        if (response&&response.data) {
          if (init) {
            init();
          }          
        }
        context.setOpenDialog(false)
      })
      
    }

    console.log(negociacion)                
    
    const Item=({row,keys})=>{
        return  <Paper sx={{px:2,py:1,mb:1}}>
                    <Grid><b>{row.name}</b></Grid>
                    <Grid>Categoría: <b>{row.subcategory&&row.subcategory.category&&row.subcategory.category.name}</b></Grid>
                    <Grid>Subcategoría: <b>{row.subcategory&&row.subcategory.name}</b></Grid>
                    <Grid>Peso Unitario: <b>{row.weight}</b></Grid>
                    <Grid container spacing={1} justifyContent={"end"}>
                      <Grid item xs={4}>
                        <Counter keys={keys} event={handleIncrement} defaultValue={row.quantity||0}/>
                      </Grid>
                      <Grid item xs={6}>
                        <Input  onChange={(e)=>onChange(keys,e)} 
                                defaultValue={row.value} 
                                size="small" 
                                name="value"
                                type="number" 
                                label="Valor unitario"/>
                      </Grid>                      
                    </Grid>
                </Paper>
    }

    const filteredNegociaciones = negociaciones.filter((row) =>
      {
        return row.name.toLowerCase().includes(searchText.toLowerCase())                  
      }
    );

    return  <Grid sx={{p:4, backgroundColor:"#e5cefe"}}>
              <Grid container sx={{mb:1}}>
                <Grid item xs={12} > 
                  <Paper sx={{px:1,py:1,}}>
                    <Input  sx={{mb:2}} 
                            fullWidth={true} 
                            onChange={onChangeSearch} 
                            size="small" 
                            name="search" 
                            label="Buscar Producto... "/>
                  </Paper>          
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {
                  negociaciones&&negociaciones.length>0&&(
                    <Grid item xs={12} sx={{height:"70vh", overflowY:"scroll"}}> 
                        {
                          filteredNegociaciones.map((row, key) => {
                            return <Item row={row} keys={key} key={key} />;
                          })                            
                        }
                    </Grid>
                  )
                }
                
                <Grid item xs={6}>
                  <Button variant='contained' fullWidth onClick={()=>context.setOpenDialog(false)}>
                    Cerrar
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Button variant='outlined' fullWidth onClick={handleSubmit}>
                    Guardar
                  </Button>
                </Grid>

              </Grid>
            </Grid>
  }

  export default Filter