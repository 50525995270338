
export const main   =   require("../galeria/01.jpg");

const items         =   [
    require("../galeria/01.jpg"),
    require("../galeria/02.jpg"),
    require("../galeria/03.jpg"),
    require("../galeria/04.jpg"),
]

export default items