import { createTheme } from '@mui/material/styles';
import { red, grey, purple, green, blue } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#492348",
    },
    secondary: {
      main: grey[500],
    },
    primary2: {
      main: "#38099d",
    },
    primary3: {
      main: "#8b5af1",
    },
    red: {
      main: "#FF2F2F",
    },
    green: {
      main: green[300],
    },
    blue: {
      main: blue[300],
    },
    error: {
      main: purple[500],
    },
    white: {
      main: "white",
    },
    black: {
      main: "black",
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Prevent uppercase transformation
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: '50px',
            backgroundColor: "#492348", // Background color set to primary main color
            color: "#fff", // Text color set to white
            '&:hover': {
              color:"#fff",
              backgroundColor: "#38099d", // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: '50px',            
          },
        },
        {
          props: { variant: 'contained2' },
          style: {
            borderRadius: '50px',
            paddingTop:5,            
            fontSize:11,
            textTransform:"capitalize",
            backgroundColor: "#492348", // Background color set to primary main color
            color: "#fff", // Text color set to white
            '&:hover': {
              color:"#fff",
              backgroundColor: "#38099d", // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'round' },
          style: {
            borderRadius: '50px',
            backgroundColor: "#492348", // Background color set to primary main color
            color: "#fff", // Text color set to white
            '&:hover': {
              backgroundColor: "#38099d", // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'round2' },
          style: {
            borderRadius: '40px',
            minWidth:40,
            width:40,
            height:40,
            padding:0,
            backgroundColor: "#f2f2f2", // Background color set to primary main color
            color: "#000", // Text color set to white
            '&:hover': {
              backgroundColor: "#d2d2d2", // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#000", 
              backgroundColor: "#f2f2f2", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'transparent' },
          style: {
            padding:0,
            backgroundColor: "transparent", // Background color set to primary main color
            color: "#fff", // Text color set to white
            '&:hover': {
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'btnAddNew' },
          style: {
            borderRadius: '50px',
            backgroundColor: "#38099D",
            color: "#fff", 
            '&:hover': {
              color: "#fff", 
              backgroundColor: "#58099D", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'btnSave' },
          style: {
            borderRadius: '50px',
            backgroundColor: "#2FCA5A",
            color: "#fff", 
            '&:hover': {
              color: "#fff", 
              backgroundColor: "#3FCA5A", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'btnTagSelect' },
          style: {
            fontSize:10,
            textTransform:"capitalize",
            paddingLeft:7,
            paddingRight:7,
            paddingTop:2,
            paddingBottom:2,
            borderRadius: '50px',
            backgroundColor: "#fff",
            color: "#000", 
            '&:hover': {
              color: "#000", 
              backgroundColor: "#f2f2f2", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'btnTag' },
          style: {
            fontSize:10,
            textTransform:"capitalize",
            paddingLeft:7,
            paddingRight:7,
            paddingTop:2,
            paddingBottom:2,
            borderRadius: '50px',
            backgroundColor: "transparent",
            color: "#fff", 
            '&:hover': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'white' },
          style: {
            borderRadius: '50px',
            backgroundColor: "transparent",
            color: "#fff", 
            '&:hover': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'red' },
          style: {
            fontSize:10,
            textTransform:"capitalize",
            paddingLeft:7,
            paddingRight:7,
            paddingTop:2,
            paddingBottom:2,
            borderRadius: '50px',
            backgroundColor: red[400],
            color: "#fff", 
            '&:hover': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'green' },
          style: {
            fontSize:10,
            textTransform:"capitalize",
            paddingLeft:7,
            paddingRight:7,
            paddingTop:2,
            paddingBottom:2,
            borderRadius: '50px',
            backgroundColor: green[400],
            color: "#fff", 
            '&:hover': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            fontSize:10,
            textTransform:"capitalize",
            paddingLeft:7,
            paddingRight:7,
            paddingTop:2,
            paddingBottom:2,
            borderRadius: '50px',
            backgroundColor: purple[800],
            color: "#fff", 
            '&:hover': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#fff", 
              backgroundColor: "transparent", // Background color set to primary2 main color on hover
            },
          },
        },
        {
          props: { variant: 'btnUpload' },
          style: {
            fontSize:10,
            textTransform:"capitalize",
            paddingLeft:10,
            paddingRight:10,
            paddingTop:4,
            paddingBottom:4,
            borderRadius: '50px',
            backgroundColor: purple[900],
            color: "#fff", 
            '&:hover': {
              color: "#fff", 
              backgroundColor: purple[700], // Background color set to primary2 main color on hover
            },
            '&:visited': {
              color: "#fff", 
              backgroundColor: purple[700], // Background color set to primary2 main color on hover
            },
          },
        },
      ],
    },
    MuiGrid: {
      variants: [
        {
          cursor: "pointer",
          props: { variant: 'link' },
          style: {
            color: purple[400],
          },
        },
        {
          props: { variant: 'counter' },
          style: {
            display:"flex",
            fontSize:16,
            textTransform:"capitalize",
            paddingTop:6,
            paddingBottom:0,
            paddingLeft:8,
            paddingRight:8,
            borderRadius: '50px',
            border: '1px solid purple',
            color: purple[900], 
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'cardHome' },
          style: {
            border: '2px solid rgba(0,0,0,0.1)', // Borde tenue
            borderRadius: '4px', // Bordes redondeados (opcional)
          },
        },
      ],
    },
    MuiToolbar: {
      variants: [
        {
          props: { variant: 'main' },
          style: {
            color:"#000",
            minHeight: 60,
            background:"#fff",
            boxShadow:"none",
            border:0,
            padding:10
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h5' },
          style: {
            fontWeight:"bold"
          },
        },
      ],
    },
  },
});
