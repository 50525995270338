import  React from  'react';
import  Grid  from  '@mui/material/Grid';
import  Paper  from  '@mui/material/Paper';
import  './dashboard.css';
import Input        from '../components/Input/IconLeftInput';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {NavLink} from 'react-router-dom'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import logo   from '../assets/png/logo.png';
import left2 from '../assets/png/elementTopLeft2.png'
import Profile from '../components/AvatarProfile/Profile';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation , useNavigate} from 'react-router-dom';

const BACKEND = window.location.hostname === 'localhost' ? process.env.REACT_APP_BACKEND : process.env.REACT_APP_BACKENDREMOTE;

const   Container   =     React.forwardRef((props, ref) => {

    const [open,setOpen]  =   React.useState(false)
    const location        =   useLocation();
    const navigate        =   useNavigate();

    React.useEffect(()=>{
      if (location.search==='') {
        setOpen(false)
      }else{
        setOpen(true)
      }
    },[location.search])
    
    const onKeyDown=(e)=>{
      props.onKeyDown(e)
    }

    return  <Grid ref={ref} className="dashboard-content">
              <Grid >
                
                <Grid className="left" sx={{zIndex:1}}>
                  <img alt="" src={left2}/>
                </Grid>
                <Grid className="left">
                  <Profile/>
                </Grid>
                <Grid sx={{mb:{md:6,xs:0},mt:4}}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={9} sx={{display:{xs:"block",md:"none"},mt:-8,zIndex:5}}>
                      <Grid sx={{textAlign:"center"}}>
                        <img src={logo} alt="programandoWeb" width={40}/>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={9} sx={{display:{xs:"flex",md:"none"}}}>
                      <Grid sx={{mr:2,position:"relative", width:"86%",}}>
                        <Input  className="bg-white" 
                                size={"small"}
                                sx={{mb:2,background:"white"}} 
                                fullWidth={true} 
                                icon={<SearchIcon/>}
                                onChange={props.onChange} name="email" label="Buscar..."/>
                      </Grid>
                      {
                        props.download&&(
                          <Button variant="round2" sx={{mr:1}} component={NavLink} to={BACKEND+props.download}>
                            <FileDownloadIcon/>                       
                          </Button>
                        )
                      }
                      <Button variant="round2" onClick={props.filter}>
                        <FilterAltOutlinedIcon/>                      
                      </Button>
                    </Grid>
                  </Grid>  
                  <Grid container spacing={3} sx={{mt:{xs:-1,md:2}}}>
                    <Grid item xs={4} md={7} sx={{display:{xs:"none",md:"block"},mr:4}}>
                      <Input  className="bg-white" sx={{mb:2,background:"white",}} 
                              onChange={props.onChange} 
                              size="small" 
                              icon={<SearchIcon/>}
                              fullWidth name="email" type="email" label="Buscar..."
                              onKeyDown={onKeyDown}
                      />
                    </Grid>
                    <Grid item xs={1} >
                      
                    </Grid>
                      {
                        props.download&&(
                          <Grid item xs="auto" sx={{display:{xs:"none",md:"block"}}}>
                            <Button variant='contained' component={NavLink} to={BACKEND+props.download} target="_blank">
                              Descargar CSV
                            </Button>
                          </Grid>                          
                        )
                      }
                    {
                      !open&&(
                        <Grid item xs="auto" sx={{display:{xs:"none",md:"block"}}}>
                          <Button variant='contained' color="primary3" onClick={props.filter} >
                            <Grid sx={{color:"white"}}>
                              Filtros
                            </Grid>
                          </Button>
                        </Grid>
                      )
                    } 
                    {
                      open&&(
                        <Grid item xs="auto" sx={{display:{xs:"none",md:"block"}}}>
                          <Button variant='contained' color="primary" onClick={()=>{navigate(location.pathname)}} >
                            <Grid sx={{color:"white"}}>
                              Borrar Filtro
                            </Grid>
                          </Button>
                        </Grid>
                      )
                    }                    
                  </Grid>  
                </Grid>
                <Grid variant="outlined" className="paper list-paper">
                  {props.children}
                </Grid>
              </Grid>
              
              <Grid sx={{bottom:70,left:0,position:"fixed", width:"100%", display:{xs:"block",md:"none"}}}>

                <Grid sx={{ padding:2, height:36, display:{xs:"block",md:"none"}}} align="center">
                  <Paper sx={{padding:2,mt:2, borderRadius:20, position:"relative"}} >
                    <NavLink to="/dashboard" className={"contentBtnRound"}>
                      <Grid className={"btnRound"}>
                        <HomeIcon/>
                      </Grid>                      
                    </NavLink>
                    {
                      !props.skipAdd&&(
                        <Button
                          sx={{
                            position:"absolute",
                            top:-70,
                            right:4,
                            padding:2,
                            fontSize:18,
                            color:"white"
                          }}
                          variant="btnAddNew"
                          component={NavLink}
                          className='textWhite'
                          to="new"
                        >
                          <AddIcon sx={{ fontSize:26 }}/>
                        </Button>
                      )
                    }                    
                  </Paper>
                </Grid>
                

              </Grid>
              <Grid sx={{display:{xs:"none",md:"block"}}}>                
                {
                  !props.skipAdd&&(
                    <Grid className='btnAddNew'>
                      <Button
                        style={{
                          padding: "16px",
                          fontSize: "18px",
                          color:"white",
                        }}
                        variant="btnAddNew"
                        component={NavLink}
                        to="new"
                      >
                        <AddIcon sx={{fontSize:{md:30,xs:26}}}/>
                      </Button>
                    </Grid>
                  )
                }                
              </Grid>  

                
            </Grid>
});


export default Container
