import { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import {main} from '../../../assets/jpg/galeria'
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import {limitarCadena} from '../../../utils/functions';


const Home=()=>{
  let { id }            =   useParams();  
  const { getInit }     =   useAxios();
  const [data,setData]  =   useState({})
  const userStorage                   =   useAsyncStorage("formProduct");


  useEffect(()=>{
    getInit("product",{},"include=subcategory.category,&filter=id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {
        const data_ = {...response.data[0]}
        data_.description=limitarCadena(data_.description,80);
        data_.specification=limitarCadena(data_.specification,80);
        userStorage.setData(data_)
        setData(data_)        
      }      
    })
  },[])
  
  return  <Container {...useFormData()} label="Editar" component={NavLink} to={"/dashboard/productos/"+id}>
            <Grid container spacing={2} justifyContent={"left"} sx={{mb:4,display:{xs:"none",md:"flex"}}}>
              <Grid item xs={12} md={4} align={"center"} >
                <Avatar alt="ProgramandoWeb" src={data.photo||main} sx={{width:200,height:200}}/>
              </Grid>
              <Grid item xs={12} md={1} >
                
              </Grid>
              <Grid item xs={12} md={4} align={"center"}>
                <Card>
                  <CardContent align="left">
                    <Grid>
                      Nombre : <b>{data.name}</b>
                    </Grid>
                    <Grid>
                        Categoría : <b>{data.subcategory&&data.subcategory.category.name}</b>
                    </Grid>
                    {
                      
                      <Grid>
                        Subcategoría : <b>{data.subcategory&&data.subcategory.name}</b>
                      </Grid>  
                      
                    }                    
                    <Grid>
                      Descripción  : <b>{data.description}</b>
                    </Grid>
                    <Grid>
                      Marca : <b>{data.brand}</b>
                    </Grid>
                    <Grid>
                      Serial : <b>{data.serial}</b>
                    </Grid>
                    <Grid>
                      Especificación : <b>{data.specification}</b>
                    </Grid>
                    <Grid>
                      Peso en Lb. : <b>{data.weight}</b>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{mb:4}} justifyContent={"left"}>
              <Grid item xs={12} md={4} align={"center"} sx={{mt:{md:0,xs:8}}}>
                
                  <Grid  className='borderBottom'>
                    <Typography component={"h3"}>
                      MOTOR V6
                    </Typography>
                  </Grid>

                  <Grid sx={{mt:2}}>
                    <Button variant='contained'>
                        Motor
                    </Button>
                  </Grid>
                
              </Grid>
              <Grid item xs={12} sx={{display:{xs:"block",md:"none"}}}>
                <Grid container spacing={2} sx={{mb:4}} justifyContent={"left"} >
                  <Grid item xs={12} md={4} align={"center"}>
                    <Card>
                      <CardContent align="left">
                        <Grid>
                          Nombre : <b>{data.name}</b>
                        </Grid>
                        <Grid>
                          Categoría : <b>{data.subcategory&&data.subcategory.name}</b>
                        </Grid>
                        {
                          /*
                            <Grid>
                              Subcategoría
                            </Grid>  
                          */
                        }                    
                        <Grid>
                          Descripción  : <b>{data.description}</b>
                        </Grid>
                        <Grid>
                          Marca : <b>{data.brand}</b>
                        </Grid>
                        <Grid>
                          Serial : <b>{data.serial}</b>
                        </Grid>
                        <Grid>
                          Especificación : <b>{data.specification}</b>
                        </Grid>
                        <Grid>
                          Peso en Lb. : <b>{data.weight}</b>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={1} >
                
              </Grid>
              <Grid item xs={12} md={4} align={"center"}>
                <Typography align='left' sx={{mb:2,mt:{xs:1,md:6}}}>
                  Fotos complementarias
                </Typography>
                <Grid container spacing={2}>
                  {
                    data&&data.complementary_photos&&data.complementary_photos.length>0&&(
                      data.complementary_photos.map((row,key)=>{
                        return  <Grid item xs={12} md={3} key={key}>
                                  <img className='img-fluid' src={row.url} alt="Programandoweb" />
                                </Grid>  
                      })
                    )
                  }                  
                </Grid> 
              </Grid>
            </Grid>
          </Container>
}
export default Home
