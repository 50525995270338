import {Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
const SubMenu=({option,md,xl})=>{
    return <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={md||6} xl={xl||4}>
                    <Grid className='contentSubMenu'>
                        <Grid container>
                            <Grid item xs={3} align="center">
                                <Button variant={option===''?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/importacion/0">
                                    Importación
                                </Button>
                            </Grid>
                            <Grid item xs={3} align="center">
                                <Button variant={option==='productos'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/importacion/0/productos">
                                    Productos
                                </Button>
                            </Grid>
                            <Grid item xs={3} align="center">
                                <Button variant={option==='gastos'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/importacion/0/gastos">
                                    Gastos
                                </Button>
                            </Grid>
                            <Grid item xs={3} align="center">
                                <Button variant={option==='estados'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/importacion/0/estados">
                                    Estados
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
}
export default SubMenu;