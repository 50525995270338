import  React from  'react';
import  Grid  from  '@mui/material/Grid';
import { Avatar, Button, Paper } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
          NavLink
        } from 'react-router-dom';

import circleLeft from '../assets/png/circleLeft.png'
import circleRight from '../assets/png/circleRight.png'
import car from '../assets/png/car.png'
import AddIcon from '@mui/icons-material/Add';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const   Container       =   React.forwardRef((props, ref) => {
    const isMobile = useMediaQuery('(max-width:768px)');
    
    return  <Grid ref={ref} className="dashboard-content" sx={{pt:5}}>
              <Grid  className='header-movile' sx={{display:{xs:"block", md:"none"}}}>
                <Grid className='position-relative'>
                    <img src={circleLeft} style={{position:"absolute", top:60, left:0}} alt="" />
                    <img style={{position:"absolute", top:0, right:0}} src={circleRight} alt=""/>
                    <Grid container>
                      <Grid item xs={6}>
                        <Button component={NavLink} to="../" variant='white' sx={{mt:2}}>
                          <KeyboardArrowLeftIcon/> Volver
                        </Button>
                      </Grid>
                      {!props.skipSave&&props.onClickSubmit&&(
                        <Grid item xs={6} align="right">
                          <Button variant='white' sx={{mt:2, mr:2}} type="submit">
                            Guardar
                          </Button>
                        </Grid>
                      )}                      
                    </Grid>
                </Grid>                
              </Grid>
              
              <Grid sx={{pointerEvents:{md:"none"},display:{xs:"block", md:"none"}}} className='body-movile'>
                <Grid className='content-avatar-movile'>
                  <Grid className='avatar-movile' align="center">
                    <Avatar src={car} sx={{width:150,height:150}}></Avatar>
                  </Grid>                  
                </Grid>
                <Grid className='content-movile'>
                  {isMobile?props.children:false}              
                </Grid>
                <Grid >                
                  {
                    props.add&&(
                      <Grid className='btnAddNew'>
                        <Button
                          style={{
                            padding: "16px",
                            fontSize: "18px",
                            color:"white",
                          }}
                          variant="btnAddNew"
                          component={props.add.component}
                          to={props.add.to}
                        >
                          <AddIcon sx={{fontSize:{md:30,xs:26}}}/>
                        </Button>
                      </Grid>
                    )
                  }
                </Grid> 
              </Grid>
              
              
              <Paper sx={{p:4,display:{xs:"none",md:"block"}}}>
                <Grid container>
                  <Grid item xs={6}>
                    <Button component={NavLink} to={props.back||".."}>
                      <KeyboardArrowLeftIcon/> Volver
                    </Button>
                  </Grid>
                  { !props.skipSave&&(

                    <Grid item xs={6} align="right">
                      { 
                        props.to && props.component&&(
                          <Button variant='btnSave' component={props.component} to={props.to} className="text-white">
                            {props.label||"Guardar"}
                          </Button>
                        )
                      }
                      { 
                        !props.to&&props.onClickSubmit&&(
                          <Button variant='btnSave' type="submit">
                            {props.label||"Guardar"}
                          </Button>
                        )
                      }
                    </Grid>                      
                    )
                  }
                  
                </Grid>
                <Grid sx={{p:2}}>
                  {!isMobile?props.children:false}                 
                </Grid>  
                <Grid >                
                  {
                    props.add&&(
                      <Grid className='btnAddNew'>
                        {props.add.component&&(
                          <Button
                            style={{
                              padding: "16px",
                              fontSize: "18px",
                              color:"white",
                            }}
                            variant="btnAddNew"
                            component={props.add.component}
                            to={props.add.to}
                          >
                            <AddIcon sx={{fontSize:{md:30,xs:26}}}/>
                          </Button>
                        )}

                        {props.add.event&&(
                          <Button
                            style={{
                              padding: "16px",
                              fontSize: "18px",
                              color:"white",
                            }}
                            variant="btnAddNew"
                            onClick={props.add.event}                            
                          >
                            <AddIcon sx={{fontSize:{md:30,xs:26}}}/>
                          </Button>
                        )}
                        
                      </Grid>
                    )
                  }
                </Grid>             
              </Paper>
              
            </Grid>
});


export default Container
