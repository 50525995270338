import {Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
const SubMenu=({option})=>{
    console.log(option)
    return <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={8}>
                <Grid className='contentSubMenu'>
                    <Grid container>
                    <Grid item xs={4} align="center">
                        <Button variant={option===''?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/centroAcopio/remanufactura/0/mapa/0">
                            Piso 
                        </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='medio'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/centroAcopio/remanufactura/0/mapa/0/medio">
                            Medio
                        </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='arriba'?'btnTagSelect':'btnTag'} component={NavLink} to="/dashboard/centroAcopio/remanufactura/0/mapa/0/arriba">
                            Arriba
                        </Button>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
}
export default SubMenu;