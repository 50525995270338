import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LoopIcon from '@mui/icons-material/Loop';
import useAxios     from '../../hooks/useAxios';



export default function CustomizedInputBase({customAdd, getInit, upload, download, name, label, defaultValue, variant, size, onChange, placeholder, fullWidth, type, required, sx , add, handleEventSearch }) {
  const axios                       =   useAxios();
 
  const [active,setActive]          =   React.useState(false)
  const [inputValue, setInputValue] =   React.useState(defaultValue || ''); // Estado para el valor del input

  const handleEvent=()=>{
    if (active) {
      handleClear()
    }
    handleEventSearch(active?false:true)
    setActive(active?false:true)
  }

  const handleClear = () => {
    document.getElementById("search").value="";
    setInputValue(''); // Restablecer el valor del input
  };

  const handleChange = (event) => {
    event.preventDefault()
    setInputValue(event.target.value); // Actualizar el valor del input
    onChange(event); // Llamar a la función onChange pasada como prop, si existe
    if (event.target.value==='') {
      handleEventSearch(false)
      setActive(false)
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleEvent();
    }
  };

  return (
    <Paper
      sx={{ display: 'flex', alignItems: 'center', }}
    >
      <IconButton sx={{ p: '10px' }} aria-label="menu">
        {active?<HighlightOffIcon/>:<SearchIcon />}
      </IconButton>
      <InputBase
        required={required||false}
        type={type||"text"}
        fullWidth={fullWidth}
        name={name}
        label={label}
        id={"search"}
        value={inputValue} // Usar el valor del estado inputValue como valor del input
        variant={variant}
        size={size}
        onChange={handleChange} // Usar la función handleChange para actualizar el estado
        onKeyDown={handleKeyDown} // Agregar el evento onKeyDown para detectar la tecla Enter
        placeholder={placeholder || label}
        sx={{ ml: 1, flex: 1 }}
        inputProps={{ 'aria-label': 'search' }}
      />

      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleEvent}>
        
      </IconButton>      
    </Paper>
  );
}
