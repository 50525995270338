/*set components*/
import Home  from './Home';
import Edit  from './Edit';
import EditProductos  from './EditProductos';
import EditGastos  from './EditGastos';
import EditGastosForm  from './EditGastosForm';
import EditEstados  from './EditEstados';

/*set icons*/
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  }, 

  {
    name: "Home",
    path: "/:id",
    component: Edit,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  }, 

  {
    name: "Home",
    path: "/:id/productos",
    component: EditProductos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  }, 
  {
    name: "Home",
    path: "/:id/gastos",
    component: EditGastos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  }, 

  {
    name: "Home",
    path: "/:id/gastos/:sid",
    component: EditGastosForm,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  }, 

  {
    name: "Home",
    path: "/:id/estados",
    component: EditEstados,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  }, 
  
  
];
