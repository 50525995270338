import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import UploadIcon from '@mui/icons-material/Upload';
import SubMenuInterno from './SubMenuInterno';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';


const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} save={"/"}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                   Transporte #43473
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Transporte #43473
                </Typography>                
              </Grid>              
            </Grid>

            <Grid container spacing={2} sx={{mt:{xs:5,md:1},mb:{xs:2,md:0}}}>
              <Grid item xs={12} md={6}>
                <SubMenuInterno md={12} xl={12} option={""}/> 
              </Grid>
            </Grid>              
            
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Fecha de salida"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Fecha de llegada"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Proveedor"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Contenedor No"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Origen"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Piezas"/>
              </Grid>

              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Destino"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Seal"/>
              </Grid>  

              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Puerto"/>
              </Grid>  
              <Grid item xs={12} md={2} sx={{mt:1}} >
                <b>Booking Naviera</b>
              </Grid>
              <Grid item xs={12} md={3} >
                <TextSnippetIcon sx={{fontSize:40,color:"#2fca5a"}}/>
              </Grid>

              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Lista de productos"/>
              </Grid>  
              <Grid item xs={12} md={2} sx={{mt:1}} >
                <b>Estado importación</b>
              </Grid>
              <Grid item xs={12} md={3} >
                <Button variant={'red'} >
                  {'Altamar'}
                </Button>  
              </Grid>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Factor importación"/>
              </Grid>  
                    
              
            </Grid>
          </Container>
}
export default Home
