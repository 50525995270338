import React from 'react';
import Container    from '../../theme/Dashboard';
import BottomIcon from '../../components/BottomIcon/'
import useAsyncStorage     from '../../hooks/useAsyncStorage';
import useAxios     from '../../hooks/useAxios';


const Web=()=>{
  return  <Container>
            <BottomIcon/>
          </Container>
}
export default Web
