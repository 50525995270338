import {Button, Grid, Paper, IconButton } from '@mui/material';
//import { NavLink } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import image    from '../../../assets/png/image-item.png';

const Item=({row,nro})=>{
    return  <Grid item xs={12} md={3}> 
              <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid className={(row.status==='Vencido')?' item-status colorRed ':(row.status==='A tiempo')?' item-status colorGreen ':' item-status colorBlack '} 
                          sx={{fontSize:11,fontWeight:"bold"}}>
                      {row.status}
                    </Grid>
                    <Grid className='item-name'>
                      {row.label}
                    </Grid>
                    <Grid className='item-date'>
                      Fecha de negociación
                    </Grid>
                    <Grid className='item-date2'>
                      {row.fecha}
                    </Grid>
                    <Grid className='item-price'>
                      ${row.precio}
                    </Grid>                  
                  </Grid>
                  <Grid item xs={6} align="right">
                    <Grid sx={{mb:1}}>
                      <IconButton color="green" size="small" variant="text"><WhatsAppIcon sx={{mr:1}}/> </IconButton>
                      <IconButton color="blue" size="small" variant="text"><PhoneForwardedIcon/></IconButton>
                    </Grid>
                    <img className='item-img' src={image} alt="Programandoweb"/>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"right"}>
                  <Grid item xs={"auto"}>
                    <Button variant='primary' sx={{mr:0.5}} color="primary">Ver Productos</Button>
                    <Button variant='red'>No Pago</Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }
  
export default Item