import {Grid, Paper } from '@mui/material';

const Item=()=>{
    return  <Grid item xs={12} md={3}> 
              <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      Lista contenedor 25
                    </Grid>
                    <Grid className='item-date'>
                      Fecha de creación: 10/10/2023
                    </Grid>
                    <Grid className='item-date2'>
                      Cantidad de productos: 5
                    </Grid>
                    <Grid className='item-price'>
                      Peso peso: 10Lb
                    </Grid>                  
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }
  
export default Item