/*set components*/
import Home  from './Home';
import Negociacion  from './Negociacion';
import NegociacionTotal  from './NegociacionTotal';
import Edit  from './Edit';
import EditFormaPago  from './EditFormaPago';
import EditFormaPagoForm  from './EditFormaPagoForm';
import EditFormaProductos  from './EditFormaProductos';
import EditAddProductofotografia  from './EditAddProductofotografia';
import EditAddFormaPago  from './EditAddFormaPago';
import EditAddProducto  from './EditAddProducto';
import Cuentas  from './Cuentas';
/*set icons*/
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Home",
    path: "/partial_transport",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Home",
    path: "/total_transport",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Edit",
    path: "/:id",
    component: Edit,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "EditFormaPago",
    path: "/:id/formapago",
    component: EditFormaPago,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "EditFormaPago",
    path: "/:id/formapago/:id2",
    component: EditAddFormaPago,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "EditFormaPago",
    path: "/:id/productos/:id2",
    component: EditAddProducto,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "EditFormaPago",
    path: "/:id/productos/:id2/fotografia",
    component: EditAddProductofotografia,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "EditFormaProductos",
    path: "/:id/productos",
    component: EditFormaProductos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "EditFormaPagoForm",
    path: "/:id/productosForm",
    component: EditFormaPagoForm,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Cuentas Bancarias",
    path: "/:id/cuentas",
    component: Cuentas,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
];
