import  React from  'react';
import  Grid  from  '@mui/material/Grid';
import  Button  from  '@mui/material/Button';
import { NavLink }  from 'react-router-dom';
import  './home.css';
const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} className="home-content">
              <Grid className="home-center">
                <Button component={NavLink} to="/auth/login" variant="contained" sx={{mt:4}}>
                  Comienza aquí...
                </Button>
              </Grid>
            </Grid>
});


export default Container
