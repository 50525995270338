import {Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
const SubMenu=({option,id})=>{
    return <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={6} xl={4}>
                <Grid className='contentSubMenu'>
                    <Grid container>
                    <Grid item xs={4} align="center">
                        <Button variant={option===''?'btnTagSelect':'btnTag'} component={NavLink} to={"/dashboard/transporte/"+id}>
                            Datos Transporte
                        </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='productos'?'btnTagSelect':'btnTag'} component={NavLink} to={"/dashboard/transporte/"+id+"/productos"}>
                            Productos
                        </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button variant={option==='pago'?'btnTagSelect':'btnTag'} component={NavLink} to={"/dashboard/transporte/"+id+"/pago"}>
                            Pago
                        </Button>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
}
export default SubMenu;