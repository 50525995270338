//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import Submenu from './SubMenu2';



const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                    #1634566
                  </Typography>
                  <Typography component={"h4"} align='center'>
                    Precio: $5.000
                  </Typography>                
                  <Typography component={"h4"} align='center'>
                    Cantidad: 8
                  </Typography>                
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  #1634566
                </Typography>                
                <Typography component={"h4"} align='center'>
                    Precio: $5.000
                </Typography>                
                <Typography component={"h4"} align='center'>
                  Cantidad: 8
                </Typography>                
              </Grid>              
            </Grid>
            <Submenu option={""}/>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Nombre de la lista"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Fecha de creación"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Cantidad de productos"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Peso Total"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Importación Asignado"/>
              </Grid>  
            </Grid>
          </Container>
}
export default Home
