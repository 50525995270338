import { useEffect,useContext, useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import useAxios from '../../../hooks/useAxios';
import Container    from '../../../theme/List';
import { Grid, Button } from '@mui/material';
import Select from '../../../components/Select';
import StateContext from '../../../utils/ContextState';
import { useLocation, useNavigate } from 'react-router-dom';
import Item from './Items';
import Pagination from '../../../components/Pagination';


let filterUrl    =   {};

const Home=()=>{
  const navigate                =   useNavigate();
  const location                =   useLocation();
  const context                 =   useContext(StateContext);
  const {Map}                   =   context;
  const {getInit}               =   useAxios();
  const {formData,setFormData}  =   useFormData();
  
    
  useEffect(()=>{    
    let subcategory   =   ""
    if (filterUrl.subcategory) {
      subcategory   =   "include=subcategory&order_by=name&filter=subcategory.id:"+filterUrl.subcategory;
    }else if (filterUrl.filter) {
      subcategory   =   "include=subcategory.category&filter="+filterUrl.filter;
    }else{
      subcategory   =   "include=subcategory.category&order_by=name&filter=subcategory.category.type:product";
    }    
    if(location.search===''){
      subcategory   =   "include=subcategory.category&order_by=name&filter=subcategory.category.type:product";
    }
    
    getInit("product",{},subcategory).then((response)=>{  
      if (response) {
        setFormData(response);        
      }
    })

  },[location.search])  



  const Filter=()=>{    
    
    const [subcategory,setSubCategory]          =   useState([]);

    const onChange=(name,value)=>{
      let return_=[]
      context.config.subcategory.map((row)=>{
        if (row.category_id===value.id) {
          return_.push(row)  
        }      
      }) 
      
      if (name==='category') {
        setSubCategory(return_)  
      }    
      filterUrl[name] = value.id
      
    }

    const setUrl=()=>{
      let new_url = location.pathname+"?include=subcategory&order_by=name&filter=subcategory.id:"+filterUrl.subcategory
      return navigate(new_url);       
    }

    const clearUrl=()=>{
      filterUrl={}
      let new_url = location.pathname
      return navigate(new_url);      
    }
   


    return  <Grid sx={{p:4}}>
              <Grid container spacing={3} sx={{mb:2}}>
                <Grid item xs={6}>
                  <Select label="Categoría" name="category" data={context.config.category} onChange={onChange}/>
                </Grid>
                <Grid item xs={6}>
                  <Select label="SubCategoría" name="subcategory" data={subcategory} onChange={onChange}/>
                </Grid>
                <Grid item xs={12}>
                  <Select label="Marca"/>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button variant='contained' fullWidth onClick={()=>{setUrl();context.setOpenDialog(false);}}>
                    Aceptar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant='outlined' fullWidth onClick={()=>{clearUrl();context.setOpenDialog(false);}}>
                    Borrar Filtro
                  </Button> 
                </Grid>
              </Grid>
            </Grid>
  }

  const handleFilters=()=>{
    return context.setOpenDialog({
              component:<Filter/>,
              maxWidth:"sm"
            })
  }

  const onKeyDown=(e)=>{
    filterUrl={
      filter:'name/'+e.target.value
    }
    let new_url = location.pathname+"?include=subcategory&order_by=name&filter="+filterUrl.filter
    return navigate(new_url);       
  }

  const handleChange  =   (event, value) => {
    const new_url     =   location.pathname+"?page="+value+"&top="+parseInt(process.env.REACT_APP_PAGINATION);
    navigate(new_url)
  };

  return  <Container {...useFormData()} filter={handleFilters} download={"api/product/export?key="+process.env.REACT_APP_DOWNLOAD} onKeyDown={onKeyDown}>
            <Grid container spacing={1}>
              <Map formData={formData.data} component={Item}/>
            </Grid>       
            <Grid>
              <Pagination handleChange={handleChange} formData={formData} navigate={navigate} location={location}/>                  
            </Grid>
          </Container>
}
export default Home
