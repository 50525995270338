import React from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, setOpen, getInit,maxWidth }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={open.maxWidth||"md"} // Aumento del ancho máximo
      open={open ? true : false}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Grid justifyContent="center"> {/* Eliminación de la propiedad `container` */}
        <Grid item xs={12}>
          {
            open&&open.component && typeof open.component !== 'function'&&(
              open.component
            )
          }
          {
            open&&open.component && typeof open.component === 'function'&&(
              <open.component data={open} handleClose={handleClose} getInit={getInit} subFixed={open.subFixed}  />
            )
          }
        </Grid>
      </Grid>
    </Dialog>
  );
}
