//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';



const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                    Suspensión 300
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Suspensión 300
                </Typography>                
              </Grid>              
            </Grid>
            {
              /*
                <Grid container spacing={2} sx={{mb:4}}>
                  <Grid item xs={12} sm={10} md={8}>
                    <Grid className='contentSubMenu'>
                      <Grid container>
                        <Grid item xs={6} align="center">
                          <Button variant='btnTagSelect' component={NavLink} to="/negociacionInternacional/0/productos/0">
                            Formulario
                          </Button>
                        </Grid>
                        <Grid item xs={6} align="center">
                          <Button variant='btnTag' component={NavLink} to="/dashboard/negociacionInternacional/0/productos/0/fotografia">
                            Fotografía
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>  
              */
            }
            
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Nombre"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Marca"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Categoria"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Serial"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Subcategoria"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Especificación"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Peso"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Descripción"/>
              </Grid>  
            </Grid>
          </Container>
}
export default Home
