/*set components*/
import Dashboard  from './Dashboard';
import Productos  from './Productos';
import Proveedores  from './Proveedores';
import Clientes  from './Clientes';
import Transporte  from './Transporte';
import NegociacionInternacional  from './NegociacionInternacional';
import ComprasProveedoresNacionales  from './ComprasProveedoresNacionales';
import PagoProveedoresInternacionales  from './PagoProveedoresInternacionales';
import PagoProveedoresnacionales  from './PagoProveedoresnacionales';
import PagoClientes  from './PagoClientes';
import CentroAcopio  from './CentroAcopio';
import Usuarios  from './Usuarios';
import BodegaNacional  from './BodegaNacional';
import Importacion  from './Importacion';
import Ventas  from './Ventas';
import Bk404  from '../../components/NotFound/BK403';

/*set icons*/
import HomeIcon from '@mui/icons-material/Home';



import productos from '../../assets/png/modules/productos.png'
import proveedores from '../../assets/png/modules/proveedores.png'
import clientes from '../../assets/png/modules/clientes.png'
import negointernacional from '../../assets/png/modules/Negointernacional.png'
import transporte from '../../assets/png/modules/transporte.png'
import pagosproveedores from '../../assets/png/modules/pagosproveedores.png'
import centrodeacopio from '../../assets/png/modules/centrodeacopio.png'
import importación from '../../assets/png/modules/importación.png'
import comprasprovnacionales from '../../assets/png/modules/comprasprovnacionales.png'
import ventas from '../../assets/png/modules/ventas.png'
import pagosclientes from '../../assets/png/modules/pagosclientes.png'
import bodeganacional from '../../assets/png/modules/bodeganacional.png'
import garantías from '../../assets/png/modules/garantías.png'
import usuarios from '../../assets/png/modules/usuarios.png'
import gastos from '../../assets/png/modules/gastos.png'
import estadoderesultados from '../../assets/png/modules/estadoderesultados.png'
import bancosflujodecaja from '../../assets/png/modules/bancosflujodecaja.png'


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Dashboard,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Home",
    path: "home",
    component: Dashboard,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Productos",
    url: "productos",
    path: "productos/*",
    component: Productos,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Proveedores",
    url: "proveedores",
    path: "proveedores/*",
    component: Proveedores,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Clientes",
    url: "clientes",
    path: "clientes/*",
    component: Clientes,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Negociación Int",
    url: "negociacionInternacional",
    path: "negociacionInternacional/*",
    component: NegociacionInternacional,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Transporte",
    url: "transporte",
    path: "transporte/*",
    component: Transporte,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Pago Proveedores Int",
    url: "pagoProveedores",
    path: "pagoProveedores/*",
    component: PagoProveedoresInternacionales,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Centro de acopio",
    url: "centroAcopio",
    path: "centroAcopio/*",
    component: CentroAcopio,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Importación",
    url: "importacion",
    path: "importacion/*",
    component: Importacion,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Compras Prov Nac",
    url: "comprasProvNacionales",
    path: "comprasProvNacionales/*",
    component: ComprasProveedoresNacionales,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Pago Prov. Nacional",
    url: "pagosProvNacionales",
    path: "pagosProvNacionales/*",
    component: PagoProveedoresnacionales,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Bodega Nacional",
    url: "bodega_nacional",
    path: "bodega_nacional/*",
    component: BodegaNacional,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Ventas",
    url: "ventas",
    path: "ventas/*",
    component: Ventas,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Pagos Clientes",
    url: "pagoClientes",
    path: "pagoClientes/*",
    component: PagoClientes,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Usuarios",
    url: "usuarios",
    path: "usuarios/*",
    component: Usuarios,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
];

export const directorios =   [
  {
      label:"Productos",
      src:productos,
      url: "productos",
      path: "productos/*",
      component:Bk404
  },
  {
      label:"Proveedores",
      src:proveedores,
      url: "proveedores",
      path: "proveedores/*",
      component:Bk404
  },
  {
      label:"Clientes",
      src:clientes,
      url: "clientes",
      path: "clientes/*",
      component:Bk404
  }
]

export const internacional =   [
  {
      label:"Negociación internacional",
      src:negointernacional,
      url: "negociacionInternacional",
      path: "negociacionInternacional/*",
      component:Bk404
  },
  {
      label:"Transporte",
      src:transporte,
      url: "transporte",
      path: "transporte/*",
      component:Bk404
  },
  {
      label:"Pagos Proveedores",
      src:pagosproveedores,
      url: "pagoProveedores",
      path: "pagoProveedores/*",
      component:Bk404
  },
  {
      label:"Centro de acopio",
      src:centrodeacopio,
      url: "centroAcopio",
      path: "centroAcopio/*",
      component:Bk404
  },
  {
      label:"Importación",
      src:importación,
      url: "importacion",
      path: "importacion/*",
      component:Bk404      
  },
]

export const nacional =   [
  {
      label:"Compras Prov. Nacionales",
      src:comprasprovnacionales,
      url: "comprasProvNacionales",
      path: "comprasProvNacionales/*",
      component:Bk404
  },
  {
      label:"Pagos Prov. Nacionales ",
      src:pagosproveedores,
      url: "pagosProvNacionales",
      path: "pagosProvNacionales/*",
      component:Bk404
  },
  {
      label:"Ventas",
      src:ventas,
      url: "ventas",
      path: "ventas/*",
      component:Bk404
  }, 
  {
      label:"Pagos Clientes  ",
      src:pagosclientes,
      url: "pagoClientes",
      path: "pagoClientes/*",
      component:Bk404
  },
  {
      label:"Bodega Nacional ",
      src:bodeganacional,
      url: "bodegaNacional",
      path: "bodegaNacional/*",
      component:Bk404
  },
  {
      label:"Garantías  ",
      src:garantías,
      url: "garantias",
      path: "garantias/*",
      component:Bk404
  },
  {
      label:"Usuarios ",
      src:usuarios,
      url: "usuarios",
      path: "usuarios/*",
      component:Bk404
  },
]

export const finanzas =   [
  {
      label:"Gastos",
      src:gastos,
      url: "gastos",
      path: "gastos/*",
      component:Bk404
  },
  {
      label:"Estado de Resultados",
      src:estadoderesultados,
      url: "estadoResultados",
      path: "estadoResultados/*",
      component:Bk404
  },
  {
      label:"Bancos Flujo de  Caja",
      src:bancosflujodecaja,
      url: "flujoCaja",
      path: "flujoCaja/*",
      component:Bk404
  }
]
