//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';
import UploadIcon from '@mui/icons-material/Upload';
import SubMenuInterno from './SubMenuInterno';



const Home=()=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} save={"/"}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                   Transporte #43473
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Transporte #43473
                </Typography>                
              </Grid>              
            </Grid>

            <SubMenuInterno option={"pago"}/>
            
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Fecha de Pago"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Categoría"/>
              </Grid> 
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Sub Categoría"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Tipode transacción"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Método de Pago"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Valor"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Comentario"/>
              </Grid>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Asociado a"/>
              </Grid>  
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Código"/>
              </Grid>
              <Grid item xs={12} md={6} >
                <Button variant='btnUpload'>
                  <UploadIcon sx={{fontSize:20}}/> Subir Documentos
                </Button>
              </Grid>
            </Grid>
          </Container>
}
export default Home
