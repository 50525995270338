import { Grid, Paper } from "@mui/material"
import galeria,{main} from '../../assets/jpg/galeria'
const Resumen=()=>{
    return  <Grid item xs={12}>
                <Paper sx={{p:2}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Fecha de Ingreso</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            30/05/2023
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Cantidad</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            20
                        </Grid>
                    </Grid>
                    <Grid container sx={{mt:2}}>
                        <Grid item xs={6}>
                            <b>Peso Unitario</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            45
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={6}>
                            <b>Peso Total</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            900
                        </Grid>
                    </Grid>
                </Paper>

                <Paper sx={{p:2,mt:3}}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={"auto"}>
                            <b>Precio Unitario</b>
                        </Grid>                    
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Valor Negociación</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            $450.000
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Valor Transporte</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            $200.000
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Valor Importación</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            $450.000
                        </Grid>
                    </Grid>
                    <Grid container sx={{mt:2}}>
                        <Grid item xs={6}>
                            <b>Valor Total Unitario</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            $1.100.000
                        </Grid>
                    </Grid>
                </Paper>
                <Paper sx={{p:2,mt:3}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Proveedor</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            Toyota
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Número de Negociación</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            3443
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Código Transporte</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            34535
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={6}>
                            <b>Código Importación</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            34535
                        </Grid>
                    </Grid>
                </Paper>

                <Paper sx={{p:2,mt:3}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Nombre</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            Motor VX6
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Categoría</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            Motor
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <b>Subcategoría</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            Tractor
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={6}>
                            <b>Descripción</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            Tractor rfh
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={6}>
                            <b>Marca</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            Toyota
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={6}>
                            <b>Serial</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            shddd
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={6}>
                            <b>Especificación</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            V6
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={6}>
                            <b>Peso en LB</b>
                        </Grid>
                        <Grid item xs={6} align="right">
                            3554
                        </Grid>
                    </Grid>
                </Paper>
                <Paper sx={{p:2,mt:3}}>
                    Fotos complementarias
                    <Grid container sx={{mt:2}}>
                        {
                            galeria.map((row,key)=>{
                            return  <Grid item xs={3} md={3} key={key}>
                                        <img className='img-fluid' src={row} alt="Programandoweb" />
                                    </Grid>  
                            })
                        } 
                    </Grid>
                </Paper>
            </Grid>
}

export default Resumen