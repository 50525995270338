import { useState } from 'react';
import {Button, Grid, Paper } from '@mui/material';
import Input        from '../Input';
import Select from '../Select'
import Counter from '../Counter';


let inputs=[]
let negociacionGlobal={}

const Filter=({axios,context,negociaciones,id,init})=>{

    const [negociacion,setNegociacion]              =   useState([])
    
    const onChange=(name,value)=>{
        // Mapea el array original para obtener solo los datos que necesitas

        //return console.log(value.products)

        const formattedProducts = value.products.map(product => ({
          id: product.id,
          quantity: product.quantity,
          value: product.value,          
        }));

        // Actualiza el estado con el nuevo array formateado
        setNegociacion(value.products);

        inputs            =   formattedProducts;
        negociacionGlobal =   value

    }

    const handleIncrement=(key,quanty)=>{
      let _negociacion            =   [...negociacion];
      _negociacion[key].quantity  =   quanty;
      //setNegociacion(_negociacion)
      inputs=_negociacion;
    }

    const onChange2 = (e,key)=>{    
      let _negociacion            =   [...negociacion];
      _negociacion[key].value     =   e.target.value;
      inputs                      =   _negociacion;      
    }

    const handleSubmit=()=>{

      delete(negociacionGlobal.id)
      delete(negociacionGlobal.name)

      negociacionGlobal.products  = inputs;
      axios.postData({
              ...negociacionGlobal,                
            },"api/negotiation/update/"+id).then((response)=>{
        if (response&&response.data) {
          if (init) {
            init();
          }          
        }
        context.setOpenDialog(false)
      })
      
    }

    
    
    const Item=({row,keys})=>{
        return  <Paper sx={{px:2,py:1,mb:1}}>
                    <Grid><b>{row.name}</b></Grid>
                    <Grid>Categoría: <b>{row.subcategory&&row.subcategory.category&&row.subcategory.category.name}</b></Grid>
                    <Grid>Subcategoría: <b>{row.subcategory&&row.subcategory.name}</b></Grid>
                    <Grid>Peso Unitario: <b>{row.weight}</b></Grid>
                    <Grid container spacing={1} justifyContent={"end"}>
                      <Grid item xs={4}>
                        <Counter keys={keys} event={handleIncrement} defaultValue={row.quantity}/>
                      </Grid>
                      <Grid item xs={6}>
                        <Input  onChange={(e)=>onChange2(e,keys)} 
                                defaultValue={row.value} 
                                size="small" 
                                name="value"
                                type="number" 
                                label="Valor unitario"/>
                      </Grid>                      
                    </Grid>
                </Paper>
    }

    return  <Grid sx={{p:4, backgroundColor:"#e5cefe"}}>
              <Grid container spacing={3} sx={{mb:2}}>
                <Grid item xs={12} align="center">
                  <b>Seleccionar</b>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{mb:2}}>
                <Grid item xs={12}>
                  <Select label="Número de negociación" data={negociaciones} name="negotiation" onChange={onChange} className="bg-white"/>
                </Grid>
              </Grid>
              <Grid container spacing={3}>

                {
                  negociacion&&negociacion.length>0&&(
                    <Grid item xs={12}> 
                        {
                            negociacion.map((row,key)=>{
                                return <Item row={row} keys={key} key={key}/>
                            })
                        }
                    </Grid>
                  )
                }
                
                <Grid item xs={6}>
                  <Button variant='contained' fullWidth onClick={()=>context.setOpenDialog(false)}>
                    Cerrar
                  </Button>
                </Grid>

                {
                  negociacion&&negociacion.length>0&&(
                    <Grid item xs={6}>
                      <Button variant='outlined' fullWidth onClick={handleSubmit}>
                        Guardar
                      </Button>
                    </Grid>
                  )
                }
                

              </Grid>
            </Grid>
  }

  export default Filter