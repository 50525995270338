import Container    from '../../theme/Auth';
import WebLogin from './web/WebLogin';
import MovilLogin from './movil/MovilLogin';
import { Grid } from '@mui/material';

const Login=()=>{
  return  <Container>
            <Grid  >
              <Grid sx={{display:{xs:"none",sm:"block"}}}>
                <WebLogin/>  
              </Grid>
              <Grid sx={{display:{md:"none",xs:"block"}}}>
                <MovilLogin/>  
              </Grid>            
            </Grid>
          </Container>
}
export default Login
