import {Button, Grid, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
const Item=({row,nro})=>{
    return  <Grid item xs={12} md={3}> 
              <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      {
                        nro % 2 === 0&&(
                          <Grid className='colorGreen'>A tiempo</Grid>
                        )
                      }

{
                        nro % 2 !== 0&&(
                          <Grid className='colorRed'>Vencido</Grid>
                        )
                      }
                      
                    </Grid>
                    <Grid className='item-name'>
                      {row.label}
                    </Grid>
                    <Grid className='item-date'>
                      Valor pago:$50.000 USD
                    </Grid>
                    <Grid className='item-date2'>
                      Fecha de pago:10/10/2023
                    </Grid>
                    <Grid className='item-price'>
                      Banco: bancolombia
                    </Grid>                  
                  </Grid>
                  <Grid item xs={12} >
                    <Grid container justifyContent={"end"} spacing={1}>
                      <Grid item xs={"auto"}>
                        <Button variant="primary" component={NavLink} to="/dashboard/pagoProveedores/0">
                          Ver Negociación
                        </Button> 
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Button variant={(row.status==='Vencido')?'red':'green'} component={NavLink} to="/dashboard/pagoProveedores/0">
                          {row.status==='Vencido'?"No Pago":"Pago"}
                        </Button>                         
                      </Grid>
                    </Grid>
                                    
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }
  
export default Item