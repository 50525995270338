import { Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

const GroupInput = ({
  required,
  onChange,
  label,
  type,
  name,
  fullWidth,
  variant,
  size,
  placeholder,
  defaultValue,
  maxLength,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} sx={{ display: { xs: "none", md: "block" } }}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          required={required} // Configura correctamente el atributo required
          type={type || "text"}
          fullWidth={fullWidth || true}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              maxLength: maxLength || 10000, // Define aquí la longitud máxima que desees
            },
          }}
          name={name}
          label={label}
          defaultValue={defaultValue}
          variant={variant}
          size={size}
          onChange={onChange}
          placeholder={placeholder}
        />
      </Grid>
    </Grid>
  );
};

export default GroupInput;
