//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';



const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} save={"/"}>
            <Grid container spacing={2} sx={{mb:4}}>
              <Grid item xs={4} className='borderBottom'>
                <Typography component={"h3"}>
                  Suspensión 300
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{mb:4}}>
              <Grid item xs={4}>
                <Grid className='contentSubMenu'>
                  <Grid container>
                    <Grid item xs={6} align="center">
                      <Button variant='btnTag' component={NavLink} to="/dashboard/proveedores/0">
                        Datos proveedor
                      </Button>
                    </Grid>
                    <Grid item xs={6} align="center">
                      <Button variant='btnTagSelect' component={NavLink} to="/dashboard/proveedores/0/cuentas">
                        Cuenta bancaria
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="País "/>
              </Grid>  
              <Grid item xs={12} md={6} >
                
              </Grid>  
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Banco"/>
              </Grid>  
              
            </Grid>            
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Tipo de Cuenta"/>
              </Grid>  
              
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Input  sx={{mb:2}} 
                        required 
                        onChange={onChange} 
                        size="small" 
                        name="email" 
                        label="Número de cuenta"/>
              </Grid>                
            </Grid>
          </Container>
}
export default Home
