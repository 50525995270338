/*set components*/
import Edit  from './Edit';
import Home  from './Home';
import HomeRemanufactura  from './HomeRemanufactura';
import EditRemanufactura  from './EditRemanufactura';
import EditRemanufacturaProductos  from './EditRemanufacturaProductos';
import EditRemanufacturaMapa  from './EditRemanufacturaMapa';
import EditRemanufacturaCama  from './EditRemanufacturaCama';
import EditRemanufacturaCamaAdd  from './EditRemanufacturaCamaAdd';
import EditRemanufacturaCamaMedio  from './EditRemanufacturaCamaMedio';
import EditRemanufacturaCamaArriba  from './EditRemanufacturaCamaArriba';
import HomeEnviado  from './HomeEnviado';

/*set icons*/
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/remanufactura",
    component: HomeRemanufactura,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/remanufactura/:id",
    component: EditRemanufactura,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/remanufactura/:id/productos",
    component: EditRemanufacturaProductos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/remanufactura/:id/mapa",
    component: EditRemanufacturaMapa,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/remanufactura/:id/mapa/:sid",
    component: EditRemanufacturaCama,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/remanufactura/:id/productos/:sid",
    component: EditRemanufacturaCama,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/remanufactura/:id/mapa/:sid/:sid2",
    component: EditRemanufacturaCamaAdd,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/remanufactura/:id/mapa/:sid/medio",
    component: EditRemanufacturaCamaMedio,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/remanufactura/:id/mapa/:sid/arriba",
    component: EditRemanufacturaCamaArriba,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/enviado",
    component: HomeEnviado,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/:id",
    component: Edit,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  
  
];
