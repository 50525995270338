//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Button, Grid, Link, Typography } from '@mui/material';
import Item from './Items5';
import Submenu from './SubMenu3';
import { NavLink } from 'react-router-dom';


const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },  
]


const Home=(props)=>{
  //const [items,setItems]        =   useState([])
  const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} skipSave={true}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"} align='center'>
                    <b>Cama</b>
                  </Typography>
                  <Typography component={"h4"} align='center'>
                    Peso Total: 20Kg
                  </Typography>                
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  <b>Cama</b>
                </Typography>
                <Typography component={"h4"} align='center'>
                  Peso Total: 20Kg
                </Typography>                 
              </Grid>              
            </Grid>
            <Submenu option={"medio"}/>
            <Grid container spacing={1} sx={{mb:3}}>
              {
                items.map((row,key)=>{
                  return <Item key={key} num={key} row={row} />
                })
              }     
            </Grid>  
            <Grid align="center">
              <Button component={NavLink} to="0" variant='contained'>Agregar Producto</Button>
            </Grid> 
          </Container>
}
export default Home
