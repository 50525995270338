import { Button, Grid, IconButton, Paper } from '@mui/material';
import image    from '../../../assets/png/image-item.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import {formatearMontoEnCOP} from '../../../utils/functions';
import { NavLink } from 'react-router-dom';
const Item=({row})=>{
    return  <Grid item xs={12} md={3}> 
              <Paper sx={{p:2}}>
                <NavLink to={"./"+row.id}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Grid className={(row.status==='Vencido')?' item-status colorRed ':(row.status==='A tiempo')?' item-status colorGreen ':' item-status colorGreen 1colorBlack '} 
                            sx={{fontSize:11,fontWeight:"bold"}}>
                        {row.status||"A tiempo"}
                      </Grid>
                      {
                        row&&row.provider&&row.provider.name&&(
                          <Grid className='item-name'>
                            {row.provider.name}
                          </Grid>
                        )
                      }
                      <Grid className='item-date'>
                        Fecha de negociación
                      </Grid>
                      <Grid className='item-date2'>
                        <b>{row.negotiation_date}</b>
                      </Grid>
                      <Grid className='item-price'>
                        {formatearMontoEnCOP(row.negotiation_value)}
                      </Grid>                  
                    </Grid>
                    <Grid item xs={6} align="right">
                      <Grid sx={{mb:1}}>
                        <IconButton color="green" size="small" variant="text"><WhatsAppIcon sx={{mr:1}}/> </IconButton>
                        <IconButton color="blue" size="small" variant="text"><PhoneForwardedIcon/></IconButton>
                      </Grid>
                      <img className='item-img' src={image} alt="Programandoweb"/>
                    </Grid>
                  </Grid>
                </NavLink>
                <Grid container justifyContent={"right"}>
                  <Grid item xs={"auto"}>
                    <Button component={NavLink} to={"/dashboard/negociacionInternacional/"+row.id+"/formapago"} variant='primary' sx={{mr:0.5}} color="primary">Ver Productos</Button>
                    <Button variant='red'>No Pago</Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }
export default Item  