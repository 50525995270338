import  React from  'react';
import  Grid  from  '@mui/material/Grid';
import  './dashboard.css';
const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} className="dashboard-content">
              <Grid>
                <Grid variant="outlined" className="paper">
                  {props.children}
                </Grid>
              </Grid>
            </Grid>
});


export default Container
