import { useState, useEffect } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import {Button, Grid, Typography, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import Loading from '../../../components/Loading/LinearWithLabel';
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import { useNavigate } from "react-router-dom";
import {formatearMontoEnCOP} from '../../../utils/functions'



const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },  
]


const Item=({row,nro})=>{
  return  <Grid item xs={12} md={3}> 
            <Paper sx={{p:2}}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <NavLink to={"/dashboard/negociacionInternacional/"+row.negotiation_id+"/formapago/"+row.id}> 
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      Pago {row.number}
                    </Grid>
                    <Grid className='item-name'>
                      {row.label}
                    </Grid>
                    <Grid className='item-date'>
                      Fecha de negociación <b>{row.negotiation.negotiation_date}</b>
                    </Grid>
                    <Grid className='item-date'>
                      Medio de pago: <b>{row.method}</b>
                    </Grid>
                    <Grid className='item-price'>
                      {formatearMontoEnCOP(row.value)}
                    </Grid>                  
                  </NavLink>
                </Grid>                
                <Grid item xs={4} >
                  <Grid className='flexBottom'>
                    <Button variant={(row.state_id!=='paid')?'red':'green'} component={NavLink} to="/dashboard/negociacionInternacional/0/productosForm">
                      {row.state_id==='paid'?"Pago":"No Pago"}
                    </Button>  
                  </Grid>                  
                </Grid>
              </Grid>
            </Paper>
          </Grid>
}

let data  = []
const timeLoading                     =   2000;
let inputs= {}

const Home=(props)=>{
  const { getInit }               =   useAxios();
  let { id }                      =   useParams();
  const userStorage               =   useAsyncStorage("formProduct");
  const navigate                  =   useNavigate();
  const [loading,setLoading]      =   useState(true);

  useEffect(()=>{
    if (id==="new") {
      data=[]
      setLoading(false)
      userStorage.removeData();  
      return;
    }  
    getInit("negotiation",{},"filter=id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {      
        inputs=response.data[0];         
      }      
    })   
      
    getInit("payment",{},"include=state,negotiation&filter=negotiation_id:"+id).then((response)=>{
      if (response&&response.data&&response.data[0]) {      
        data=response.data; 
        setTimeout(() => {
          setLoading(false)
        }, timeLoading);              
      }else{
        data=[]; 
        setLoading(false)        
      }      
    })    
  },[])

  return  <Container {...useFormData()} save={"/"} add={{component:NavLink,to:"0"}}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                    Negociacón Internacional #{inputs.id}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  Negociacón Internacional #{inputs.id}
                </Typography>                
              </Grid>              
            </Grid>

            <Grid container spacing={2} sx={{mb:4}}>
              <Grid item xs={12} sm={10} md={8}>
                <Grid className='contentSubMenu'>
                  <Grid container>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTag' component={NavLink} to={"/dashboard/negociacionInternacional/"+id}>
                        Negociación
                      </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTagSelect' component={NavLink} to={"/dashboard/negociacionInternacional/"+id+"/formapago"}>
                        Forma de Pago
                      </Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant='btnTag' component={NavLink} to={"/dashboard/negociacionInternacional/"+id+"/productos"}>
                        Productos
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              loading&&(
                <Grid>
                  <Loading time={timeLoading} increment={timeLoading}/>
                </Grid>
              )
            }
            {
              !loading&&(
                <Grid container spacing={2}>
                  {
                    data.map((row,key)=>{
                      return <Item key={key} row={row} nro={key} />
                    })
                  }  
                </Grid>
              )
            }
            { 
              !loading&&data&&data.length===0&&(
                <Grid >No hay pagos aún</Grid>                
              )
            }  
            
          </Container>
}
export default Home
