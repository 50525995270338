import { useContext } from 'react';
import Input        from '../../../components/Input';
import Button       from '@mui/material/Button';
//import { NavLink }  from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import useFormData  from '../../../hooks/useFormData';
import useAxios     from '../../../hooks/useAxios';
import StateContext from '../../../utils/ContextState';
import useAsyncStorage     from '../../../hooks/useAsyncStorage';
import { useNavigate, useLocation, NavLink } from "react-router-dom";

import logo   from '../../../assets/png/logo.png';
import vehiculo   from '../../../assets/png/vehiculo.png';
import left   from '../../../assets/png/elementTopLeft.png';
import left2   from '../../../assets/png/elementTopLeft2.png';
import right   from '../../../assets/png/elementBottonRight2.png';

import elementCenter   from '../../../assets/png/elementCenter.png';

const CenteredImage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Grid className='img-center' align="center">
        <img
          src={elementCenter}
          alt="Imagen responsiva"
          style={{ maxWidth: '50%', height: 'auto' }}
        />
      </Grid>      
    </div>
  );
};

const WebLogin=()=>{
    const userStorage             =   useAsyncStorage("user");
    const context                 =   useContext(StateContext);
    const { formData, onChange }  =   useFormData();
    const axios                   =   useAxios();
    const navigate                =   useNavigate();
    const location                =   useLocation();
    

    const onSubmit=(e)=>{
        e.preventDefault()
        console.log(500)
        axios.postData(formData).then((response)=>{
          if (  response  &&
                response.data &&
                response.code &&
                response.code==="SUCCESS"
              ) {
    
            let permissions = {}
            if (response.data&&response.data.permissions) {
              response.data.permissions.map((row,key)=>{
                const split = row.name.split("_")
                if (!permissions[split[0]]) {
                  permissions[split[0]] = []
                }
                return permissions[split[0]].push(split[1])
              })
            }
    
    
            const setStorageAndState  =  {
                                            access_token:response.data.access_token,
                                            ...response.data.user,
                                            roles:response.data.roles,
                                            permissions:permissions,
                                          };
            /*vamos a setear el estado en App.js para que esté disponible global*/
            context.setUser(setStorageAndState)
            /*Guardo en el storage también*/
            userStorage.setData(setStorageAndState).then(resp=>{
              context.setOpenMessage("Inicio de sesión, será redirigido a tu cuenta...")
              if (location.search==='?redirect=true' && window.history.length===0) {
                return navigate("/");
              }else {
                return navigate("/dashboard");
              }
            })
          }
        })
    }

    return  <>  

              <Grid className="left">
                <img src={left} alt=""/>
              </Grid>
              <Grid className="left2">
                <img src={left2} alt=""/>
              </Grid>
              <Grid container className='vh100'>
                <Grid item xs={12} md={6} className='flex'>
                  <Grid className='flex-center' align="center">
                    <Grid sx={{mb:2}}><img src={logo} alt=""/></Grid>
                    <Grid>

                      <form  onSubmit={onSubmit}>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={10} md={7}>  
                              <Typography sx={{fontSize:30,fontWeight:"bold"}}>
                                  Recuperación de contraseña
                              </Typography>
                            </Grid>
                            <Grid item xs={10} md={7} sx={{mb:3}}>   
                              <Typography sx={{fontSize:14}}>
                                Ingresa tu nueva contraseña y confírmala
                              </Typography>
                            </Grid>
                            <Grid item xs={10} md={7}>  
                              <Input sx={{mb:2}} required onChange={onChange} size="small" fullWidth name="email" type="password" label="Contraseña"/>
                              <Input sx={{mb:2}} required onChange={onChange} size="small" fullWidth name="email" type="password" label="Repetir Contraseña"/>
                            </Grid>
                            <Grid item xs={10} md={7}>  
                            <Button style={{ display: 'block', width: '100%' }} 
                                    size="large" 
                                    variant='contained' 
                                    component={NavLink}
                                    to="/auth/recover/token"
                            >
                                Recuperar contraseña
                            </Button>
                            </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item 
                      xs={12} 
                      md={6} 
                      sx={  
                            {
                              display:{
                                        xs:"none",
                                        md:"block"
                                      },
                              background: "url('" + vehiculo + "') bottom / cover no-repeat",
                              width: "100%",
                            }
                          }
                >
                  <Grid sx={{position:"absolute",top:0,left:0,width:"100%",zIndex:1,pointerEvents: "none",}}>
                    <CenteredImage />
                  </Grid>    
                </Grid>
              </Grid>
            </>
}
export default WebLogin;