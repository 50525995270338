import { Paper, Typography } from '@mui/material';
import Grid  from  '@mui/material/Grid';
import './css.css'
import {directorios,internacional,nacional,finanzas} from '../../app/Dashboard/routes'
import {NavLink} from 'react-router-dom'

const Item=({row})=>{
    return  <Grid item xs={4}  sm={2} md={2} lg={1}>
                <Paper  elevation={5}>
                    <NavLink to={"/dashboard/"+(row.url||row.path)}>
                        <Grid className='item' sx={{p:0}} align="center">
                            <img  className='img-fluid' src={row.src} alt=""/>                                                    
                            <Grid align="center" sx={{mt:{md:-2,xs:-1},fontSize:{md:12,xs:11},lineHeight:{md:"17px",xs:"13px"},height:{md:44,xs:28}}}>
                                {row.label}
                            </Grid>                                
                        </Grid>                        
                    </NavLink>    
                </Paper>
            </Grid> 
}

const HomeBotton=()=>{
   
    
    return  <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography component={"h2"}>
                        <b>Directorios</b>
                    </Typography>
                </Grid>    
                <Grid item xs={12} >
                    <Grid container spacing={2}> 
                        {   
                            directorios.map((row,key)=>{
                                return <Item key={key} row={row}/>    
                            })
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"h2"}>
                        <b>Operación internacional</b>
                    </Typography>
                </Grid>    
                <Grid item xs={12} >
                    <Grid container spacing={2}> 
                        {   
                            internacional.map((row,key)=>{
                                return <Item key={key} row={row}/>    
                            })
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"h2"}>
                        <b>Operación nacional</b>
                    </Typography>
                </Grid>    
                <Grid item xs={12} >
                    <Grid container spacing={2}> 
                        {   nacional.map((row,key)=>{
                                return <Item key={key} row={row}/>    

                            })
                        }
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography component={"h2"}>
                        <b>Finanzas</b>
                    </Typography>
                </Grid>    
                <Grid item xs={12} >
                    <Grid container spacing={2}> 
                        {   finanzas.map((row,key)=>{
                                return <Item key={key} row={row}/>    

                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
}
export default HomeBotton
