import React from 'react';
import StateContext from '../../utils/ContextState'
import {  Avatar, Grid, Typography  } from '@mui/material';
//import SupportIcon from '@mui/icons-material/Support';
//import NotificationsIcon from '@mui/icons-material/Notifications';
import './index.css'
const AvatarProfile=()=>{
    const context   =   React.useContext(StateContext);
    return      <Grid container justifyContent={"right"}>
                    <Grid item xs={"auto"} sx={{mr:2,mt:1}} >
                        <Avatar sx={{height:28,width:28}}></Avatar>
                    </Grid>
                    <Grid align="left" item xs={"auto"}>
                        <Grid sx={{height:14}}>
                            <Typography variant='div' className='profile-name'>
                                {context.user.name}
                            </Typography>                            
                        </Grid>                        
                        <Grid sx={{height:10,mb:3}}>
                            <Typography variant='div' className='profile-email'>
                                {context.user.email}
                            </Typography>
                        </Grid>                        
                    </Grid>
                </Grid>
}
export default AvatarProfile