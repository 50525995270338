import { useContext, useEffect, useState  } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import { NavLink } from 'react-router-dom';
import Select from '../../../components/Select/GroupSelect';
import StateContext from '../../../utils/ContextState';
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import Loading from '../../../components/Loading/LinearWithLabel';
import UploadIcon from '@mui/icons-material/Upload';
import {getImagePreview} from '../../../utils/functions';


let inputs                            =   {}
let category                          =   0;
let subCategory                       =   0;
const timeLoading                     =   2000;

const Edit=()=>{
  
  let { id }                          =   useParams();  
  const axios                         =   useAxios();
  const navigate                      =   useNavigate();
  const context                       =   useContext(StateContext);
  const [subcategory,setSubCategory]  =   useState([]);
  const userStorage                   =   useAsyncStorage("formProduct");
  const [loading,setLoading]          =   useState(true);
  const [mainImage, setMainImage]     =   useState(null); // Estado para la imagen principal
    
  const onChange = (e)=>{    
    inputs[e.target.name] = e.target.value;
  }

  const onChange2 = (name,value)=>{
    if (!value||!value.id) {
      return false;
    }
    inputs[name]  =  value.id;
    let return_=[]
    context.config.subcategory.map((row)=>{
      if (row.category_id===value.id) {
        return_.push(row)  
      }      
    }) 
    if (name==='category') {
      setSubCategory(return_)  
    }            
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    delete(inputs.complementary_photos);
    axios.postUpload({...inputs,photo:mainImage},parseInt(id)>0?"api/product/update/"+id:"api/product/create?").then((response)=>{
      if (response&&response.data) {
        navigate('../'+response.data.id+"/fotografia")
      }
    })
    
  };

  useEffect(()=>{
    if(userStorage.data&&userStorage.data.name&&id!=="new"){
      inputs      =   userStorage.data      
      if (inputs&&inputs.subcategory&&inputs.subcategory.category&&inputs.subcategory.category.id) {
        category    =   inputs.subcategory.category.id;  
      }      
      if (inputs&&inputs.subcategory&&inputs.subcategory.id) {
        subCategory =   inputs.subcategory.id;
      }      
      setTimeout(() => {
        setLoading(false)
      }, timeLoading);
    }else if(id==="new"){
      userStorage.removeData();
      inputs    =   {}
      setLoading(false)
    }
  },[userStorage.data])

  
  

  return  <form onSubmit={handleSubmit}>
            <Container {...useFormData()} onClickSubmit={true}>
              <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
                <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                  <Grid  className='borderBottom' >
                    <Typography component={"h3"}>
                      {inputs.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                  <Typography component={"h3"} align='center'>
                    {inputs.name}
                  </Typography>                
                </Grid>              
              </Grid>
              <Grid container spacing={2} sx={{mb:4}}>
                <Grid item xs={12} sm={10} md={8}>
                  <Grid className='contentSubMenu'>
                    <Grid container>
                      <Grid item xs={6} align="center">
                        <Button variant='btnTagSelect' component={NavLink} to={"/dashboard/productos/"+id}>
                          Formulario
                        </Button>
                      </Grid>
                      <Grid item xs={6} align="center">
                        <Button variant='btnTag' component={NavLink} to={"/dashboard/productos/"+id+"/fotografia"}>
                          Fotografía
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {
                loading&&(
                  <Grid>
                    <Loading time={timeLoading} increment={timeLoading}/>
                  </Grid>
                )
              }
              {
                !loading&&(
                    <Grid container spacing={2}  >
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                defaultValue={inputs.name}
                                onChange={onChange} 
                                size="small" 
                                name="name" 
                                label="Nombre"/>
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                defaultValue={inputs.brand}
                                onChange={onChange} 
                                size="small" 
                                name="brand" 
                                label="Marca"/>
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Select defaultValue={category} label="Categoría" name="category" data={context.config.category} onChange={onChange2}/>                
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                defaultValue={inputs.serial}
                                onChange={onChange} 
                                size="small" 
                                name="serial" 
                                label="Serial"/>
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Select defaultValue={subCategory} label="SubCategoría" name="subcategory_id" data={subcategory&&subcategory.length>0?subcategory:context.config.subcategory} onChange={onChange2}/>                
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                onChange={onChange} 
                                size="small" 
                                name="specification" 
                                defaultValue={inputs.specification}
                                label="Especificación"/>
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                type="number"
                                onChange={onChange} 
                                size="small" 
                                defaultValue={inputs.weight}
                                name="weight" 
                                label="Peso"/>
                      </Grid>  
                      <Grid item xs={12} md={6} >
                        <Input  sx={{mb:2}} 
                                required 
                                maxLength="150"
                                defaultValue={inputs.description}
                                onChange={onChange} 
                                size="small" 
                                name="description" 
                                label="Descripción"/>
                      </Grid>  

                      <Grid item xs={12} md={3} >
                        <Button variant='btnUpload' className='content-image-upload' sx={{my:2}}>
                          <input type="file" name="photo" className='input' onChange={(e) => setMainImage(e.target.files[0])} />
                          <UploadIcon sx={{fontSize:20}}/> Subir Foto principal
                        </Button>
                        {
                          mainImage&&(<img className='img-fluid'  src={getImagePreview(mainImage)} alt="Programandoweb" />)
                        } 
                        {
                          !mainImage&&inputs.photo&&(<img className='img-fluid'  src={inputs.photo} alt="Programandoweb" />)
                        } 
                                    
                      </Grid>              

                  </Grid>
                )
              }
                              


                
            </Container>
            
          </form>  
}
export default Edit
