//import { useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Grid, Typography } from '@mui/material';
import Item from './Items3';
import Input        from '../../../components/Input/GroupInput';
import Submenu from './SubMenu2';
import StateContext from '../../../utils/ContextState'
import React from 'react';
import { NavLink } from 'react-router-dom';



const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },  
]


const Modal = ()=>{
  const context   =   React.useContext(StateContext)
  const onChange=()=>{

  }
  return  <Grid sx={{p:4}}>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs={12}>
                  <b>Pagos</b>
              </Grid>
              <Grid item xs={12} md={6} >
                <Input 
                      sx={{mb:0}}
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Fecha Pago"/>              
              </Grid>              
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Categoría"/>              
              </Grid>   
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Subcategoría"/>              
              </Grid>              
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Tipo de transacción"/>              
              </Grid>              
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Método de pago"/>              
              </Grid> 
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Estado de pago"/>              
              </Grid> 
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Valor"/>              
              </Grid>             
              <Grid item xs={12} md={6} >
                <Input 
                      required 
                      onChange={onChange} 
                      size="small" 
                      name="email" 
                      label="Comentario"/>              
              </Grid>  
            </Grid>
          </Grid>  
}


const Home=(props)=>{
  /*
  const context                   =   React.useContext(StateContext)
  const handleOpenModal=()=>{
    context.setOpenDialog({
      open:true,
      component:<Modal/>
    })
  }
  */
  //const [items,setItems]        =   useState([])
  //const { formData, onChange }    =   useFormData();
  return  <Container {...useFormData()} add={{component:NavLink,to:"0"}}>
            <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
              <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                <Grid  className='borderBottom' >
                  <Typography component={"h3"}>
                    #1634566
                  </Typography>
                  <Typography component={"h4"} align='left'>
                    Precio: $5.000
                  </Typography>                
                  <Typography component={"h4"} align='left'>
                    Cantidad: 8
                  </Typography>                
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                <Typography component={"h3"} align='center'>
                  #1634566
                </Typography>                
                <Typography component={"h4"} align='center'>
                    Precio: $5.000
                </Typography>                
                <Typography component={"h4"} align='center'>
                  Cantidad: 8
                </Typography>                
              </Grid>              
            </Grid>
            <Submenu option={"productos"}/>
            <Grid container spacing={1} sx={{mb:3}}>
              {
                items.map((row,key)=>{
                  return <Item key={key} row={row} />
                })
              }     
            </Grid>   
          </Container>
}
export default Home
