import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function PaginationRounded({ formData, handleChange }) {
  // Calcular la cantidad de páginas
  const itemsPerPage  =   parseInt(process.env.REACT_APP_PAGINATION);
  const totalPages    =   Math.ceil((formData.length||0) / itemsPerPage);
  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      <Pagination count={totalPages} variant="outlined" shape="rounded"  onChange={handleChange}/>
    </Stack>
  );
}
