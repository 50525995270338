import { useContext } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/List';
import { Grid, Typography, Button , Paper } from '@mui/material';
import Item from './Items';
import Submenu from './SubMenu';

import StateContext from '../../../utils/ContextState';
import Select from '../../../components/Select'


const items   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },  
]

const items2   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },  
]

const items3   =   [
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"A tiempo"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:"Vencido"
  },
  {
    label:"Roberto Manrique",
    fecha:"10/12/2023",
    precio:"50.000.000",
    image:"",
    whatsapp:"+573115000926",
    phone:"+573115000926",
    status:""
  },  
]


const Filter=({context})=>{
  return  <Grid sx={{p:4}}>
            <Grid container spacing={3} sx={{mb:2}}>
              <Grid item xs={6}>
                <Select label="Categoría"/>
              </Grid>
              <Grid item xs={6}>
                <Select label="Subcategoría"/>
              </Grid>
              <Grid item xs={6}>
                <Select label="Marca"/>
              </Grid>
              <Grid item xs={6}>
                <Select label="Proveedor"/>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button variant='contained' fullWidth>
                  Aceptar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant='outlined' fullWidth onClick={()=>context.setOpenDialog(false)}>
                  Borrar Filtro
                </Button> 
              </Grid>
            </Grid>
          </Grid>
}


const Home=(props)=>{

  const context               =   useContext(StateContext);
  
  const handleFilters=()=>{
    return context.setOpenDialog({
              component:<Filter context={context}/>,
              maxWidth:"sm"
            })
  }

  return  <Container {...useFormData()} save={"/"} skipAdd={true} filter={handleFilters}>
            <Submenu option={""}/>
            <Grid container spacing={0} sx={{mb:3}}>
              <Grid item xs={12} className='separador-letras'>
                <Typography sx={{pl:1,pt:0.5,pb:0.5,m:0,fontWeight:"bold"}}>
                  CHEVROLET
                </Typography>
              </Grid>
            </Grid>       
            <Grid container spacing={1} sx={{mb:3}}>
              {
                items.map((row,key)=>{
                  return <Item key={key} row={row} />
                })
              }     
            </Grid>       

            <Grid container spacing={0} sx={{mb:3}}>
              <Grid item xs={12} className='separador-letras'>
                <Typography sx={{pl:1,pt:0.5,pb:0.5,m:0,fontWeight:"bold"}}>
                  FORD
                </Typography>
              </Grid>
            </Grid>       
            <Grid container spacing={1} sx={{mb:3}}>
              {
                items2.map((row,key)=>{
                  return <Item key={key} row={row} />
                })
              }     
            </Grid>

            <Grid container spacing={0} sx={{mb:3}}>
              <Grid item xs={12} className='separador-letras'>
                <Typography sx={{pl:1,pt:0.5,pb:0.5,m:0,fontWeight:"bold"}}>
                  SUZUKI
                </Typography>
              </Grid>
            </Grid>       
            <Grid container spacing={1} sx={{mb:3}}>
              {
                items3.map((row,key)=>{
                  return <Item key={key} row={row} />
                })
              }     
            </Grid>             
          </Container>
}
export default Home
