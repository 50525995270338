/*set components*/
import Home  from './Home';
import HomeInternacional  from './HomeInternacional';
import HomeNacional  from './HomeNacional';
import Negociacion  from './Negociacion';
import Edit  from './Edit';
/*set icons*/
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Home",
    path: "/:id",
    component: Edit,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/internacional/:id",
    component: Edit,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/nacional/:id",
    component: Edit,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/internacional",
    component: HomeInternacional,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Home",
    path: "/nacional",
    component: HomeNacional,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Home",
    path: "/negociacionparcial",
    component: Negociacion,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  
];
