import React  from  'react';
import Grid   from  '@mui/material/Grid';
import  './auth.css';

const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} sx={{position:"relative"}} component={"main"}>
              {props.children}              
            </Grid>
});


export default Container

