import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import StateContext from '../utils/ContextState';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";



const BACKEND = window.location.hostname === 'localhost' ? process.env.REACT_APP_BACKEND : process.env.REACT_APP_BACKENDREMOTE;

const eliminarDashboard=(str)=>{
  // Utiliza la función replace para eliminar la palabra "/dashboard" y cualquier carácter que lo siga
  // El patrón "/dashboard" está precedido por una barra "/", por lo que usamos un escape "\\"
  // y el modificador "g" para que se realice la sustitución en todo el string, no solo en la primera coincidencia.
  return str.replace(/\/dashboard/g, '');
}

const quanty  =   parseInt(process.env.REACT_APP_PAGINATION);

const useAxios = () => {
  const location              =   useLocation();
  const navigate              =   useNavigate();
  const context               =   useContext(StateContext);
  const [data, setData]       =   useState(null);
  const [url, setUrl]         =   useState(BACKEND + "api"+ location.pathname);
  const token                 =   localStorage.getItem('user') || null;

  

  useEffect(() => {
    setUrl(BACKEND + "api"+ location.pathname)
  }, [location]);

  const getData = async (endpoint,requestData,params,top) => {
    
    const me  =   JSON.parse(localStorage.getItem('user'))
    
    context.setLoading(true);
    
    let _url  =   url;

    if (endpoint) {
      _url    =   BACKEND + "api/"+endpoint;
    }   

    if (!_url.includes("page")&&!_url.includes("top")&&location.search==='') {
      _url  =   _url+"?top="+(top||quanty)+"&page=1";
    }else if (location.search!=='') {
      _url  =   _url+location.search;
    }

    if (params) {
      _url  =   _url+'&'+params
    }

    _url  =   eliminarDashboard(_url)    

    try {
      const response = await axios.get(_url, {
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
          "Content-Type":"text/html",
          "Accept":"*/*",
          "Referrer-Policy":"same-origin"
        },        
      });
      
      if (response.data) {
        return response.data  
      }else{
        return {}
      }
      
    } catch (err) {
      
      if (err&&err.response&&err.response.status&&err.response.status===404) {
        return navigate("/auth/login");
      }
      context.setOpenMessage(err.name+": "+err.message+" "+_url)
      return  {
        error:{
          name:err.name,
          code:err.code,
          message:err.message
        }                
      }
    } finally {
      context.setLoading(false);
    }
  };

  const getInit     =   async(endpoint,requestData,params,top)=>{
    const response  =   await getData(endpoint,requestData,params,top);
    return response;
  }

  const postData  =   async (requestData, endpoint) => {

    const me  =   JSON.parse(localStorage.getItem('user'))

    

    let _url = false;
    if (endpoint) {
      _url = BACKEND + endpoint;
    }

    try {
      //return console.log(_url , url,me.access_token,requestData);
      const response = await axios.post(_url || url, requestData, {
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
          "Content-Type":"application/json",
          "Accept":"*/*",
          "Referrer-Policy":"same-origin"
        },
      });

      if (response.data && response.data.code && response.data.code === 'ERROR') {
        context.setOpenMessage(response.data.code + ": " + response.data.message)
      }

      if (response&&response.data&&response.data.errors) {
        if (response.data.errors.description||response.data.errors.specification) {
          context.setOpenMessage("Error: "+(response.data.errors.description||response.data.errors.specification))          
        }else{
          let errors="";
          Object.entries(response.data.errors).map((row,key)=>{
            return errors=errors+" ("+row[0]+") "+row[1];
          })
          context.setOpenMessage("Error: "+errors)          
        }
        //context.setOpenMessage("Error: "+(response.data.errors.description||response.data.errors.specification))        
        return false;
      }else if(response&&response.data&&response.data.message){
        context.setOpenMessage("Error: "+response.data.message);                          
        return false;
      }

      if (response && response.data) {
        setData(response.data);
        return response.data;
      }
    } catch (err) {
      if (err&&err.response&&err.response.statusText&&err.response.statusText==='Unauthorized') {
        return navigate("/auth/login");
      }
      if (err.response && err.response.data && err.response.data.code && err.response.data.message) {
        context.setOpenMessage(err.response.data.code + ": " + err.response.data.message)
      }
    } finally {
      context.setLoading(false);
    }
  };

  const postUpload = async (requestData, endpoint) => {
    try {
      const me = JSON.parse(localStorage.getItem('user'));
  
      const formData = new FormData();
  
      // Recorre las propiedades de requestData
      for (const key in requestData) {
        if (requestData.hasOwnProperty(key)) {
          const value = requestData[key];
  
          // Si el valor es un archivo (File o array de Files)
          if (value instanceof File || (Array.isArray(value) && value.every(item => item instanceof File))) {
            // Si es un array de Files
            if (Array.isArray(value)) {
              // Recorre el array y agrega cada archivo
              value.forEach((file, index) => {
                formData.append(`${key}[]`, file);
              });
            } else {
              // Es un solo archivo
              formData.append(key, value);
            }
          } else {
            // No es un archivo, simplemente agrega la propiedad
            formData.append(key, value);
          }
        }
      }
  
      let _url = false;
      if (endpoint) {
        _url = BACKEND + endpoint;
      }
  
      const response = await axios.request({
        url: BACKEND + (endpoint),
        method: "POST",
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
          'Content-Type': 'multipart/form-data',
          "Accept": "*/*",
          "Referrer-Policy": "same-origin"
        },
        data: formData
      });
      
      if (response&&response.data&&response.data.errors) {
        if (response.data.errors.description||response.data.errors.specification) {
          context.setOpenMessage("Error: "+(response.data.errors.description||response.data.errors.specification))          
        }else{
          let errors="";
          Object.entries(response.data.errors).map((row,key)=>{
            return errors=errors+" ("+row[0]+") "+row[1];
          })
          context.setOpenMessage("Error: "+errors)          
        }
        
        return false;
      }else if(response&&response.data&&response.data.message){
        context.setOpenMessage("Error: "+response.data.message);                          
        return false;
      }


      return response && response.data;
    } catch (error) {
      // Manejar cualquier excepción aquí
      console.error("Error en postUpload:", error);
      // Puedes lanzar una excepción personalizada o manejarla de alguna otra manera si es necesario
      throw error;
    }
  };
  
    

  const postDataAttachment = async (requestData, endpoint) => {
    const me = JSON.parse(localStorage.getItem('user'));

    context.setLoading(true);

    let _url = false;
    if (endpoint) {
      _url = BACKEND + endpoint;
    }

    try {
      const formData = new FormData();
      Object.entries(requestData).map((row,key)=>{
        return formData.append(row[0], row[1]);
      })

      const response = await axios.post(_url || url, formData, {
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
          'Content-Type': 'multipart/form-data', // Establecer la cabecera adecuada para enviar archivos
        },
      });

      if (response.data && response.data.code && response.data.code === 'ERROR') {
        context.setOpenMessage(response.data.code + ": " + response.data.message);
      }
      if (response && response.data) {
        setData(response.data);
        return response.data;
      }
    } catch (err) {
      if (err&&err.response&&err.response.statusText&&err.response.statusText==='Unauthorized') {
        return navigate("/auth/login");
      }
      if (err.response && err.response.data && err.response.data.code && err.response.data.message) {
        context.setOpenMessage(err.response.data.code + ": " + err.response.data.message);
      }
    } finally {
      context.setLoading(false);
    }
  };


  const putData = async (requestData, endpoint) => {

    const me  =   JSON.parse(localStorage.getItem('user'))

    context.setLoading(true);

    let _url = false;
    if (endpoint) {
      _url = BACKEND + endpoint;
    }

    try {
      const response = await axios.put((_url || url)+"/"+requestData.id, requestData, {
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      if (response.data && response.data.code && response.data.code === 'ERROR') {
        context.setOpenMessage(response.data.code + ": " + response.data.message)
      }

      if (response&&response.errors) {
        return console.log(response.errors)
      }

      if (response && response.data) {
        setData(response.data);
        return response.data;
      }
    } catch (err) {
      if (err&&err.response&&err.response.statusText&&err.response.statusText==='Unauthorized') {
        return navigate("/auth/login");
      }
      if (err.response && err.response.code && err.response.message) {
        context.setOpenMessage(err.response.code + ": " + err.response.message)
      }

    } finally {
      context.setLoading(false);
    }
  };

  const deleteData = async (endpoint) => {

    const me  =   JSON.parse(localStorage.getItem('user'))

    context.setLoading(true);


    let _url = false;
    if (endpoint) {
      _url = BACKEND + endpoint;
    }

    try {
      await axios.delete(_url || url, {
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
        },
      });
      setData(null);
      return true;
    } catch (err) {
        if (err.response && err.response.code && err.response.message) {
          context.setOpenMessage(err.response.code + ": " + err.response.message)
        }

      } finally {
        context.setLoading(false);
      }
      };


      return {
        getInit,
        data,
        postData,
        postUpload,
        postDataAttachment,
        putData,
        deleteData,
        getData, // Función para realizar una solicitud GET
      };
    };

    export default useAxios;
