/*set components*/
import Edit  from './Edit';
import EditFormaPago  from './EditFormaPago';
import EditProductos  from './EditProductos';
import Home  from './Home';
import HomePagos  from './HomePagos';
import HomeProductos  from './HomeProductos';
import EditAddFormaPago  from './EditAddFormaPago';
import EditFormaProductos  from './EditFormaProductos';
import EditAddProductos  from './EditAddProductos';
/*set icons*/
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/pagos",
    component: HomePagos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/nopagos",
    component: HomeProductos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/:id",
    component: Edit,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/:id/pagos",
    component: EditFormaPago,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "Home",
    path: "/:id/productos",
    component: EditProductos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "EditFormaPago",
    path: "/:id/pagos/:id2",
    component: EditAddProductos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "EditFormaProductos",
    path: "/:id/productos",
    component: EditFormaProductos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },

  {
    name: "EditFormaProductos",
    path: "/:id/productos/:sid",
    component: EditAddProductos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  
  
];

