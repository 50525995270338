import { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import right from '../../../assets/png/elementBottonRight.png'
import right2 from '../../../assets/png/elementBottonRight2.png'

import left from '../../../assets/png/elementTopLeft.png'
import left2 from '../../../assets/png/elementTopLeft2.png'

import logo from '../../../assets/png/logo2.png'
import logo2 from '../../../assets/png/logo3.png'

import Button       from '@mui/material/Button';
import { NavLink }  from 'react-router-dom';

const Splash=()=>{
  return  <Grid className="vh100 flex splash position-relative">
            <Grid className="left">
              <img alt="" src={left}/>
            </Grid>
            <Grid className="right">
              <img alt="" src={right}/>
            </Grid>
            <Grid>
              <img alt="" src={logo}/>              
            </Grid>
          </Grid>
}

const View1=()=>{
  return  <Grid className="vh100 flex position-relative">
            <Grid className="left">
              <img alt="" src={left2}/>
            </Grid>
            <Grid className="right">
              <img alt="" src={right2}/>
            </Grid>
            <Grid align="center">
              <Grid>
                <img alt="" src={logo2}/>              
              </Grid>
              <Grid sx={{mt:3}}>
                <Button style={{ display: 'block', width: '100%' }} 
                        size="large" 
                        variant='round' 
                        color="primary"
                        component={NavLink}
                        to="/auth/login/movil"
                >
                    Inicio de Sesión
                </Button>

                <Button style={{ display: 'block', width: '100%', marginTop:"10px" }} 
                        component={NavLink}
                        to="/auth/recover/movil"
                >
                    ¿Olvidaste la contraseña?
                </Button>
              </Grid>
              
            </Grid>
          </Grid>
}

const WebLogin=()=>{
    const [active,setActive]  = useState(false)
    useEffect(()=>{
      setTimeout(()=>{setActive(true)}, 3000)
    },[])
    return  <>  
              {!active?<Splash/>:<View1/>}
            </>
}
export default WebLogin;