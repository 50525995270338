/*set components*/
import Home  from './Home';
import HomeEnviado  from './HomeEnviado';
import HomeEntregado  from './HomeEntregado';
import Edit  from './Edit';
import EditProductos  from './EditProductos';
import HomeProductosAdd  from './HomeProductosAdd';
import EditFormaPago  from './EditFormaPago';
import EditPago  from './EditPago';
import PagoForm  from './PagoForm';
/*set icons*/
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Edit",
    path: "/:id",
    component: Edit,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Edit",
    path: "/:id/productos",
    component: EditProductos,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Productos",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Edit",
    path: "/:id/productos/:id2",
    component: HomeProductosAdd,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Productos",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },  
  {
    name: "Edit",
    path: "/:id/pago/:id2",
    component: EditFormaPago,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Productos",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },  
  {
    name: "Edit",
    path: "/:id/pago",
    component: EditPago,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Pago",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "Edit",
    path: "/:id/pagoForm",
    component: PagoForm,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Pago",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },
  {
    name: "EditFormaPago",
    path: "/transport_sent",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },  
  {
    name: "EditFormaPago",
    path: "/transport_delivered",
    component: Home,
    menu: true,
    private: false,
    icon: <WorkHistoryIcon />,
    title: "Vacantes",
    subtitle: "Nunca la gestión en RR.HH. ha sido tan simple..."
  },  
];
