import { useContext } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/List';
import { Button, Grid, Paper, Typography } from '@mui/material';
import image    from '../../../assets/png/avatar.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import IconButton from '@mui/material/IconButton';
import StateContext from '../../../utils/ContextState';
import Select from '../../../components/Select'
import Submenu from './SubMenu';


const Filter=({context})=>{
  return  <Grid sx={{p:4}}>
            <Grid container spacing={3} sx={{mb:2}}>
              <Grid item xs={6}>
                <Select label="Estado"/>
              </Grid>
              <Grid item xs={6}>
                <Select label="Ciudad"/>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button variant='contained' fullWidth>
                  Aceptar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant='outlined' fullWidth onClick={()=>context.setOpenDialog(false)}>
                  Borrar Filtro
                </Button> 
              </Grid>
            </Grid>
          </Grid>
}


const items   =   [
  {
    label:"Andrés Manrique",
  },  
  {
    label:"Andrés Manrique",
  },    
]

const items2   =   [
  {
    label:"Brandon Manrique",
  },  
  {
    label:"Brandon Manrique",
  },    
  {
    label:"Brandon Manrique",
  },    
]

const items3   =   [
  {
    label:"Carlos Manrique",
  },  
  {
    label:"Carlos Manrique",
  },    
  {
    label:"Carlos Manrique",
  },    
]

const Item=({row})=>{
  return  <Grid item xs={12} md={3}> 
            <Paper sx={{p:2}}>
              <Grid container>
                <Grid item xs={9}>
                  <Grid>
                    {row.label}
                  </Grid>
                  <Grid sx={{mt:1}}>
                    <Grid container>
                      <Grid item xs={2}>
                        <IconButton color="green" size="small" variant="text"><WhatsAppIcon sx={{fontSize:22}} /></IconButton>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton color="blue" size="small" variant="text"><PhoneForwardedIcon sx={{fontSize:22}}  /></IconButton>                    
                      </Grid>
                      <Grid item xs={8} align="center">
                        <Button size="small" variant="contained2">
                          Datos Vehículo
                        </Button>                    
                      </Grid>
                    </Grid>                    
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <img className='img-fluid' src={image} alt="Programandoweb"/>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
}

const Home=(props)=>{

  const context               =   useContext(StateContext);
  
  const handleFilters=()=>{
    return context.setOpenDialog({
              component:<Filter context={context}/>,
              maxWidth:"sm"
            })
  }

  return  <Container {...useFormData()} filter={handleFilters}>
            <Submenu option={"internacionales"}/>
            <Grid container spacing={0} sx={{mb:3}}>
              <Grid item xs={12} className='separador-letras'>
                <Typography sx={{pl:1,pt:0.5,pb:0.5,m:0,fontWeight:"bold"}}>
                  A
                </Typography>
              </Grid>
            </Grid>       
            <Grid container spacing={1} sx={{mb:3}}>
              {
                items.map((row,key)=>{
                  return <Item key={key} row={row} />
                })
              }     
            </Grid>       

            <Grid container spacing={0} sx={{mb:3}}>
              <Grid item xs={12} className='separador-letras'>
                <Typography sx={{pl:1,pt:0.5,pb:0.5,m:0,fontWeight:"bold"}}>
                  B
                </Typography>
              </Grid>
            </Grid>       
            <Grid container spacing={1} sx={{mb:3}}>
              {
                items2.map((row,key)=>{
                  return <Item key={key} row={row} />
                })
              }     
            </Grid>

            <Grid container spacing={0} sx={{mb:3}}>
              <Grid item xs={12} className='separador-letras'>
                <Typography sx={{pl:1,pt:0.5,pb:0.5,m:0,fontWeight:"bold"}}>
                  C
                </Typography>
              </Grid>
            </Grid>       
            <Grid container spacing={1} sx={{mb:3}}>
              {
                items3.map((row,key)=>{
                  return <Item key={key} row={row} />
                })
              }     
            </Grid> 
          </Container>
}
export default Home
