import { useState, useEffect } from 'react';
import useFormData from '../../../hooks/useFormData';
import Container    from '../../../theme/Form';
import { Button, Grid, Typography } from '@mui/material';
import Input        from '../../../components/Input/GroupInput';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import UploadIcon from '@mui/icons-material/Upload';
import Loading from '../../../components/Loading/LinearWithLabel';
import { useParams } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import useAsyncStorage from '../../../hooks/useAsyncStorage';
import { useNavigate } from "react-router-dom";
import Select from '../../../components/Select/GroupSelect';
import {getImagePreview} from '../../../utils/functions';
import FileContent from '../../../components/FileContent';

let inputs  = {}
const timeLoading                 =   2000;

const Home=(props)=>{
  const { getInit,postUpload }    =   useAxios();
  let { id , id2}                      =   useParams();
  const userStorage               =   useAsyncStorage("formProduct");
  const navigate                  =   useNavigate();
  const [loading,setLoading]      =   useState(true);  
  const { formData, onChange }    =   useFormData();
  const [mainImage, setMainImage] =   useState(null); // Estado para la imagen principal
  const [image, setImage]         =   useState(null); // Estado para la imagen principal


  const onChange2 = (name,value)=>{
    //console.log(name,value)

    if (!value||!value.id) {
      return false;
    }

    inputs[name]  =  value.id;

  }

  useEffect(()=>{
    if (id2==="0") {
      inputs={}
      setLoading(false)
      userStorage.removeData();  
      return;
    }    
    getInit("payment",{},"include=negotiation,state&filter=id:"+id2).then((response)=>{
      if (response&&response.data&&response.data[0]) {      
        inputs=response.data[0]; 

        if (inputs.attachments) {
          setImage(inputs.attachments)  
          delete inputs.attachments;
        }  
        setTimeout(() => {
          setLoading(false)
        }, timeLoading);              
      }      
    })    
  },[])

  const handleSubmit = (event) => {
    event.preventDefault(); // Evita el comportamiento predeterminado del formulario
    if (id2) {
      //console.log({...inputs,...formData,})
      //return;
      postUpload({
        ...inputs,
        ...formData
        },"api/payment/update/"+id2).then((response)=>{
        if (response&&response.data) {
          navigate('/dashboard/negociacionInternacional/'+response.data.negotiation_id+'/formapago')
        }
      })    
    }else{
      postUpload({
              ...inputs,
              ...formData,
              negotiation_id:id,
              due_date: formData.payment_date, 
              "attachments[]":mainImage
        },"api/payment/create").then((response)=>{
        if (response&&response.data) {
          userStorage.setData(response.data)
          navigate('/dashboard/negociacionInternacional/'+response.data.negotiation_id+'/formapago')
        }
      })
    }
    
    
  };


  return  <form onSubmit={handleSubmit}>
            <Container {...useFormData()} onClickSubmit={true}>
              <Grid container spacing={2} sx={{mb:4, mt:{xs:5,md:1}}}>
                <Grid item xs={8} md={4} sx={{display:{xs:"none",md:"block"}}}>
                  <Grid  className='borderBottom' >
                    <Typography component={"h3"}>
                      Forma de Pago
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} align='center' sx={{display:{xs:"block",md:"none"}}}>
                  <Typography component={"h3"} align='center'>
                    Forma de Pago
                  </Typography>                
                </Grid>              
              </Grid>
              {
                loading&&(
                  <Grid>
                    <Loading time={timeLoading} increment={timeLoading}/>
                  </Grid>
                )
              }
              {
                !loading&&(
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              defaultValue={inputs.number}
                              onChange={onChange} 
                              size="small" 
                              name="number" 
                              label="Número de pago"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}} 
                              defaultValue={inputs.value}
                              type={"number"}
                              onChange={onChange} 
                              size="small" 
                              name="value" 
                              label="Valor de pago"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Input  sx={{mb:2}}                                
                              type="date"
                              defaultValue={inputs.payment_date}
                              onChange={onChange} 
                              size="small" 
                              name="payment_date" 
                              label="Fecha de pago"/>
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Select defaultValue={inputs.method} label="Método de pago" name="method" data={[
                        {
                          id:"Bancolombia",
                          name:"Bancolombia",
                        },
                        {
                          id:"Cuenta TRF",
                          name:"Cuenta TRF",
                        },
                        {
                          id:"Datafono",
                          name:"Datafono",
                        },
                        {
                          id:"Davivienda",
                          name:"Davivienda",
                        },
                        {
                          id:"Efectivo",
                          name:"Efectivo",
                        },
                        {
                          id:"Nequi",
                          name:"Nequi",
                        },                        
                      ]} onChange={onChange2}/>                      
                    </Grid>  
                    <Grid item xs={12} md={6} >
                      <Select defaultValue={inputs.state_id} label="Estado de pago" name="state_id" data={[
                        {
                          id:"paid",
                          name:"Pago",
                        },
                        {
                          id:"not_payed",
                          name:"No Pago",
                        },
                      ]} onChange={onChange2}/>                      
                    </Grid>  
                    <Grid item xs={12} md={5} sx={{mb:2,mt:1}}>
                      <Grid sx={{display:{xs:"none",md:"block"}}}>
                        <Grid container>
                          <Grid item xs={6}>
                            <span style={{paddingTop:"20px"}}>Documentos Adjuntos</span> 
                          </Grid>
                          {
                            image&&(
                              <FileContent files={image}/>                     
                            )
                          }
                          <Grid item xs={3} className='content-image-upload'>
                            <input type="file" name="attachments[]" className='input' onChange={(e) => setMainImage(e.target.files[0])} />
                            <TextSnippetIcon sx={{fontSize:50,float:"right",color:"#2fca5a"}}/>  
                          </Grid>
                          <Grid item xs={3} className='content-image-upload'>
                            {
                              mainImage&&(<img className='img-fluid'  src={getImagePreview(mainImage)} alt="Programandoweb" />)
                            }                                     
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid sx={{display:{md:"none",xs:"block"}}}>
                        <Button variant='btnUpload' className='content-image-upload'>
                          <input type="file" name="bank.attachments[]" className='input' onChange={(e) => setMainImage(e.target.files[0])} />
                          <UploadIcon sx={{fontSize:20, }}/> Subir Documentos
                        </Button>
                      </Grid>                
                    </Grid>
                  </Grid>  
                )
              }
            </Container>
          </form>
}
export default Home
