import {Button, Grid, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
const Item=({row,nro})=>{
    return  <Grid item xs={12} md={3}> 
              <Paper sx={{p:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid sx={{fontSize:11,fontWeight:"bold"}}>
                      Jugk
                    </Grid>
                    <Grid className='item-date'>
                      Fecha de ingreso
                    </Grid>
                    <Grid className='item-date2'>
                      Cantidad: 5
                    </Grid>
                    <Grid className='item-price'>
                      Peso: 10Grs
                    </Grid>                  
                  </Grid>
                  <Grid item xs={4} >
                    <Grid className='flexBottom'>
                      <Button variant={'green'} component={NavLink} to="/dashboard/centroAcopio/0">
                        Ver detalle
                      </Button>  
                    </Grid>                  
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
  }
  
export default Item