import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const vertical    = 'top'
const horizontal  = 'center'

const LIMIT_TIMEOUT       =   process.env.REACT_APP_LIMIT_TIMEOUT || 5000

export default function SimpleSnackbar({open, setOpen}) {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  React.useEffect(()=>{
    if (open) {
      setTimeout(()=>setOpen(false),((open.timeout||LIMIT_TIMEOUT)*3))
    }
  },[open,setOpen])

  return (
    <div>
      <Snackbar
        severity={"success"}
        anchorOrigin={{ vertical, horizontal }}
        open={(open)?true:false}
        autoHideDuration={6000}
        onClose={handleClose}
        message={open}
        action={action}
      />
    </div>
  );
}
