import * as React from 'react';
import Box                from '@mui/material/Box';
import {  List,
          ListItem,
          ListItemButton,
          ListItemText,
          CssBaseline,
          Toolbar,
          Grid,
          Typography
        } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiDrawer          from '@mui/material/Drawer';
import ClickAwayListener  from '@mui/material/ClickAwayListener';

import MuiAppBar          from '@mui/material/AppBar';
import HttpsIcon          from '@mui/icons-material/Https';
import left               from '../../assets/png/elementTopLeft.png';
import logo               from '../../assets/png/logo.png';

import StateContext       from '../../utils/ContextState';

import FitbitIcon from '@mui/icons-material/Fitbit';

import { NavLink,useLocation  } from "react-router-dom";
import { 
          routes_modules,
          directorios,
          internacional,
          nacional,
          finanzas
        } from './routes';

import useMyRoutes        from '../../hooks/useMyRoutes';
//import useAxios           from  '../../hooks/useAxios';
import useAsyncStorage    from  '../../hooks/useAsyncStorage';
import { useNavigate }    from  "react-router-dom";
import AvatarProfile        from '../../components/AvatarProfile';

const drawerWidth                     =   240;


const ListNew=({open,handleSessionClose})=>{
  const location        =   useLocation();
  const fixed_menu      =   [
    // {
    //   label:'Switch Perfil',
    //   icon:<ToggleOnIcon />,
    //   event:openModal,
    // },
    {
      name:'Cerrar sesión',
      icon:<HttpsIcon />,
      event:handleSessionClose,
    },
  ]
      return <List>
                    {
                      routes_modules.map((text, index) => {
                        if (!text.menu) {
                          return false
                        }

                        
                        const active =  (((location.pathname === "/dashboard/home"||location.pathname === "/dashboard/" ||location.pathname === "/dashboard") && text.name==='Home')  || (location.pathname === ("/dashboard/"+text.url) || location.pathname.includes("/dashboard/"+text.url)));
                        
                        return  <ListItem key={index} disablePadding sx={{ display: 'block' }} className={active?" item-active ":""}>
                                  <ListItemButton
                                    component={NavLink} to={text.url||text.path}
                                    sx={{
                                      minHeight: 30,
                                      justifyContent: open ? 'initial' : 'center',
                                      px: 2.5,
                                      py: 0.2,
                                    }}
                                     // Agrega la clase si el enlace está activo
                                  >
                                    <FitbitIcon sx={{fontSize:20, mr:1, display:(active)?"block":"none"}}/>
                                    <ListItemText primary={text.name} sx={{ opacity: open ? 1 : 0 }} />
                                  </ListItemButton>
                                </ListItem>
                      })
                    }

                    {
                      fixed_menu.map((text, index) => (
                        <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                            }}
                            onClick={()=>{ text.event && text.event()}}
                          >
                            <ListItemText  primary={text.name} sx={{ opacity: open ? 1 : 0 }} />
                          </ListItemButton>
                        </ListItem>
                      ))
                    }
                  </List>
}

const Auth = () => {
  const location                      =   useLocation();
  const navigate                      =   useNavigate();
  const context                       =   React.useContext(StateContext);
  //const axios                         =   useAxios();
  const userStorage                   =   useAsyncStorage("user");
  const [open, setOpen]               =   React.useState(true);
  const [title, setTitle]             =   React.useState("");
  
  const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    position:"relative",
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 5),
    ...theme.mixins.toolbar,
  }));

  
  const handleClickAway = () => {
    //setOpen(false);
  };

  const handleSessionClose=()=>{
    //axios.postData({},'api/v1/dashboard/exit')
    userStorage.removeData()
    navigate("/auth/login",{ state: { reset: true } })
  }

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  React.useEffect(()=>{
    const modules =   [  
                        ...routes_modules,
                        ...directorios,
                        ...internacional,
                        ...nacional,
                        ...finanzas
                      ]
    const result  =   modules.find((search)=>"/dashboard/"+search.url===location.pathname || "/dashboard/"+search.path===location.pathname)
    if(result){
      setTitle("Módulo "+result.name)
    }
    
  },[location.pathname])

  

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/*meto aquí un menú*/}
        <AppBar position="fixed" open={true} sx={{display:{xs:"none",md:"block", boxShadow:"none", background:"#edf1f2"}}}>
          <Toolbar variant="main" sx={{background:"transparent"}}>
            <Grid container >
              <Grid item xs={4}>
                <Typography variant='h6' sx={{mt:1}}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={8} align="right">
                <AvatarProfile/>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open} sx={{display:{xs:"none",md:"block"}}}>
          <DrawerHeader>
            <Grid sx={{position:'absolute', width:120,left:0,top:0,zIndex:-10}}>
              <img className='img-fluid' src={left} alt=""/>
            </Grid>            

            <Grid sx={
                        { 
                          marginLeft:2,
                          marginRight:2,
                          marginTop:5,
                          marginBottom:0
                        }
                      }>
              <img className='img-fluid' src={logo} alt=""/>            
            </Grid>
          </DrawerHeader>          
          <Grid sx={{height:{md:"75%"}, overflow:"hidden", overflowY:"auto"}}>
            <ListNew open={open} handleSessionClose={handleSessionClose}/>
          </Grid>
        </Drawer>
        <Box sx={{ flexGrow: 1, p: 3, }} className={location.pathname==='/dashboard'?" bgMain ":" bg-pc "}>
          <Box sx={{pt:5,display:context.loadingApiRest||context.hide?"none":"block"}}>
            {useMyRoutes([  
                            ...routes_modules,
                            ...directorios,
                            ...internacional,
                            ...nacional,
                            ...finanzas
                          ])}
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default Auth;
