/*set components*/
import ForgotPassword  from './web/ForgotPassword';
import ForgotPasswordToken  from './web/ForgotPasswordToken';
import ForgotPasswordNew  from './web/ForgotPasswordNew';
import ForgotPasswordCode  from './web/ForgotPasswordCode';


import ForgotPasswordMovil  from './movil/ForgotPassword';
import ForgotPasswordMovilCode  from './movil/ForgotPasswordMovilCode';



import Login  from  './Login';
import MovilLoginForm  from './movil/MovilLoginForm';
import Register  from './Register';

/*set icons*/
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';

export const routes_modules = [
  {
    name: "Auth",
    path: "/",
    component: Login,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
  {
    name: "Auth",
    path: "/login",
    component: Login,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "Auth",
    path: "/login/movil",
    component: MovilLoginForm,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "Register",
    path: "/register",
    component: Register,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "ForgotPassword",
    path: "/recover",
    component: ForgotPassword,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "ForgotPassword Step two",
    path: "/recover/token",
    component: ForgotPasswordToken,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "ForgotPassword Step three",
    path: "/recover/passwordNew",
    component: ForgotPasswordNew,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "ForgotPassword",
    path: "/recover_movil_code/:email",
    component: ForgotPasswordMovilCode,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },

  {
    name: "ForgotPassword",
    path: "/recover_code/:email",
    component: ForgotPasswordCode,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },

  {
    name: "ForgotPassword",
    path: "/recover/movil",
    component: ForgotPasswordMovil,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },

];
